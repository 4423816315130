import React from 'react';
import SvgImage from '@/components/Module/SvgImage';
import { useTranslation } from 'react-i18next';
import {
  DirectContactButton,
  DirectContactButtonsBox,
} from '@/components/@IntranetLibrary/ProfileCard/ProfileCardCont/ProfileIDCard/styles';
import classNames from 'classnames';

type DirectContactButtonsProps = {
  phoneMobile?: string;
  phoneInside?: string;
  email?: string;
};

const DirectContactButtons = (props: DirectContactButtonsProps) => {
  const { phoneInside, phoneMobile, email } = props;
  const { t } = useTranslation();
  return (
    <DirectContactButtonsBox>
      <DirectContactButton
        href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`}
        target={'_blank'}
        rel={'noopener noreferrer'}
        className={classNames({ disabled: !email })}
      >
        <SvgImage name={'teams'} />
        <span>{t('팀즈')}</span>
      </DirectContactButton>
      <DirectContactButton
        href={`sms:${phoneMobile}`}
        className={classNames({ disabled: !phoneMobile })}
      >
        <SvgImage name={'message'} />
        <span>{t('문자')}</span>
      </DirectContactButton>
      <DirectContactButton
        href={`tel:${phoneMobile}`}
        className={classNames({ disabled: !phoneMobile })}
      >
        <SvgImage name={'phone'} />
        <span>{t('휴대폰')}</span>
      </DirectContactButton>
      <DirectContactButton
        href={`tel:${phoneInside}`}
        className={classNames({ disabled: !phoneInside })}
      >
        <SvgImage name={'phone'} />
        <span>{t('내선전화')}</span>
      </DirectContactButton>
    </DirectContactButtonsBox>
  );
};

export default DirectContactButtons;
