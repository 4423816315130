import React, {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useImperativeHandle,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import {
  Button,
  Modal as MuiModal,
  ModalProps as MuiModalProps,
  Tooltip,
} from '@mui/material';
import {
  ModalBody,
  ModalHeader,
  ModalInner,
} from '@/components/@IntranetLibrary/Modal/styles';
import classNames from 'classnames';
import CloseIcon from '@/public/icons/close_black.svg';
import CollapseIcon from '@/public/icons/collapse.svg';
import ExpandIcon from '@/public/icons/expand.svg';

type CustomModalSize = 'x-small' | 'small' | 'basic' | 'expand';

export const ModalPortalId = 'modal-portal-root';
export type ModalRef = {
  isOpen: boolean;
  open: (props?: any) => void;
  close: () => void;
};
export type ModalProps = {
  title: string;
  size?: CustomModalSize;
  isFixedHeight?: boolean; // 모달 height 고정
  isBlockBackDropClose?: boolean; // 모달 backdrop 클릭 시 닫힘 막기
  isExpandable?: boolean; // 모달 크기 확장 기능 사용 여부
  additionalButton?: ReactNode;
  availableAutoClose?: boolean;
  handleOpen?: (props?: any) => void;
  handleClose?: () => void;
} & PropsWithChildren &
  Omit<MuiModalProps, 'open' | 'onClose'>;

const Modal = forwardRef<ModalRef, ModalProps>((_props, ref) => {
  const {
    children,
    title,
    size: _size = 'basic',
    isFixedHeight = false,
    isBlockBackDropClose = false,
    isExpandable = false,
    additionalButton,
    availableAutoClose = true,
    handleOpen,
    handleClose,
    onClick,
    ...props
  } = _props;
  const [isOpen, setIsOpen] = useState(false);
  const [size, setSize] = useState<CustomModalSize>(_size);

  const open = (props?: any) => {
    handleOpen?.(props);
    setIsOpen(true);
  };

  const onClose = () => {
    if (availableAutoClose) close();
    handleClose?.();
  };

  const close = () => {
    setIsOpen(false);
    setSize(_size);
  };

  useImperativeHandle(ref, () => ({
    isOpen,
    open,
    close,
  }));

  return createPortal(
    <MuiModal
      open={isOpen}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.(event);
      }}
      onClose={(event, reason) => {
        if (isBlockBackDropClose && reason === 'backdropClick') return;
        onClose();
      }}
      {...props}
    >
      <ModalInner
        className={classNames('modal-inner', size, {
          fixedHeight: isFixedHeight,
        })}
      >
        <ModalHeader className={'modal-header'}>
          <h1>{title}</h1>
          <div className={'button-box'}>
            {additionalButton}
            {isExpandable && (
              <Tooltip
                title={size === 'expand' ? '축소해서 보기' : '확장해서 보기'}
                placement="top"
                arrow
                slotProps={{
                  tooltip: {
                    sx: { backgroundColor: 'black', color: 'white' },
                  },
                  arrow: { sx: { color: 'black' } },
                  popper: {
                    modifiers: [
                      { name: 'offset', options: { offset: [0, -8] } },
                    ],
                  },
                }}
              >
                <Button
                  className={'expand'}
                  onClick={() => setSize(size === 'expand' ? _size : 'expand')}
                >
                  {size === 'expand' ? (
                    <CollapseIcon width={'24px'} height={'24px'} />
                  ) : (
                    <ExpandIcon width={'24px'} height={'24px'} />
                  )}
                </Button>
              </Tooltip>
            )}
            <Button className={'close'} onClick={onClose}>
              <CloseIcon width={'24px'} height={'24px'} />
            </Button>
          </div>
        </ModalHeader>
        <ModalBody className={'modal-body'}>{children}</ModalBody>
      </ModalInner>
    </MuiModal>,
    document.getElementById(ModalPortalId) || document.body,
  );
});
Modal.displayName = 'Modal';

export default Modal;
