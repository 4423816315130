import React from 'react';
import {
  FormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  SvgIcon,
} from '@mui/material';

type Option<ValueType> = {
  readonly value: ValueType;
  readonly label: string;
};

export type RadioProps<ValueType> = {
  readonly options: Option<ValueType>[];
  readonly row?: boolean;
  readonly disabled?: boolean;
} & MuiRadioGroupProps;

function Radio<ValueType = 'string'>({
  options,
  disabled,
  sx,
  ...field
}: RadioProps<ValueType>) {
  return (
    <MuiRadioGroup
      {...field}
      sx={{
        '.MuiButtonBase-root': {
          display: 'flex',
          margin: 0,
          padding: 0,
        },
        '.MuiFormControlLabel-root': {
          padding: '0',
          margin: '0 24px 0 0',
          gap: '8px',
        },
        input: {
          width: '1px',
          height: '1px',
        },
        ...sx,
      }}
    >
      {options.map((option) => (
        <FormControlLabel
          key={`${option.value}`}
          value={option.value}
          control={
            <MuiRadio
              disabled={disabled}
              icon={
                <SvgIcon viewBox={'0 0 20 20'}>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="19"
                    height="19"
                    rx="9.5"
                    fill="white"
                    stroke={disabled ? '#BCBCBC' : '#B7BBC2'}
                  />
                </SvgIcon>
              }
              checkedIcon={
                <SvgIcon viewBox={'0 0 20 20'}>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="19"
                    height="19"
                    rx="9.5"
                    fill={disabled ? '#F0F0F0' : 'white'}
                    stroke={disabled ? '#E8E8E8' : '#B7BBC2'}
                  />
                  <rect
                    x="4"
                    y="4"
                    width="12"
                    height="12"
                    rx="6"
                    fill={disabled ? '#D0D0D0' : 'black'}
                  />
                </SvgIcon>
              }
            />
          }
          label={option.label}
        />
      ))}
    </MuiRadioGroup>
  );
}

export default Radio;
