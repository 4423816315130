import * as React from 'react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { TextareaAutosizeProps } from '@mui/material';

export const TextareaAutosize = styled((props: TextareaAutosizeProps) => (
  <BaseTextareaAutosize {...props} />
))(
  () => `
    box-sizing: border-box;
    
    padding: 16px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #E7E9EC;
    
    color: #202326;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-family: inherit;
    line-height: 20px;
    border-radius: 6px;

    &:hover {
      border-color: #000;
    }
    
    &.error {
      border-color: #F55060;
    }

    &:focus {
      border-color: #000;
    }
    
    ::placeholder {
      color: #B7BBC2;
      font-size: 14px;
    }

    /* firefox */
    &:focus-visible {
      outline: 0;
    }
  `,
);
