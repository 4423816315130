import { MyMenuContent } from '@/constant/mymenu';
import EditProfileContent from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/EditProfile';
import { PersonalInfoContent } from 'components/MenuDrawer/MyMenuDrawer/V2/SubMenu/PersonalInfo';
import MailSignatureContent from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/MailSignature';
import LanguageSettingContent from 'components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSetting';
import AlarmSettingContent from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/AlarmSetting';
import PasswordSettingContent from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/PasswordSetting';
import CertificateOfEmployment from 'components/MenuDrawer/MyMenuDrawer/V2/SubMenu/CertificateOfEmployment';
import { PayStubContent } from 'components/MenuDrawer/MyMenuDrawer/V2/SubMenu/PayStub';
import WifiContent from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/WifiContent';
import Vacation from '@/components/Profile/SecondItem/Vacation';
import ShoppingMallVerification from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/ShoppingMallVerification';

export const MyMenuContentMap = new Map<
  MyMenuContent,
  {
    component: any;
    hasSidePadding: boolean;
  }
>([
  [
    MyMenuContent.EDIT_PROFILE,
    { component: EditProfileContent, hasSidePadding: false },
  ],
  [
    MyMenuContent.PERSONAL_INFO,
    { component: PersonalInfoContent, hasSidePadding: true },
  ],
  [
    MyMenuContent.MAIL_SIGNATURE,
    { component: MailSignatureContent, hasSidePadding: true },
  ],
  [
    MyMenuContent.LANGUAGE_SETTING,
    { component: LanguageSettingContent, hasSidePadding: true },
  ],
  [
    MyMenuContent.ALARM_SETTING,
    { component: AlarmSettingContent, hasSidePadding: true },
  ],
  [
    MyMenuContent.PASSWORD_SETTING,
    { component: PasswordSettingContent, hasSidePadding: true },
  ],
  [
    MyMenuContent.CHECK_VACATION,
    { component: Vacation, hasSidePadding: false },
  ],
  [
    MyMenuContent.CERTIFICATE_OF_EMPLOYMENT,
    { component: CertificateOfEmployment, hasSidePadding: true },
  ],
  [MyMenuContent.PAY_STUB, { component: PayStubContent, hasSidePadding: true }],
  [
    MyMenuContent.SHOPPING_MALL_VERIFICATION,
    { component: ShoppingMallVerification, hasSidePadding: true },
  ],
  [MyMenuContent.WIFI, { component: WifiContent, hasSidePadding: true }],
]);
