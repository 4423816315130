import React from 'react';
import {
  Menu,
  MenuProps,
  styled,
  Typography,
  TypographyProps,
  Box,
  BoxProps,
  Theme,
} from '@mui/material';
import { TabList, TabListProps } from '@mui/lab';
import { SystemStyleObject } from '@mui/system';

export const BoardDetailSx: SystemStyleObject<Theme> = {
  width: '100%',
  position: 'relative',
  '.board-reaction-wrap': { paddingTop: '24px' },
  '&.isRowDirection': {
    '--board-detail-comment-width': '420px',
    header: {
      width:
        'calc(min(var(--page-max-width, 100%), 100%) - var(--board-detail-comment-width, 0px) - (var(--board-detail-padding-X, var(--modal-padding, 0px)) * 2))',
      top: 'calc(var(--board-detail-offset-top, 0px) + 1px)',
      left: 'max(calc((100% - var(--page-max-width, 100%)) / 2), var(--board-detail-padding-X, var(--modal-padding, 0px)))',
      transform: 'none',
      paddingRight: '16px',
      borderRight: '1px solid #E7E9EC',
    },
    '.board-content-wrap': { flexDirection: 'row', gap: 0 },
    '#board-content': { minWidth: 0, flex: 1, paddingRight: '16px' },
    '.board-reaction-wrap': {
      width: 'var(--board-detail-comment-width, 0px)',
    },
    '#board-reaction': {
      position: 'fixed',
      top: 'var(--board-detail-offset-top, var(--modal-padding, 0px))',
      bottom: 'calc(var(--extra-height, 0px))',
      overflowY: 'auto',
      width: 'var(--board-detail-comment-width, 0px)',
      borderTop: '1px solid #E7E9EC',
      '#board-reaction-tab-list': {
        padding: '17px 16px',
        borderBottom: '1px solid #E7E9EC',
        backgroundColor: 'white',
      },
      '.board-detail-comment': { paddingLeft: '8px' },
      '.board-detail-likes': { paddingLeft: '16px' },
      '#comment-input-wrap': {
        position: 'sticky',
        bottom: 0,
        width: 'calc(var(--board-detail-comment-width, 0px) - 8px)',
        zIndex: 1,
        padding: '0 0 24px 8px',
        backgroundColor: 'white',
      },
    },
    '&.isModal': {
      'header.isCompact': {
        paddingTop: 'var(--board-detail-header-padding-top, 16px)',
        '> h1': { paddingRight: '0px' },
      },
      '.board-reaction-wrap': {
        width: 0,
        '#board-reaction': { right: 'var(--modal-padding, 0px)' },
      },
    },
  },
};

export const BoardDetailHeaderSx: SystemStyleObject<Theme> = {
  position: 'fixed',
  top: 'var(--board-detail-offset-top, 0px)',
  left: '50%',
  transform: 'translateX(-50%)',
  width: 'calc(100% - var(--board-detail-padding-X, 0px) * 2)',
  maxWidth: 'var(--page-max-width, "none")',
  backgroundColor: 'white',
  borderBottom: '1px solid #E7E9EC',
  paddingTop:
    'calc(var(--board-detail-padding-top, 0px) + var(--board-detail-header-padding-top, 16px))',
  paddingBottom: '16px',
  zIndex: 2,
  '.board-title': {
    marginTop: '12px',
    fontWeight: 500,
    fontSize: '24px',
    color: 'black',
    backgroundColor: 'white',
  },
  '&.isMobile': {
    paddingTop:
      'calc(var(--board-detail-padding-top, 0px) + var(--board-detail-header-padding-top, 8px))',
    paddingBottom: '8px',
    '.board-category': {
      paddingRight: '70px',
    },
    '.board-title': {
      marginTop: '4px',
      fontSize: '16px',
    },
    '.board-createAt': {
      position: 'absolute',
      top: 'calc(var(--board-detail-padding-top, 0px) + var(--board-detail-header-padding-top, 8px) + var(--modal-padding, 0px))',
      right: 0,
      fontSize: '12px',
      lineHeight: '24px',
    },
  },
  '&.isModal': {
    left: 'var(--modal-padding, 0px)',
    transform: 'none',
    width: 'calc(100% - (var(--modal-padding, 0px) * 2))',
    maxWidth: 'none',
    paddingTop:
      'max(var(--board-detail-header-padding-top, 16px), var(--modal-padding, 0px))',
    '&.isMobile': {
      paddingTop:
        'calc(var(--board-detail-padding-top, 0px) + var(--board-detail-header-padding-top, 8px) + var(--modal-padding, 0px))',
    },
  },
  '&.isCompact': {
    paddingTop:
      'max(var(--board-detail-header-padding-top, 16px), var(--modal-padding, 0px))',
    paddingBottom: 'var(--board-detail-header-padding-top, 16px)',
    '&.isModal.isMobile': {
      paddingTop: 'var(--modal-padding, 0px)',
    },
    '> :not(h1)': {
      display: 'none',
    },
    '> h1': {
      paddingRight: '28px',
      margin: '0 !important',
      fontSize: '20px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
};

export const BackgroundBlockSx: SystemStyleObject<Theme> = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  height: '56px',
  backgroundColor: 'white',
  zIndex: 2,
};

export const BoardDetailSubText = styled(
  ({ children, ...props }: TypographyProps) => (
    <Typography
      component={'span'}
      fontSize={'16px'}
      color={'#888D96'}
      {...props}
    >
      {children}
    </Typography>
  ),
)(() => ({}));

export const BoardDetailMenu = styled(({ children, ...props }: MenuProps) => (
  <Menu
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    {...props}
  >
    {children}
  </Menu>
))({
  '& .MuiMenu-list': {
    width: '104px',
    padding: '6px 10px',
    borderRadius: '8px',
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.12)',
    '> li': {
      width: '100%',
      padding: '6px',
      margin: 0,
      fontSize: '14px',
      color: 'black',
    },
  },
});

export const BoardReactionTabList = styled(
  ({ children, ...props }: TabListProps) => (
    <TabList {...props}>{children}</TabList>
  ),
)(() => ({
  minHeight: 'auto',
  position: 'sticky',
  top: 0,
  zIndex: 1,
  '.MuiTabs-flexContainer': {
    marginBottom: '4px',
    '.MuiTab-root': {
      minHeight: 'auto',
      minWidth: 'auto',
      marginRight: '16px',
      padding: '0 2px',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#B7BBC2',
      '&.Mui-selected': {
        fontWeight: 700,
        color: 'black',
      },
    },
  },
  '.MuiTabs-indicator': {
    backgroundColor: 'black',
  },
}));

export const FileAttachmentWrapper = styled(
  ({ children, ...props }: BoxProps) => <Box {...props}>{children}</Box>,
)(() => ({
  display: 'grid',
  gridTemplateColumns: 'calc(50% - 8px) calc(50% - 8px)',
  gap: '8px',
  paddingTop: 2,
}));
