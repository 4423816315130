import {
  Box,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  styled,
} from '@mui/material';
import { HighZIndex } from '@/constant/z-index';

export const DialogContainer = styled(MuiDialog)`
  & .MuiPaper-root {
    position: relative;
    min-width: 320px;
    border-radius: 8px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  }
  &.high-z-index .MuiPaper-root {
    z-index: ${HighZIndex} !important;
  }
`;

export const DialogHeader = styled(Box)`
  position: relative;
  padding: 12px;
  background-color: #f9fafb;
  border-bottom: 1px solid #e7e9ec;
  &.hideTitle {
    background-color: transparent;
    border-bottom: none;
    & + .dialog-content {
      padding-top: 0;
    }
  }
  h2.MuiDialogTitle-root {
    height: 24px;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
  button.close {
    position: absolute;
    top: 8px;
    right: 12px;
    padding: 4px;
  }
`;

export const DialogContent = styled(MuiDialogContent)`
  padding: 24px 20px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  white-space: pre-wrap;
`;

export const DialogActions = styled(MuiDialogActions)`
  width: 100%;
  display: block;
  padding: 0 20px 20px;
`;
