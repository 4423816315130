import React, { PropsWithChildren } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { SubContentProps } from 'components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSetting';
import ContentDescription from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';
import {
  Body1,
  Body2,
  Caption,
} from '@/components/@IntranetLibrary/Typography';
import Link from '@/components/@IntranetLibrary/Link';
import Label from '@/components/@IntranetLibrary/Label';
import { FormGroup } from '@/components/Board/BoardForm/style';
import RHFPasswordField from '@/components/HookForm/RHFPasswordField';
import FormProvider from '@/components/HookForm/FormProvider';
import usePasswordSettingProps from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/PasswordSetting/@hooks/usePasswordSettingProps';
import BoxButton from '@/components/@IntranetLibrary/Button/BoxButton';

const PasswordSetting = (props: SubContentProps) => {
  const { passwordSettingLink, formProps, isSubmitButtonDisabled, isLoading } =
    usePasswordSettingProps(props);

  return (
    <>
      <ContentDescription hasVerticalMargin={true}>
        회사에서 사용하는 계정은 F&F 계정과 MS Office 365 계정으로 구분됩니다.
        <br />
        계정별 사용 시스템 확인 후 변경해 주세요.
      </ContentDescription>
      <Stack>
        <FormProvider {...formProps}>
          <SectionHeader
            title={'F&F 계정'}
            subTitle={'사용 시스템'}
            tags={['M ERP', 'SCM', 'PDM']}
          />
          <Stack sx={{ padding: '24px 0 32px', gap: '24px' }}>
            <FormGroup gapsize={'s'}>
              <Label isRequired={true} title={'현재 비밀번호'} size={14} />
              <RHFPasswordField
                name={'currentPassword'}
                placeholder={'현재 사용중인 비밀번호를 입력해 주세요'}
                disabled={isLoading}
                inputProps={{ maxLength: 16 }}
              />
            </FormGroup>
            <FormGroup gapsize={'s'}>
              <Label isRequired={true} title={'새 비밀번호'} size={14} />
              <RHFPasswordField
                name={'updatePassword'}
                placeholder={'새롭게 사용할 비밀번호를 입력해 주세요'}
                disabled={isLoading}
                inputProps={{ maxLength: 16 }}
              />
              <RHFPasswordField
                name={'updateConfirmPassword'}
                placeholder={'새롭게 사용할 비밀번호를 한 번 더 입력해 주세요'}
                disabled={isLoading}
                inputProps={{ maxLength: 16 }}
              />
            </FormGroup>
            <BoxButton
              variant={'primary'}
              type={'submit'}
              id={formProps.id}
              disabled={isSubmitButtonDisabled}
            >
              비밀번호 변경
            </BoxButton>
          </Stack>
        </FormProvider>
      </Stack>
      <Stack>
        <SectionHeader
          title={'MS Office 365 계정'}
          subTitle={'사용 시스템'}
          tags={[
            'Finstagram',
            'GOMS',
            'MS O365',
            'Portal (Intranet)',
            'PLM',
            'SA (Simply Accounting)',
            'S-ERP',
            'SMS',
          ]}
        >
          <Caption sx={{ marginTop: '8px' }}>
            MS 365 비밀번호는 Microsoft 암호변경에서 변경 가능합니다.
          </Caption>
        </SectionHeader>
        <Stack sx={{ padding: '24px 0 32px' }}>
          <Link
            href={passwordSettingLink}
            target={'_blank'}
            size={'large'}
            variant={'secondary'}
          >
            MS 365 비밀번호 변경하기
          </Link>
        </Stack>
      </Stack>
    </>
  );
};

export default PasswordSetting;

const SectionHeader = ({
  title,
  subTitle,
  tags,
  children,
}: PropsWithChildren<{
  readonly title: string;
  readonly subTitle: string;
  readonly tags: string[];
}>) => {
  return (
    <Stack gap={'8px'}>
      <Divider sx={{ marginBottom: `${24 - 8}px` }} />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Body1 sx={{ fontWeight: 700 }}>{title}</Body1>
        <Body2
          sx={{
            marginLeft: '8px',
          }}
        >
          {subTitle}
        </Body2>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          padding: '12px',
          borderRadius: '8px',
          background: '#F9FAFB',
          flexWrap: 'wrap',
          gap: '4px',
        }}
      >
        {tags.map((tag, index) => (
          <Box
            key={index}
            sx={{
              padding: '3px 7px',
              borderRadius: '4px',
              border: '1px solid #888D96',
              background: '#E7E9EC',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '18px',
            }}
          >
            {tag}
          </Box>
        ))}
      </Box>
      {children}
    </Stack>
  );
};
