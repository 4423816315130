import React, { useState } from 'react';
import { Passport } from '@/types/api';
import { VIP_NUM } from '@/constant';
import { imgUrlChk } from '@/utils/function';
import useLayoutSize from '@/hooks/useLayoutSize';
import OpenTeamsLinkButton from '@/components/@IntranetLibrary/Button/OpenTeamsLinkButton';
import { Box, BoxProps, Skeleton } from '@mui/material';
import {
  IDCardBand,
  IDCardBox,
  IDCardContent,
  IDCardContentWrapper,
  IDCardImageWrapper,
} from './styles';
import DirectContactButtons from './DirectContactButtons';

type ProfileIDCardProps = { passport: Passport } & BoxProps;

const ProfileIDCard = ({ passport, sx, ...props }: ProfileIDCardProps) => {
  const { isMobile } = useLayoutSize();
  const [profileImgError, setProfileImgError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Box sx={{ position: 'relative', ...sx }} {...props}>
      <IDCardBand src={'/images/profile/band.png'} alt="band" />
      <IDCardBox>
        <IDCardImageWrapper>
          {isLoading && (
            <Skeleton
              width={180}
              height={180}
              variant={'rectangular'}
              className={'profile-image-skeleton'}
            />
          )}
          <img
            src={
              profileImgError
                ? '/images/profile/errorProfileImage.svg'
                : imgUrlChk(passport.detail.profileImageUrl)
            }
            alt={`profile-image-${passport.detail.name}`}
            onError={() => setProfileImgError(true)}
            onLoad={() => setIsLoading(false)}
          />
        </IDCardImageWrapper>
        <IDCardContentWrapper>
          <IDCardContent>
            <p className={'profile-name'}>
              <strong>{`${passport.detail.name} ${
                passport.detail.nickname ? `(${passport.detail.nickname})` : ''
              }`}</strong>
              <span>{passport.detail.positionName}</span>
            </p>
            <p>
              {passport.detail.employeeNumber === VIP_NUM
                ? '대표이사'
                : `${passport.detail.divisionName} • ${passport.detail.departmentName}`}
            </p>
            <p className={'ellipsis-line2'}>
              {passport.detail.organizationShortName}
            </p>
          </IDCardContent>
          {isMobile ? (
            <DirectContactButtons
              phoneInside={passport.detail.phoneInside}
              phoneMobile={passport.detail.phoneMobile}
              email={passport.detail.email}
            />
          ) : (
            <OpenTeamsLinkButton email={passport.detail.email} />
          )}
        </IDCardContentWrapper>
      </IDCardBox>
    </Box>
  );
};

export default ProfileIDCard;
