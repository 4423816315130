import { styled } from '@mui/material/styles';
import { Box, Stack, StackProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { BoxProps } from '@mui/material/Box';
import { Body1, Body2 } from '@/components/@IntranetLibrary/Typography';

export const Menu = styled((props: BoxProps) => <Box {...props} />)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '8px 12px 8px 8px',
  borderRadius: '8px',

  ':hover': {
    background: '#F9FAFB',
    cursor: 'pointer',
  },
}));

export const Icon = styled((props: BoxProps) => <Box {...props} />)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '56px',
  height: '56px',
  borderRadius: '4px',
  border: '1px solid #E7E9EC',
  background: '#FFF',
}));

export const Description = styled((props: StackProps) => <Stack {...props} />)(
  () => ({
    flex: 1,
  }),
);

export const DescriptionTitle = styled(({ children }: PropsWithChildren) => (
  <Body1
    sx={{
      fontSize: '14px',
    }}
  >
    {children}
  </Body1>
))(() => ({}));

export const DescriptionContent = styled(({ children }: PropsWithChildren) => (
  <Body2
    sx={{
      fontSize: '12px',
      fontWeight: 400,
      '& .blue': {
        color: '#06C !important',
        fontWeight: 700,
      },
    }}
  >
    {children}
  </Body2>
))(() => ({}));
