import React, { ReactNode, useState } from 'react';
import Dialog, { DialogOptions } from '@/components/@IntranetLibrary/Dialog';
import { uuidv4 } from '@/utils/function';

type DialogProviderProps = {
  children: ReactNode;
};

export type DialogContextType = {
  show: (options?: DialogOptions) => void;
  hide: (id?: string) => void;
  hideAll: () => void;
  stack: DialogType[];
};

type DialogType = {
  id?: string;
  isOpen: boolean;
  option: DialogOptions;
};

type ResultDialogType = DialogType | null;

export const DialogContext = React.createContext<DialogContextType | null>(
  null,
);

export const DialogProvider = ({ children }: DialogProviderProps) => {
  const [dialogStacks, setDialogStacks] = useState<DialogType[]>([]);

  const alertConfirmShow = (dialogOptions?: DialogOptions) => {
    const basicOption: DialogOptions = {
      type: 'alert',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
    };
    if (
      dialogOptions &&
      !dialogOptions.type &&
      (!!dialogOptions.cancelButtonText || !!dialogOptions.onCancel)
    ) {
      dialogOptions.type = 'confirm';
    }

    setDialogStacks((prevState) =>
      prevState.filter((item) => item.id === dialogOptions?.id).length > 0
        ? [...prevState]
        : [
            ...prevState,
            {
              id: dialogOptions?.id || uuidv4(),
              isOpen: true,
              option: { ...basicOption, ...dialogOptions },
            },
          ],
    );
  };

  const handleClose = (id?: string) => {
    if (id) {
      setDialogStacks((prevState) => {
        handleAfterClose(prevState, id);
        return prevState.filter((item) => item.id !== id);
      });
    } else {
      setDialogStacks((previousArr) => {
        handleAfterClose(previousArr, id);
        return previousArr.slice(0, -1);
      });
    }
  };

  const allClose = () => {
    setDialogStacks((prevState) => {
      prevState.forEach((item) => {
        handleAfterClose(prevState, item.id);
      });
      return [];
    });
  };

  const handleAfterClose = (stack: DialogType[], id: string | undefined) => {
    const target = dialogGet(stack, id) as ResultDialogType;
    if (target && target.option.onAfterClose) {
      target.option.onAfterClose();
    }
  };

  const dialogGet = (stack: DialogType[], id: string | undefined) => {
    if (!stack.length) {
      return null;
    }
    const result = stack.filter((item) => item.id === id);
    return (
      (result.length && result[result.length - 1]) || stack[stack.length - 1]
    );
  };

  const handleClickConfirm = (dialog: DialogType) => {
    handleClose(dialog.id);
    dialog.option.onConfirm?.();
  };

  const handleClickCancel = (dialog: DialogType) => {
    handleClose(dialog.id);
    dialog.option.onCancel?.();
  };

  const handleHide = () => handleClose();
  const handleHideAll = () => allClose();

  return (
    <>
      <DialogContext.Provider
        value={{
          show: alertConfirmShow,
          hide: handleHide,
          hideAll: handleHideAll,
          stack: dialogStacks,
        }}
      >
        {children}
        {dialogStacks.map((dialog) => (
          <Dialog
            key={`dialog_${dialog.id}`}
            open={dialog.isOpen}
            onClose={handleClose}
            onClickCancel={() => handleClickCancel(dialog)}
            onClickConfirm={() => handleClickConfirm(dialog)}
            dialogOptions={dialog.option}
          />
        ))}
      </DialogContext.Provider>
    </>
  );
};
