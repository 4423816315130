import { useContext } from 'react';
import {
  SnackbarContext,
  SnackbarContextProps,
} from '@/components/@IntranetLibrary/Toast/ToastProvider';

const useToast = (): SnackbarContextProps => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
};

export default useToast;
