import Label from '@/components/@IntranetLibrary/Label';
import FormProvider, {
  FormProviderProps,
} from '@/components/HookForm/FormProvider';
import RHFImageOptionSelector, {
  RHFImageOptionSelectorProps,
} from '@/components/HookForm/RHFImageOptionSelector';
import RHFRadio, { RHFRadioProps } from '@/components/HookForm/RHFRadio';
import RHFTextField from '@/components/HookForm/RHFTextField';
import ImageSlide from '@/components/Board/BoardForm/ImageSlide';
import {
  Box,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Stack,
  SxProps,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';
import {
  AutoSavedTime,
  BoardFormModal,
  BoardModalPaper,
  FormConfig,
  FormConfigBody,
  FormConfigHeader,
  FormConfigHeaderTitle,
  FormGroup,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ModalHeaderDivider,
  ModalSlide,
  ModalTitle,
  Slide,
} from '@/components/Board/BoardForm/style';
import FileAttachment from 'components/Board/BoardForm/FileAttachment';
import DisplaySections from '@/components/Board/BoardForm/DisplaySections';
import RHFDateTimePicker from '@/components/HookForm/RHFDateTimePicker';
import { RHFLexicalEditor } from '@/components/LexicalEditor/LexicalEditor';
import BoardFormLoading from './Loading';
import { BoardFormSettings } from '@/components/Board/BoardForm/@hooks/useBoardFormProps';
import { BoardDetailData } from '@/types/board';
import BoardDetail from '@/components/Board/BoardDetail';
import { Theme } from '@mui/material/styles';
import { useRouter } from 'next/router';
import RHFMultiAutoCompleteHighLightsSelect from '@/components/HookForm/RHFMultiAutoCompleteHighLightsSelect';
import { ButtonWrapper } from '@/components/@IntranetLibrary/Button/ButtonWrapper';
import BoxButton from '@/components/@IntranetLibrary/Button/BoxButton';

export type BoardFormProps = Pick<
  FormProviderProps,
  'methods' | 'onSubmit' | 'id'
> & {
  readonly mode: 'new' | 'edit';
  readonly formTitle: string;
  readonly categoryOptions: {
    readonly value: string;
    readonly label: string;
  }[];
  readonly category: {
    readonly value: string;
    readonly label: string;
  }[];
  readonly displaySectionOptions: RHFRadioProps<string>['options'];
  readonly commentPositionOptions: RHFImageOptionSelectorProps<string>['options'];
  readonly uploadTimeOptions: RHFRadioProps<string>['options'];
  readonly showUploadTimeSelector: boolean;
  readonly importantOptions: RHFRadioProps<string>['options'];
  readonly autoSavedTime: string;
  readonly submitButtonType: 'primary' | 'two';
  readonly primaryButtonOnClick: ButtonProps['onClick'];
  readonly secondaryButtonOnClick: ButtonProps['onClick'];
  readonly isSubmitButtonDisabled: ButtonProps['disabled'];
  readonly isLoading?: boolean;
  readonly isModalOpen: BoardFormSettings['isModalOpen'];
  readonly modalOnClose: () => void;
  readonly previewModalOnClose: () => void;
  readonly previewData: BoardDetailData;
  readonly isPreviewModalOpen: boolean;
  readonly isMobile: boolean;
  readonly isRowDirection: boolean;
  readonly modalPadding: string;
  readonly modalInnerRef: React.RefObject<HTMLDivElement>;
  readonly handleFormSubmit: ButtonProps['onClick'];
  readonly contentWidth: string;
  readonly modalScrollRef: React.RefObject<HTMLDivElement>;
  readonly modalBoardFormRef: React.RefObject<HTMLDivElement>;
  readonly modalPreviewRef: React.RefObject<HTMLDivElement>;
};

const BoardForm: React.FC<BoardFormProps> = ({
  isLoading,
  methods,
  onSubmit,
  id: formId = 'board-form',
  formTitle,
  categoryOptions,
  category,
  displaySectionOptions,
  commentPositionOptions,
  importantOptions,
  uploadTimeOptions,
  showUploadTimeSelector,
  autoSavedTime,
  submitButtonType,
  primaryButtonOnClick,
  secondaryButtonOnClick,
  isSubmitButtonDisabled = true,
  isModalOpen,
  modalOnClose,
  isPreviewModalOpen,
  previewModalOnClose,
  previewData,
  isMobile,
  isRowDirection,
  modalInnerRef,
  modalPadding,
  handleFormSubmit,
  contentWidth,
  modalScrollRef,
  modalBoardFormRef,
  modalPreviewRef,
}) => {
  const buttonDisabled = isLoading || isSubmitButtonDisabled;
  const router = useRouter();

  return (
    <>
      {(router.pathname === '/m' || router.pathname.startsWith('/m/')) && (
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=no"
        />
      )}
      <FormProvider methods={methods} onSubmit={onSubmit} id={formId}>
        <BoardFormModal open={isModalOpen} onClose={modalOnClose}>
          <BoardModalPaper
            ref={modalScrollRef}
            className={'modal-parent-scroll'}
            sx={{
              maxHeight: isMobile
                ? '100vh'
                : isPreviewModalOpen
                ? '1070px'
                : 'calc(100vh - 80px)',
              height: isMobile
                ? '100%'
                : isPreviewModalOpen
                ? 'calc(100vh - 80px)'
                : 'initial',
            }}
          >
            <ModalSlide
              className={'modal-slide-wrapper'}
              sx={{
                transform: `translateX(${isPreviewModalOpen ? `-100%` : 0})`,
              }}
            >
              <Slide ref={modalBoardFormRef} sx={{ width: contentWidth }}>
                <ModalHeader>
                  <ModalTitle>{formTitle}</ModalTitle>
                  <ModalCloseButton onClick={modalOnClose} />
                  <ModalHeaderDivider />
                </ModalHeader>
                <ModalBody>
                  {isLoading ? (
                    <BoardFormLoading />
                  ) : (
                    <>
                      <Stack spacing={5}>
                        <FormGroup gapsize={'s'}>
                          <Label
                            isRequired={true}
                            title={'카테고리'}
                            description={'여러 개를 선택할 수도 있어요.'}
                            size={16}
                          />
                          <RHFMultiAutoCompleteHighLightsSelect
                            name={'categories'}
                            options={categoryOptions}
                            labelKey={'label'}
                            valueKey={'value'}
                            placeholder={'선택'}
                            value={category}
                          />
                        </FormGroup>
                        <FormGroup gapsize={'s'}>
                          <Label isRequired={true} title={'제목'} size={16} />
                          <RHFTextField
                            name={'subject'}
                            placeholder={'제목을 입력해 주세요.'}
                            variant={'outlined'}
                            maxLength={100}
                          />
                        </FormGroup>
                        <FormGroup gapsize={'s'}>
                          <Label isRequired={false} title={'내용'} size={16} />
                          <RHFLexicalEditor name={'content'} />
                          <ImageSlide />
                        </FormGroup>
                        <FormGroup gapsize={'s'}>
                          <Label
                            isRequired={false}
                            title={'파일첨부'}
                            description={
                              '파일은 개당 최대 500MB까지 첨부할 수 있어요. (총 개수와 총 용량 제한은 없어요.)'
                            }
                            size={16}
                          />
                          <FileAttachment name="attachments" />
                        </FormGroup>
                      </Stack>
                      <FormConfig defaultExpanded>
                        <FormConfigHeader
                          expandIcon={
                            <Box
                              sx={{
                                transform: 'translate(-180)',
                                width: '29px',
                                height: '29px',
                              }}
                            >
                              <ExpendIcon />
                            </Box>
                          }
                          aria-controls="board-config-content"
                          id="board-config-header"
                        >
                          <FormConfigHeaderTitle>
                            게시글 설정
                          </FormConfigHeaderTitle>
                        </FormConfigHeader>
                        <FormConfigBody>
                          <FormGroup gapsize={'m'}>
                            <DisplaySections options={displaySectionOptions} />
                          </FormGroup>
                          <FormGroup gapsize={'m'}>
                            <Label
                              isRequired={false}
                              title={'코멘트가 보여질 위치를 선택해 주세요.'}
                              description={
                                <>
                                  콘텐츠가 중요한 글이라면 ‘게시글 아래’, 반응이
                                  중요한 글이라면 ‘게시글 오른쪽’을 추천드려요.{' '}
                                  <br />
                                  모바일에서는 설정과 관계없이 게시글 아래로
                                  표현됩니다.
                                </>
                              }
                              size={16}
                            />
                            <RHFImageOptionSelector
                              name="commentPosition"
                              options={commentPositionOptions}
                            />
                          </FormGroup>
                          <FormGroup gapsize={'m'}>
                            <Label
                              isRequired={false}
                              title={'게시글을 언제 올리실 건가요?'}
                              description={
                                '‘예약하기’를 선택하면 설정한 시간에 게시글이 등록됩니다.'
                              }
                              size={16}
                            />
                            <RHFRadio
                              name={'useUploadTime'}
                              options={uploadTimeOptions}
                            />
                            {showUploadTimeSelector && (
                              <RHFDateTimePicker name={'displayTime'} />
                            )}
                          </FormGroup>
                          <FormGroup gapsize={'m'}>
                            <Label
                              isRequired={false}
                              title={'중요 게시글로 설정할건가요?'}
                              description={
                                '중요 게시글은 제목에 강조 효과가 적용됩니다.'
                              }
                              size={16}
                            />
                            <RHFRadio
                              name={'isImportant'}
                              options={importantOptions}
                            />
                          </FormGroup>
                        </FormConfigBody>
                      </FormConfig>
                    </>
                  )}
                </ModalBody>
                <ModalFooter>
                  <AutoSavedTime>
                    {autoSavedTime ? `${autoSavedTime} 자동저장` : ''}
                  </AutoSavedTime>
                  {submitButtonType === 'two' ? (
                    <ButtonWrapper>
                      <BoxButton
                        variant={'secondary'}
                        disabled={buttonDisabled}
                        onClick={secondaryButtonOnClick}
                        sx={{ width: '140px' }}
                      >
                        임시저장
                      </BoxButton>
                      <BoxButton
                        variant={'primary'}
                        disabled={buttonDisabled}
                        onClick={primaryButtonOnClick}
                        sx={{ width: '200px' }}
                      >
                        미리보고 등록하기
                      </BoxButton>
                    </ButtonWrapper>
                  ) : (
                    <BoxButton
                      variant={'primary'}
                      onClick={primaryButtonOnClick}
                      disabled={buttonDisabled}
                      sx={{ width: '200px' }}
                    >
                      미리보고 등록하기
                    </BoxButton>
                  )}
                </ModalFooter>
              </Slide>
              <Slide
                sx={{ width: contentWidth }}
                ref={modalPreviewRef}
                className={'modal-content-box'}
              >
                <ModalHeader sx={{ marginBottom: 0 }}>
                  <IconButton
                    disableRipple
                    sx={{
                      padding: 0,
                      marginRight: '6px',
                      '&:hover': {
                        backgroundColor: 'initial',
                      },
                    }}
                    onClick={previewModalOnClose}
                  >
                    <BackButton
                      sx={{
                        width: '28px',
                        height: '20px',
                        padding: '7px',
                        boxSizing: 'border-box',
                      }}
                    />
                  </IconButton>
                  <ModalTitle>미리보기</ModalTitle>
                  <ModalCloseButton onClick={modalOnClose} />
                  <ModalHeaderDivider />
                </ModalHeader>
                <Box
                  ref={modalInnerRef}
                  sx={{
                    '--modal-padding': modalPadding,
                    '--extra-height': '96px',
                    '--board-detail-comment-width': isRowDirection
                      ? '420px'
                      : null,
                    width:
                      'calc(100% - var(--board-detail-comment-width, 0px))',
                    padding: '0 24px',
                  }}
                >
                  {isPreviewModalOpen && (
                    <BoardDetail
                      isModal={true}
                      scrollRef={modalInnerRef}
                      data={previewData}
                      isRowDirection={isRowDirection}
                      headerId={`preview-modal-board-detail-header`}
                      disabled={true}
                      sx={{
                        minHeight: '100%',
                        paddingBottom: isRowDirection ? '0px' : '96px',
                        '--board-detail-offset-top': isMobile ? '60px' : '56px',
                      }}
                    />
                  )}
                </Box>
                <ModalFooter>
                  <ButtonWrapper>
                    <BoxButton
                      variant={'secondary'}
                      onClick={previewModalOnClose}
                      sx={{
                        width: '171px',
                      }}
                    >
                      <BackButton
                        sx={{
                          width: '20px',
                          height: '20px',
                          marginRight: '4px',
                          padding: '4px',
                          '> svg': {
                            width: '100%',
                          },
                        }}
                      />
                      작성으로 돌아가기
                    </BoxButton>
                    <BoxButton
                      variant={'primary'}
                      form={formId}
                      disabled={isSubmitButtonDisabled}
                      onClick={handleFormSubmit}
                      sx={{ width: '200px' }}
                    >
                      등록하기
                    </BoxButton>
                  </ButtonWrapper>
                </ModalFooter>
              </Slide>
            </ModalSlide>
          </BoardModalPaper>
        </BoardFormModal>
      </FormProvider>
    </>
  );
};

export default BoardForm;

export const ExpendIcon = () => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="28 / Arrow01">
      <path
        id="shape"
        d="M8.66668 11.6667L14.5 17.5L20.3333 11.6667"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const BackButton: React.FC<
  PropsWithChildren<
    Pick<IconButtonProps, 'onClick'> & {
      sx?: SxProps<Theme>;
    }
  >
> = ({ sx }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      width: '24px',
      height: '24px',
      padding: 0,
      '&:hover': {
        backgroundColor: 'initial',
      },
      ...sx,
    }}
  >
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="shape">
        <path
          id="Vector"
          d="M2 9L17.1667 9"
          stroke="black"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M9.00016 0.833984L0.833496 9.00065L9.00016 17.1673"
          stroke="black"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  </Box>
);
