import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';

export const ModalInner = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 80px);
  max-width: 1168px;
  min-width: 688px;
  max-height: calc(100vh - 80px);
  border-radius: 8px;
  transition: width 0.3s ease, height 0.3s ease, max-width 0.3s ease,
    max-height 0.3s ease;
  &:focus-visible {
    outline: 0;
  }
  &.x-small {
    min-width: 438px;
    max-width: 438px;
  }
  &.small {
    min-width: auto;
    max-width: 688px;
  }
  &.fixedHeight {
    height: calc(100vh - 80px);
  }
  &.expand {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
    .modal-header {
      border-radius: 0;
    }
    .modal-body {
      max-height: calc(100% - 60px);
    }
  }
`;

export const ModalHeader = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(249, 250, 251, 0.9);
  backdrop-filter: blur(6px);
  border-bottom: 1px solid #e7e9ec;
  padding: 6px 12px 6px 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  h1 {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    color: black;
  }
  .button-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    button {
      padding: 6px;
    }
  }
`;
export const ModalBody = styled(Box)`
  position: relative;
  height: 100%;
  max-height: calc(100vh - 80px - 60px);
  padding: 32px 24px;
  overflow: auto;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
