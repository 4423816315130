import { RemoteFile } from '@/components/file-thumbnail/utils';

export const copyToClipboard = (
  start: number,
  end: number,
  id: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  //TODO : NEED SOMETHING NEW LOGIC ?!?
  const range = new Range();

  const temp = document.getElementById(id);

  if (temp) {
    range.setStart(temp, start);
    range.setEnd(temp, end);

    document.getSelection()?.removeAllRanges();
    document.getSelection()?.addRange(range);

    if (document.execCommand('copy')) {
      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess();
      }
    } else {
      if (onError && typeof onError === 'function') {
        onError();
      }
    }
    document.getSelection()?.removeAllRanges();
  }
};

export const copyClipBoardText = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (e) {
    return false;
  }
};

export const scrollToElement = (element: HTMLElement, offset = 0) => {
  if (element) {
    const elementPosition =
      element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
  }
};

export const downloadFile = async (file: RemoteFile) => {
  try {
    const response = await fetch(file.link);

    if (!response.ok) return '파일을 다운로드할 수 없습니다.';

    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);
    return true;
  } catch (error) {
    return `${error}` || '파일을 다운로드 하는데 실패했습니다';
  }
};
