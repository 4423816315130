import { Stack } from '@mui/material';
import { styled } from '@mui/system';

export const ButtonWrapper = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  > * {
    flex: 1;
  }
`;
