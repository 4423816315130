import { Box, Link, Stack, styled } from '@mui/material';

export const IDCardBand = styled('img')`
  position: absolute;
  width: 110px;
  left: calc(320px / 2 - 110px / 2);
  top: -292.5px;
  z-index: 1;
`;

export const IDCardBox = styled(Box)`
  width: 320px;
  border-radius: 16px;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08);
`;

export const IDCardImageWrapper = styled(Box)`
  position: relative;
  width: 320px;
  height: 340px;
  margin: 0 auto;
  background-image: url('/images/profile/background.png');
  background-size: cover;
  .profile-image-skeleton {
    z-index: 10;
    background-color: white;
  }
  img,
  .profile-image-skeleton {
    position: absolute;
    bottom: 24px;
    left: calc(100% / 2 - 90px);
    width: 180px;
    height: 180px;
    border-radius: 12px;
    object-fit: cover;
  }
`;

export const IDCardContentWrapper = styled(Stack)`
  padding: 12px;
  flex-direction: column;
  gap: 16px;
`;

export const IDCardContent = styled(Stack)`
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
    word-break: break-word;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #888d96;
  }
  .profile-name {
    display: flex;
    align-items: center;
    gap: 4px;
    strong {
      font-weight: 500;
      font-size: 20px;
      color: black;
    }
  }
`;

export const DirectContactButtonsBox = styled(Stack)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const DirectContactButton = styled(Link)<{ disabled?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  border: 1px solid #e7e9ec;
  border-radius: 6px;
  padding: 10px 4px;
  background-color: white;
  text-decoration: none;
  &.disabled {
    opacity: 0.5;
    background-color: #f9fafb;
    pointer-events: none;
  }
  svg {
    width: 28px;
    height: 28px;
  }
  span {
    font-size: 10px;
    color: black;
  }
`;
