import React from 'react';
import {
  DialogActions,
  DialogContainer,
  DialogContent,
  DialogHeader,
} from './styles';
import {
  Button,
  DialogProps as MuiDialogProps,
  DialogTitle,
} from '@mui/material';
import classNames from 'classnames';
import CloseIcon from '@/public/icons/close_black.svg';
import BoxButton from '@/components/@IntranetLibrary/Button/BoxButton';
import { ButtonWrapper } from '@/components/@IntranetLibrary/Button/ButtonWrapper';

export type DialogType = 'confirm' | 'alert';

export type DialogOptions = {
  id?: string;
  type?: DialogType;
  title?: React.ReactNode;
  message?: React.ReactNode;
  invisibleButton?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onCancel?: () => Promise<void> | void;
  onConfirm?: () => Promise<void> | void;
  onAfterClose?: () => void;
  onClickBackdrop?: () => void;
  isBlockBackDropClose?: boolean;
  forceToFront?: boolean; // 최상위 z-index
  dialogProps?: MuiDialogProps;
};

type DialogProps = {
  open: boolean;
  onClose: () => void;
  onClickCancel: () => void;
  onClickConfirm: () => void;
  dialogOptions: DialogOptions;
};

const Dialog = ({
  open,
  onClose,
  onClickCancel,
  onClickConfirm,
  dialogOptions,
}: DialogProps) => {
  const {
    type,
    title,
    message,
    cancelButtonText,
    confirmButtonText,
    dialogProps,
    forceToFront,
    isBlockBackDropClose,
    onClickBackdrop,
    invisibleButton = false,
  } = dialogOptions;

  const handleClose = () => {
    onClose();
  };

  const handleCancel = () => {
    onClickCancel();
    handleClose();
  };

  const handleConfirm = () => {
    onClickConfirm();
    handleClose();
  };

  return (
    <DialogContainer
      {...dialogProps}
      open={open}
      className={classNames({ 'high-z-index': forceToFront })}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          onClickBackdrop?.();
          if (isBlockBackDropClose) return;
        }
        handleClose();
      }}
    >
      <DialogHeader
        className={classNames('dialog-header', { hideTitle: !title })}
      >
        <DialogTitle>{title}</DialogTitle>
        <Button className={'close'} onClick={handleClose}>
          <CloseIcon width={'24px'} height={'24px'} />
        </Button>
      </DialogHeader>

      <DialogContent className={classNames('dialog-content')}>
        {message}
      </DialogContent>

      {!invisibleButton && (
        <DialogActions>
          <ButtonWrapper>
            {type === 'confirm' && (
              <BoxButton
                variant={'secondary'}
                size={'large'}
                onClick={handleCancel}
              >
                {cancelButtonText}
              </BoxButton>
            )}
            <BoxButton
              variant={'primary'}
              size={'large'}
              onClick={handleConfirm}
            >
              {confirmButtonText}
            </BoxButton>
          </ButtonWrapper>
        </DialogActions>
      )}
    </DialogContainer>
  );
};

export default Dialog;
