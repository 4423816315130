import React, { useEffect, useRef, useState } from 'react';
import { setJwtToken } from '@/utils/tokens';
import {
  Box,
  CardMedia,
  CardProps,
  CircularProgress,
  Stack,
} from '@mui/material';

type IframeWithLoadingProps = {
  readonly url: string;
  readonly isFullHeight?: boolean;
  readonly showSpinner?: boolean;
  readonly loading?: JSX.Element;
} & Omit<CardProps<'iframe'>, 'component'>;

const IframeWithLoading = ({
  url,
  isFullHeight,
  showSpinner = true,
  loading,
  ...props
}: IframeWithLoadingProps) => {
  const cardRef = useRef<HTMLIFrameElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadingIconVisible, setIsLoadingIconVisible] = useState(true);

  useEffect(() => {
    setIsLoadingIconVisible(Boolean(showSpinner && !isLoaded));
  }, [showSpinner, isLoaded]);

  return (
    <Stack
      sx={{
        position: 'relative',
        overflow: 'auto',
        height: '100%',
      }}
    >
      {isLoadingIconVisible && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: cardRef.current?.clientWidth ?? '100%',
            height: cardRef.current?.clientHeight ?? '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loading || <CircularProgress color="inherit" />}
        </Box>
      )}
      <CardMedia
        ref={cardRef}
        component={'iframe'}
        image={setJwtToken(`${url}`)}
        onLoad={(e) => {
          setIsLoaded(true);
          props?.onLoad?.(e);
        }}
        {...props}
      />
    </Stack>
  );
};

export default IframeWithLoading;

export const FullHeightHeightIframeWithLoading = ({
  sx,
  ...props
}: IframeWithLoadingProps) => {
  return (
    <IframeWithLoading
      {...props}
      sx={{ height: '100%', borderTop: 'none', ...sx }}
    />
  );
};

export const NinetyViewPortHeightIframeWithLoading = ({
  sx,
  ...props
}: IframeWithLoadingProps) => {
  return (
    <IframeWithLoading
      {...props}
      sx={{ height: '90vh', borderTop: 'none', ...sx }}
    />
  );
};

export const FullViewPortHeightIframeWithLoading = ({
  sx,
  ...props
}: IframeWithLoadingProps) => {
  return (
    <IframeWithLoading
      {...props}
      sx={{ height: '100vh', borderTop: 'none', ...sx }}
    />
  );
};
