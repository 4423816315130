import React from 'react';
import { DialogContext } from '@/components/@IntranetLibrary/Dialog/DialogProvider';

export const useDialog = () => {
  const dialog = React.useContext(DialogContext);

  if (!dialog) {
    throw new Error('useDialog must be used within a DialogProvider');
  }

  return dialog;
};
