import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatBirthday } from '@/utils/date';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Passport } from '@/types/api';
import {
  ContentsWrapper,
  JobDescriptionWrapper,
  ProfileContentWrapper,
} from './styles';
import useToast from '@/hooks/useToast';
import { BoxProps } from '@mui/material';
import LinkIcon from '@/public/icons/link2.svg';

type ProfileContentProps = { passport: Passport } & BoxProps;

const ProfileContent = ({ passport, sx, ...props }: ProfileContentProps) => {
  const toast = useToast();
  const { t, i18n } = useTranslation();

  const handleToastMessage = (isEmail?: boolean) => {
    toast.showSuccess({
      message: isEmail
        ? '이메일이 복사되었습니다.'
        : '휴대폰 번호가 복사되었습니다.',
    });
  };

  return (
    <ProfileContentWrapper sx={sx} {...props}>
      <ContentsWrapper>
        <div className={'row'}>
          <p className={'title'}>{t('법인')}</p>
          <p className={'content'}>주식회사 에프앤에프</p>
        </div>
        <div className={'row'}>
          <p className={'title'}>{`${t('생일')} (${
            passport.detail.birthdayType === 0 ? t('양력') : t('음력')
          })`}</p>
          <p className={'content'}>
            {formatBirthday(passport.detail.birthday)}
          </p>
        </div>
        <div className={'row'}>
          <p className={'title'}>{t('이메일')}</p>
          <p className={'content'}>
            {passport.detail.email ? (
              <CopyToClipboard
                text={passport.detail.email}
                onCopy={(_, result) => {
                  if (result) handleToastMessage(true);
                }}
              >
                <button>
                  {passport.detail.email}
                  <LinkIcon width={'20px'} height={'20px'} />
                </button>
              </CopyToClipboard>
            ) : (
              '-'
            )}
          </p>
        </div>
        <div className={'row'}>
          <p className={'title'}>{t('내선전화')}</p>
          <p className={'content'}>{passport.detail.phoneInside || '-'}</p>
        </div>
        <div className={'row'}>
          <p className={'title'}>{t('휴대폰')}</p>
          <p className={'content'}>
            {passport.detail.phoneMobile ? (
              <CopyToClipboard
                text={passport.detail.phoneMobile}
                onCopy={() => handleToastMessage(false)}
              >
                <button>
                  {passport.detail.phoneMobile}
                  <LinkIcon width={'20px'} height={'20px'} />
                </button>
              </CopyToClipboard>
            ) : (
              '-'
            )}
          </p>
        </div>
        {passport.job && (
          <div className={'row'}>
            <p className={'title'}>{t('직무')}</p>
            <p className={'content'}>{passport.job}</p>
          </div>
        )}
      </ContentsWrapper>

      {/* 업무소개 */}
      <JobDescriptionWrapper>
        <h2>{t('업무소개')}</h2>
        {!passport.jobDescription.summary &&
        !passport.jobDescription.content ? (
          <p className={'empty'}>
            {t('등록된 업무소개가 없습니다.')}{' '}
            {t(
              '{이름}님께 어떤 일을 하는지 소개해달라고 요청해 보세요.',
            ).replace('{이름}', passport.detail.name)}
          </p>
        ) : (
          <>
            <p className={'summary'}>
              {passport.jobDescription.summary || '-'}
            </p>
            <p className={'content'}>
              {passport.jobDescription.content || '-'}
            </p>
          </>
        )}
      </JobDescriptionWrapper>

      {/* 업데이트 날짜 */}
      {passport.jobDescription.updatedAt && (
        <p className={'updateAt'}>
          최근 업데이트: {passport.jobDescription.updatedAt}
        </p>
      )}
    </ProfileContentWrapper>
  );
};

export default ProfileContent;
