import MsTeamsIcon from '@/public/images/icon/ms_teams.svg';
import { Typography } from '@mui/material';
import Link, { LinkProps } from '@/components/@IntranetLibrary/Link';
import * as React from 'react';

type OpenTeamsLinkButtonProps = {
  email: string;
} & Omit<LinkProps, 'variant'>;

const OpenTeamsLinkButton = (_props: OpenTeamsLinkButtonProps) => {
  const { email, ...props } = _props;
  return (
    <Link
      variant={'secondary'}
      href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`}
      target={'_blank'}
      rel={'noreferrer'}
      {...props}
    >
      <MsTeamsIcon />
      <Typography sx={{ marginLeft: '4px', fontWeight: 400, fontSize: '14px' }}>
        Open Teams
      </Typography>
    </Link>
  );
};

export default OpenTeamsLinkButton;
