import { Stack, SxProps } from '@mui/material';
import React from 'react';
import {
  CardBackground,
  SignatureCard as SignatureCardType,
} from '../@hooks/constants';
import { SignatureData } from '@/hooks/apiHooks';
import { imgUrlChk } from '@/utils/function';
import SignatureCardHeader from './CardHeader';

export type SignatureCardProps = SignatureCardType & {
  readonly data: SignatureData;
  readonly copyButtonOnClick: (idName: string) => () => void;
  readonly wrapperStyle?: SxProps;
  readonly key: string;
};

const SignatureCard = ({
  data: {
    name,
    nickName,
    displayOrganizationShortName,
    companyLogoImageUrl,
    email,
    insidePhone,
    mobilePhone,
    homePageUrl,
    companyAddress,
  },
  title,
  id,
  hasLogo,
  copyButtonOnClick,
  wrapperStyle,
  color,
}: SignatureCardProps) => {
  const maxWidth = color === CardBackground.Blue ? '100px' : 'initial';
  const subColor = color === CardBackground.Blue ? '#D4D7DB' : '#f4ede8';
  const defaultStyle = {
    display: 'block',
    margin: 0,
    padding: 0,
    color: subColor,
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '16px',
    textDecoration: 'none !important',
  };

  return (
    <Stack sx={wrapperStyle}>
      <SignatureCardHeader
        title={title}
        copyButtonOnClick={copyButtonOnClick(id)}
      />
      <table cellPadding="0" cellSpacing="0" id={id}>
        <tbody>
          <tr>
            <td
              style={{
                background: color,
                boxSizing: 'border-box',
                padding: '16px 40px',
                width: '320px',
              }}
            >
              <table cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <p
                        style={{
                          display: 'block',
                          padding: 0,
                          margin: 0,
                          fontSize: '14px',
                          fontWeight: 700,
                          color: '#ffffff',
                          lineHeight: '20px',
                        }}
                      >
                        {name} {nickName}
                      </p>
                      <p
                        style={{
                          display: 'block',
                          padding: 0,
                          margin: 0,
                          fontSize: '12px',
                          fontWeight: 400,
                          color: subColor,
                          lineHeight: '18px',
                        }}
                      >
                        {displayOrganizationShortName}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td width="80" style={{ verticalAlign: 'bottom' }}>
                      {hasLogo && (
                        <img
                          src={imgUrlChk(companyLogoImageUrl)}
                          style={{ height: '26px', width: 'auto' }}
                          alt={'companyLogo'}
                        />
                      )}
                    </td>
                    <td style={{ paddingTop: '8px' }}>
                      <a href={'mailto:' + email} style={defaultStyle}>
                        {email}
                      </a>
                      {insidePhone && (
                        <div>
                          <a href={'tel:' + insidePhone} style={defaultStyle}>
                            {insidePhone}
                          </a>
                        </div>
                      )}
                      <a href={'tel:' + mobilePhone} style={defaultStyle}>
                        {mobilePhone}
                      </a>
                      <a
                        href={
                          homePageUrl.includes('http')
                            ? homePageUrl
                            : `https://${homePageUrl}`
                        }
                        style={defaultStyle}
                      >
                        {homePageUrl}
                      </a>
                      <p
                        style={{
                          ...defaultStyle,
                          wordBreak: 'keep-all',
                          maxWidth,
                        }}
                      >
                        {companyAddress}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </Stack>
  );
};

export default SignatureCard;
