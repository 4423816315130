import React from 'react';
import { Description, Title } from '@/components/@IntranetLibrary/Label/styles';
import { Box } from '@mui/material';

type LabelProps = {
  readonly isRequired: boolean;
  readonly title: React.ReactNode;
  readonly description?: React.ReactNode;
  readonly size: 16 | 14;
};

const Label = ({ size, description, isRequired, title }: LabelProps) => {
  return (
    <Box>
      <Title
        sx={{ fontSize: `${size}px` }}
        className={isRequired ? 'required' : undefined}
        variant="body2"
      >
        {title}
      </Title>
      {description && <Description>{description}</Description>}
    </Box>
  );
};

export default Label;
