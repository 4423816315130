import { MyMenuContent } from '@/constant/mymenu';
import { useResetRecoilState } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import { MyMenuDrawerProps } from '@/components/MenuDrawer/MyMenuDrawer/V2';
import { useFindPassport, useGetVacationStatus } from '@/hooks/apiHooks';
import { useRouter } from 'next/router';
import { disableMessage } from '@/utils/firebase/initialize';
import { TOKEN_KEY_SET } from '@/const/tokens';
import { removeCookie } from '@/utils/tokens';
import { Language, LanguageValue, MyInfo } from '@/types/api';
import { MyPageMainProps } from '@/components/MenuDrawer/MyMenuDrawer/V2/Main';
import { MyMenuDrawerSettings } from '@/components/MenuDrawer/MyMenuDrawer/V2/@hooks/useMyMenuDrawerProps';
import { useMemo } from 'react';
import { useDialog } from '@/hooks/useDialog';
import { AxiosError } from 'axios';

const useMainProps = ({
  myInfo,
  onClose,
  openSubMenu,
  isOpen = false,
}: {
  isOpen?: boolean;
  myInfo: MyInfo;
  openSubMenu: MyMenuDrawerProps['openSubMenu'];
  onClose: MyMenuDrawerSettings['onClose'];
}): MyPageMainProps => {
  const { passportId, departmentName, divisionName, language } = myInfo;
  const { data: passportData, isLoading } = useFindPassport(
    isOpen ? passportId : '',
  );
  const { data: vacationData, error } = useGetVacationStatus(
    isOpen ? passportId : '',
  );

  const remainingVacation = useMemo(() => {
    if (error && (error as AxiosError)) {
      const e = error as AxiosError;
      const data = e.response?.data as any;
      if (data && data.code === '0404') {
        return '';
      }

      return '조회 안됨';
    }

    if (!vacationData) {
      return '조회 안됨';
    }

    const monthlyLeaveGranted = vacationData.monthlyLeave?.granted ?? 0;
    const annualLeaveGranted = vacationData.annualLeave?.granted ?? 0;
    const monthlyLeaveUsed = vacationData.monthlyLeave?.used ?? 0;
    const annualLeaveUsed = vacationData.annualLeave?.used ?? 0;
    const overage = vacationData.overage ?? 0;

    const remainingVacation =
      monthlyLeaveGranted +
      annualLeaveGranted -
      (monthlyLeaveUsed + annualLeaveUsed + overage);

    const isExecutive = monthlyLeaveGranted === 0 && annualLeaveGranted === 0;

    return isExecutive ? '' : `잔여 ${remainingVacation}일`;
  }, [vacationData, error]);

  return {
    menuData: [
      {
        title: '계정',
        subMenu: [
          MyMenuContent.PERSONAL_INFO,
          MyMenuContent.MAIL_SIGNATURE,
          MyMenuContent.LANGUAGE_SETTING,
          MyMenuContent.ALARM_SETTING,
          MyMenuContent.PASSWORD_SETTING,
        ].map((menu) => ({
          menu,
          onClick: () => openSubMenu(menu),
          content: (() => {
            if (menu === MyMenuContent.LANGUAGE_SETTING) {
              return Language[language as LanguageValue];
            }
          })(),
        })),
      },
      {
        title: '직원 서비스',
        subMenu: [
          MyMenuContent.CHECK_VACATION,
          MyMenuContent.CERTIFICATE_OF_EMPLOYMENT,
          MyMenuContent.PAY_STUB,
          MyMenuContent.SHOPPING_MALL_VERIFICATION,
          MyMenuContent.WIFI,
        ].map((menu) => ({
          menu,
          onClick: () => openSubMenu(menu),
          content: (() => {
            if (menu === MyMenuContent.CHECK_VACATION) {
              return remainingVacation;
            }
          })(),
        })),
      },
    ],
    info: {
      ...myInfo,
      department: `${divisionName} • ${departmentName}`,
      isJobDescriptionLoading: isLoading,
      hasJobDescription: Boolean(
        passportData?.jobDescription?.summary &&
          passportData?.jobDescription?.content,
      ),
      jobDescription: passportData?.job ?? '',
      jobDescriptionSummary: passportData?.jobDescription?.summary || '-',
      jobDescriptionContent: passportData?.jobDescription?.content || '-',
    },
    onClickLogout: logout(onClose),
    onClickEditProfile: () => openSubMenu(MyMenuContent.EDIT_PROFILE),
  };
};

export default useMainProps;

const logout = (onClose: () => void) => {
  const dialog = useDialog();
  const router = useRouter();
  const reset = useResetRecoilState(MyInfoState);

  return () => {
    dialog.show({
      message: '로그아웃 하시겠습니까?',
      cancelButtonText: '취소',
      confirmButtonText: '로그아웃',
      onConfirm: async () => {
        await disableMessage();

        reset();
        TOKEN_KEY_SET.forEach(removeCookie);
        onClose();

        setTimeout(
          async () =>
            router
              .push(
                `https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=${process.env.NEXT_PUBLIC_ORIGIN_URL}`,
              )
              .then((_) => console.log('로그아웃 완료')),
          500,
        );
      },
    });
  };
};
