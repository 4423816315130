import { styled } from '@mui/material/styles';
import { StackProps } from '@mui/material';
import React from 'react';
import StackWithDivider from '@/components/StackWithDivider';

const ContentDescription = styled(
  (props: StackProps & { hasVerticalMargin: boolean }) => (
    <StackWithDivider {...props} />
  ),
)(({ hasVerticalMargin }) => ({
  marginTop: hasVerticalMargin ? '28px' : 0,
  marginBottom: hasVerticalMargin ? '28px' : 0,
  padding: '20px 12px',
  backgroundColor: '#F9FAFB',
  borderRadius: '8px',
  gap: '12px',
  fontSize: '14px',
  color: '#888D96',
}));

export default ContentDescription;

export const ContentDivider = styled((props: StackProps & { gap?: string }) => (
  <StackWithDivider {...props} />
))(() => ({}));
