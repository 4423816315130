import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export type ShoppingMallVerificationForm = {
  id: string;
  password: string;
};

const defaultValues: ShoppingMallVerificationForm = {
  id: '',
  password: '',
};

const Schema = Yup.object().shape({
  id: Yup.string().required('아이디를 입력해주세요.'),
  password: Yup.string().required('비밀번호를 입력해주세요.'),
});

const useShoppingMallVerificationForm = () => {
  return useForm<ShoppingMallVerificationForm>({
    resolver: yupResolver(Schema),
    defaultValues,
    mode: 'onChange',
  });
};

export default useShoppingMallVerificationForm;
