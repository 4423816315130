import React, { forwardRef, useState } from 'react';
import Modal, { ModalRef } from 'components/@IntranetLibrary/Modal';
import IframeApproval, { IframeApprovalMessageAction } from './IframeApproval';

type IframeApprovalModalProps = {
  url: string;
  dataItemChange?: (id: string, changes: Record<string, any>) => void;
  handleClose: () => void;
};

const IframeApprovalModal = forwardRef<ModalRef, IframeApprovalModalProps>(
  (props, ref) => {
    const { url, dataItemChange, handleClose } = props;
    const [needCloseMessage, setNeedCloseMessage] = useState(false);

    const onMessage = (action: IframeApprovalMessageAction, data: any) => {
      switch (action) {
        case 'MODAL_CLOSE': {
          handleClose();
          break;
        }
        case 'APPROVAL_CONFIRM': {
          if ('status' in data && 'docid' in data) {
            dataItemChange?.(data.docid, { status: data.status });
          }
          break;
        }
        case 'ENABLE_ALERT': {
          setNeedCloseMessage(true);
          break;
        }
        case 'DISABLE_ALERT': {
          setNeedCloseMessage(false);
          break;
        }
      }
    };

    return (
      <Modal
        ref={ref}
        title={'Approval'}
        isFixedHeight
        isExpandable
        availableAutoClose={false}
        handleClose={handleClose}
      >
        <IframeApproval
          url={url}
          needCloseMessage={needCloseMessage}
          onMessage={onMessage}
        />
      </Modal>
    );
  },
);
IframeApprovalModal.displayName = 'IframeApprovalModal';

export default IframeApprovalModal;
