import React, { PropsWithChildren, ReactNode } from 'react';
import {
  Button,
  ClickAwayListener,
  Popper as MuiPopper,
  PopperProps as MuiPopperProps,
} from '@mui/material';
import {
  PopperBody,
  PopperHeader,
  PopperInner,
} from '@/components/@IntranetLibrary/Popper/styles';
import CloseIcon from '@/public/icons/close_black.svg';

export type PopperProps = {
  anchorEl: HTMLElement | null;
  title: string;
  additionalButton?: ReactNode;
  isClickAwayClose?: boolean;
  onClose: () => void;
  zIndex?: number;
} & PropsWithChildren &
  Omit<MuiPopperProps, 'open' | 'anchorEl'>;

// usePopper Hook 과 같이 사용 권장
const Popper = (_props: PopperProps) => {
  const {
    children,
    anchorEl,
    title,
    additionalButton,
    isClickAwayClose = true,
    onClose,
    zIndex,
    modifiers,
    sx,
    ...props
  } = _props;
  const isOpen = Boolean(anchorEl);

  return (
    <ClickAwayListener
      onClickAway={(event) => {
        if (
          !isClickAwayClose ||
          !anchorEl ||
          (event.target && anchorEl.contains(event.target as HTMLElement))
        ) {
          return;
        }
        onClose();
      }}
    >
      <MuiPopper
        open={isOpen}
        anchorEl={anchorEl}
        placement={'bottom-start'}
        disablePortal={true}
        modifiers={
          modifiers || [{ name: 'preventOverflow', options: { padding: 10 } }]
        }
        sx={{ zIndex, ...sx }}
        {...props}
      >
        <PopperInner>
          <PopperHeader>
            <h1>{title}</h1>
            <div className={'button-box'}>
              {additionalButton}
              <Button className={'close'} onClick={onClose}>
                <CloseIcon width={'24px'} height={'24px'} />
              </Button>
            </div>
          </PopperHeader>
          <PopperBody>{children}</PopperBody>
        </PopperInner>
      </MuiPopper>
    </ClickAwayListener>
  );
};

export default Popper;
