import React from 'react';
import { Box, Stack, StackProps, Typography } from '@mui/material';
import ContentDescription from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';
import Radio, { RadioProps } from '@/components/@IntranetLibrary/Radio';
import { styled } from '@mui/system';

export const ItemWrapper = styled(Box)`
  width: 100%;
  display: flex;
`;

export const ItemTitle = styled(Box)`
  flex: 1;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const ItemContent = styled(Box)`
  flex: none;
`;

export const Section = styled(Stack)`
  gap: 12px;
`;

export const SectionTitle = styled(Typography)`
  color: #888d96;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const SectionContent = styled(
  ({ children, sx, ...props }: StackProps) => (
    <ContentDescription
      {...props}
      sx={{
        ...sx,
        padding: '16px 12px',
      }}
      hasVerticalMargin={false}
    >
      {children}
    </ContentDescription>
  ),
)(() => ({
  gap: '24px',
}));

export const RadioSelectWrapper = styled(
  ({ children, ...props }: StackProps) => <Stack {...props}>{children}</Stack>,
)(() => ({
  gap: '16px',
}));

export const RadioSelect = styled(
  ({ children, ...props }: RadioProps<string>) => (
    <Radio {...props}>{children}</Radio>
  ),
)(() => ({
  gap: '16px',
  '& .MuiFormControlLabel-root': {
    padding: '0',
    margin: '0',
    gap: '12px',
  },
  '& .MuiTypography-root': {
    color: '#000',
    fontSize: '14px',

    '&.Mui-disabled': {
      color: '#000 !important',
    },
  },
}));
