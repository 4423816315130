import React from 'react';
import { ButtonProps } from '@mui/material';
import { Button } from './styles';
import Spinner from '@/components/@IntranetLibrary/Spinner';

export type BoxButtonProps = Omit<
  ButtonProps,
  'variant' | 'disableRipple' | 'size'
> & {
  readonly variant: 'primary' | 'secondary'; //Small, float 은 나중에 구현
  readonly loading?: boolean;
  readonly size?: 'large' | 'small';
};

const BoxButton = ({
  loading,
  children,
  variant,
  ...props
}: BoxButtonProps) => {
  if (loading) {
    return (
      <Button {...props} variant={variant} disabled={true}>
        <Spinner theme={variant === 'primary' ? 'white' : 'black'} />
      </Button>
    );
  } else {
    return (
      <Button {...props} variant={variant}>
        {children}
      </Button>
    );
  }
};

export default BoxButton;
