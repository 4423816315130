import { Box, Stack } from '@mui/material';
import { styled } from '@mui/system';

export const PopperInner = styled(Box)`
  border-radius: 8px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
`;
export const PopperHeader = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #e7e9ec;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: rgba(249, 250, 251, 0.9);
  backdrop-filter: blur(6px);
  h1 {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    color: black;
  }
  .button-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    button {
      padding: 0;
      border-radius: 4px;
      &:hover {
        background-color: rgba(99, 115, 129, 0.08);
      }
    }
  }
`;
export const PopperBody = styled(Stack)`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: white;
`;
