import React from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { getButtonStyles } from '@/components/@IntranetLibrary/Button/styles';
import { BoxButtonProps } from '@/components/@IntranetLibrary/Button/BoxButton';

export type LinkProps = Omit<
  MuiLinkProps,
  'variant' | 'disableRipple' | 'size'
> &
  Pick<BoxButtonProps, 'variant' | 'size'>;

const Link: React.FC<LinkProps> = ({
  children,
  variant,
  size,
  sx,
  ...props
}) => {
  return (
    <MuiLink
      {...props}
      sx={{
        ...getButtonStyles(size, variant),
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        textDecoration: 'none',
        textAlign: 'center',
        ...sx,
      }}
    >
      {children}
    </MuiLink>
  );
};

export default Link;
