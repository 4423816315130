import React from 'react';
import { Stack, StackProps, styled } from '@mui/material';

export const CommentItem = styled(({ children, ...props }: StackProps) => (
  <Stack direction={'row'} {...props}>
    {children}
  </Stack>
))(() => ({
  position: 'relative',
  width: '100%',
  padding: '0 8px',
  '&:first-of-type': { marginTop: '20px' },
  '.profile-image': { borderRadius: '100%', objectFit: 'cover' },
  '.name': {
    fontWeight: 500,
    fontSize: '14px',
    color: 'black',
  },
  'button.like': {
    display: 'inline-flex',
    alignItems: 'center',
    minWidth: 'auto',
    padding: 0,
  },
  '.likeCount': {
    display: 'inline-block',
    marginLeft: '2px',
    fontSize: '14px',
    color: '#888D96',
    lineHeight: '20px',
  },
  '.createdAt': {
    fontSize: '14px',
    color: '#B7BBC2',
    lineHeight: '20px',
  },
  'button.myCommentMenu': {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
    minWidth: '24px',
  },
}));
