import React, { forwardRef, ReactNode, useState } from 'react';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@/public/icons/close_black.svg';
import ExpandIcon from '@/public/icons/expand.svg';
import CollapseIcon from '@/public/icons/collapse.svg';
import TopToScroll from '@/components/TopToScroll';
import { BoxProps } from '@mui/material/Box';
import classNames from 'classnames';

type ModalInnerProps = {
  contentRef?: React.RefObject<HTMLDivElement>;
  isMobile: boolean;
  title?: string;
  extraNode?: ReactNode;
  onClose: () => void;
} & BoxProps;

export const ModalInner = forwardRef<HTMLDivElement, ModalInnerProps>(
  (_props, ref) => {
    const {
      children,
      isMobile,
      title = '',
      contentRef,
      extraNode,
      onClose,
      sx,
      ...props
    } = _props;

    const [isExpand, setIsExpand] = useState(false);

    return (
      <Box
        className={classNames({ isExpand })}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: isMobile ? 'auto' : '400px',
          width: isMobile ? '100%' : 'min(calc(100% - 80px), 1168px)',
          height: isMobile ? '100%' : 'calc(100vh - 80px)',
          maxHeight: isMobile ? '100%' : 'auto',
          borderRadius: isMobile ? 0 : '8px',
          overflow: 'hidden',
          padding: 'var(--modal-padding, 0px) var(--modal-padding, 0px) 0',
          backgroundColor: 'white',
          transition: 'width 0.3s ease, height 0.3s ease',
          '&.isExpand': {
            width: '100%',
            height: '100%',
            borderRadius: 0,
          },
          '.button_wrap': {
            position: 'absolute',
            top: 'calc(var(--modal-padding, 0px) - var(--modal-close-diff, 4px))',
            right:
              'calc(var(--modal-padding, 0px) - var(--modal-close-diff, 4px))',
            zIndex: 3,
            display: 'block',
            button: { padding: 0 },
            'button.close': { padding: '2px' },
          },
          '.modal-content': {
            '&:focus': { outline: 'none' },
            position: 'relative',
            // modal-header, extraNode 크기 만큼 제외
            width: 'calc(100% - var(--board-detail-comment-width, 0px))',
            height:
              'calc(100% - var(--extra-height, 0px) - var(--modal-content-offset-top, 0px))',
            marginTop: 'var(--modal-content-offset-top, 0px)',
            borderTop: 'var(--modal-content-border, 0px) solid #E7E9EC',
            overflowY: 'auto',
          },
          ...sx,
        }}
        {...props}
      >
        {title && (
          <Typography
            className={'modal-title'}
            sx={{
              position: 'relative',
              fontSize: isMobile ? '20px' : '28px',
              fontWeight: '500',
              color: '#000',
              lineHeight: '32px',
              borderBottom: '2px solid black',
              paddingBottom: '6px',
              zIndex: 3,
            }}
          >
            {title}
          </Typography>
        )}
        <Stack direction={'row'} spacing={'8px'} className={'button_wrap'}>
          {!isMobile && (
            <Tooltip
              title={isExpand ? '축소해서 보기' : '확장해서 보기'}
              placement="top"
              arrow
              slotProps={{
                tooltip: { sx: { backgroundColor: 'black', color: 'white' } },
                arrow: { sx: { color: 'black' } },
                popper: {
                  modifiers: [{ name: 'offset', options: { offset: [0, -8] } }],
                },
              }}
            >
              <Button
                className={'expand'}
                onClick={() => {
                  if (isMobile) return;
                  setIsExpand(!isExpand);
                }}
              >
                {isExpand ? (
                  <CollapseIcon width={'24px'} height={'24px'} />
                ) : (
                  <ExpandIcon width={'24px'} height={'24px'} />
                )}
              </Button>
            </Tooltip>
          )}
          <Button className={'close'} onClick={onClose}>
            <CloseIcon width={'20px'} height={'20px'} />
          </Button>
        </Stack>
        <Box className={'modal-content visible-scroll'} ref={ref}>
          {children}
        </Box>
        {extraNode}

        <TopToScroll scrollableDivRef={contentRef} />
      </Box>
    );
  },
);
ModalInner.displayName = 'BoardDetailModalInner';
