import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { LanguageValue, MyInfo } from '@/types/api';
import { RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup';
import useLanguageSettingContentProps from '@/components/MenuDrawer/MyMenuDrawer/V2/@hooks/useLanguageSettingContentProps';
import { ContentDivider } from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';
import { MyMenuDrawerProps } from '@/components/MenuDrawer/MyMenuDrawer/V2';
import BoxButton from '@/components/@IntranetLibrary/Button/BoxButton';

export type SubContentProps = Pick<MyMenuDrawerProps, 'myInfo' | 'menuOnClose'>;

export type LanguageSettingContentProps = Pick<MyInfo, 'language'> & {
  readonly selectedLanguage: LanguageValue;
  readonly selectOptions: { value: string; label: string }[];
  readonly handleSelect: RadioGroupProps['onChange'];
  readonly changeButtonOnClick: () => void;
  readonly isChangeButtonDisabled: boolean;
  readonly isLoading: boolean;
};

const LanguageSettingContent = ({ myInfo }: SubContentProps) => {
  const {
    language,
    selectedLanguage,
    selectOptions,
    handleSelect,
    changeButtonOnClick,
    isChangeButtonDisabled,
    isLoading,
  } = useLanguageSettingContentProps({ myInfo });

  return (
    <ContentDivider>
      <RadioGroup
        defaultValue={language}
        name="language"
        value={selectedLanguage}
        onChange={handleSelect}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          margin: '24px 0 32px',
          '& .MuiFormControlLabel-root': {
            position: 'relative',
            display: 'flex',
            margin: 0,
            gap: '12px',
          },
          '& .MuiButtonBase-root': {
            padding: 0,
          },
          '[data-default="true"]::after': {
            content: '"사용중"',
            display: 'inline-block',
            position: 'absolute',
            right: 0,
            padding: '4px 12px',
            borderRadius: '999px',
            backgroundColor: 'rgba(0, 102, 204, 0.12)',
            fontSize: '12px',
            color: '#0066CC',
          },
        }}
      >
        {selectOptions.map(({ value, label }) => (
          <FormControlLabel
            key={`${value}`}
            value={value}
            control={<Radio />}
            label={label}
            data-default={value === language}
          />
        ))}
      </RadioGroup>
      <BoxButton
        variant={'primary'}
        size={'large'}
        onClick={changeButtonOnClick}
        disabled={isChangeButtonDisabled}
        loading={isLoading}
        sx={{ marginTop: '24px' }}
      >
        변경하기
      </BoxButton>
    </ContentDivider>
  );
};

export default LanguageSettingContent;
