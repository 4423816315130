import React, { useEffect, useRef, useState } from 'react';
import { useNotiTokens, useNotiTopics, useSetToken } from '@/hooks/apiHooks';
import { FIREBASE_TOKEN } from '@/const/tokens';
import { fetchApi } from '@/utils/api/customAxios';
import { getCookie, setCookie } from '@/utils/tokens';
import firebaseApp, { requestPermission } from '@/utils/firebase/initialize';
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
} from '@firebase/messaging';
import process from 'process';
import { useRecoilState } from 'recoil';
import { notiState, socialState } from '@/recoil/atom';
import { getNowFormat } from '@/utils/format';
import { NotificationData } from '@/types/api';

const listSize = 50;
const TOPIC_NAME = 'refresh_social';

export default function NotiTopics() {
  const [state, setState] = useRecoilState(socialState);
  const [noticeList, setNoticeList] = useRecoilState(notiState);
  const { data: topicsData } = useNotiTopics(listSize);
  const { data: tokensData } = useNotiTokens(listSize);

  // 이미 구독 처리가 되었는지 여부를 저장하는 ref
  const subscribedRef = useRef(false);
  const registerRef = useRef(false);

  // 전역 메시지 큐 (메모리 상에 저장)
  const pendingMessages: MessagePayload[] = [];

  useEffect(() => {
    // 문서의 가시성 변화 이벤트를 감지
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        // 포그라운드로 전환되었을 때, 대기 중인 메시지를 처리
        processPendingMessages();
      }
    });

    //서비스 워커에서 백그라운드 이벤트 전달 수신
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'FIREBASE_BACKGROUND_MESSAGING') {
        // 메시지를 즉시 처리하지 않고 큐에 저장
        pendingMessages.push(event.data.payload);
      }
    });
  }, []);

  useEffect(() => {
    if (registerRef.current) return;

    if (typeof navigator !== 'undefined') {
      if ('serviceWorker' in navigator) {
        requestPermission().then((e) => {
          const messaging = getMessaging(firebaseApp);

          getToken(messaging, {
            vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
          })
            .then((token) => {
              useSetToken(token).then((_) => setCookie(FIREBASE_TOKEN, token));
            })
            .catch((e) => console.error(e));
          onMessage(messaging, (payload) => {
            if (payload.data?.refreshSection === 'social') {
              if (document.visibilityState === 'visible') {
                boardApiRefresh(payload);
              } else {
                pendingMessages.push(payload);
              }
            } else {
              const newNotice: NotificationData = {
                title: payload.notification?.title || '',
                body: payload.notification?.body || '',
                link: payload.fcmOptions?.link || '',
                messageId: payload.messageId,
              };

              setNoticeList({
                data: [newNotice, ...noticeList.data],
              });
            }
          });
        });
        registerRef.current = true;
      }
    }
  }, []);

  useEffect(() => {
    // FIREBASE_TOKEN이 없거나, 이미 구독을 진행했다면 더 이상 실행하지 않음
    if (!getCookie(FIREBASE_TOKEN) || subscribedRef.current) return;

    // topicsData와 tokensData가 존재하고 데이터가 있을 때만 실행
    if (topicsData?.length && tokensData?.length) {
      // FIREBASE_TOKEN 쿠키와 일치하는 토큰 객체 찾기
      const tokenObj = tokensData.find(
        (tokenItem) => getCookie(FIREBASE_TOKEN) === tokenItem.token,
      );
      if (!tokenObj?.id) {
        console.warn('No matching browser token found.');
        return;
      }

      const subscribeToTopics = async () => {
        try {
          await Promise.all(
            topicsData.map((topic) => {
              if (topic.topic === TOPIC_NAME) {
                return fetchApi({
                  method: 'POST',
                  url: '/api/v1/notificationCenter/subscribe/topic',
                  baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
                  data: {
                    tokenId: tokenObj.id,
                    topicId: topic.id,
                  },
                });
              }
              // 조건에 맞지 않으면 resolved Promise 반환
              return Promise.resolve();
            }),
          );
          // 구독 작업이 완료되었으므로 플래그 업데이트
          subscribedRef.current = true;
          console.log('Subscription complete.');
        } catch (error) {
          console.error('Error during subscription:', error);
        }
      };

      subscribeToTopics();
    }
  }, [topicsData, tokensData, getCookie(FIREBASE_TOKEN)]);

  // 큐에 저장된 메시지를 처리하는 함수
  const processPendingMessages = () => {
    while (pendingMessages.length > 0) {
      const payload = pendingMessages.shift();
      if (payload) {
        boardApiRefresh(payload); // 저장된 메시지를 이용하여 API 새로고침 실행
        pendingMessages.length = 0; // 리프래쉬 푸쉬는 백그라운드 상태에서 최대 1회만 실행되도록
      }
    }
  };

  const boardApiRefresh = (payload: MessagePayload) => {
    if (payload.data?.refreshSection === 'social') {
      // 5초 ~ 60초 사이의 랜덤 딜레이 (밀리초 단위)
      const randomDelay = Math.floor(Math.random() * (15000 - 3000 + 1)) + 3000; //3초~15초
      console.log(`Refreshing board API after ${randomDelay / 1000} 초 delay`);
      setTimeout(() => {
        setState({
          ...state,
          currentDate: getNowFormat('yyyy-MM-dd HH:mm:ss'),
          isTopicRender: true,
        });
      }, randomDelay);
    }
  };

  return <></>;
}

export function boardWebPushCall() {
  fetchApi({
    method: 'POST',
    url: '/api/v1/webPush/topic/sendByTemplate',
    baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
    data: {
      templateKey: 'topic_refresh',
      templateData: {
        data: {
          refreshSection: 'social',
        },
      },
      topic: TOPIC_NAME,
      sendBy: 'queue',
    },
  });
}
