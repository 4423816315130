import React from 'react';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
  DatePicker as XDatePicker,
  DatePickerProps as XDatePickerProps,
} from '@mui/x-date-pickers';
import { DateSelectorNormalizedStyle } from '@/components/@IntranetLibrary/DatePicker/styles';
import CalendarIcon from '@/public/icons/calendar.svg';

type DatePickerProps = XDatePickerProps<Date> & {
  sx?: SxProps<Theme>;
  placeholder?: string;
  error?: { message?: string };
};

const DatePicker: React.FC<DatePickerProps> = ({
  sx,
  slots,
  placeholder,
  error,
  ...datePickerProps
}) => {
  return (
    <XDatePicker
      {...datePickerProps}
      label={placeholder}
      sx={{
        ...DateSelectorNormalizedStyle,
        ...sx,
      }}
      slots={{
        ...slots,
        openPickerIcon: () => <CalendarIcon width={20} height={20} />,
      }}
      slotProps={{
        textField: { helperText: error?.message ?? '', error: Boolean(error) },
      }}
    />
  );
};

export default DatePicker;
