import React, { Suspense, useState } from 'react';
import { StyledButton, StyledButtonImg } from '@/components/Profile/styles';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import Alarm, { AlarmSuspense } from '@/components/Alarm';
import { imgUrlChk } from '@/utils/function';
import MyMenuDrawer from '../MenuDrawer/MyMenuDrawer/V2';

const Profile = () => {
  const { profileImageUrl, email } = useRecoilValue(MyInfoState);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const profileImage = profileImageUrl.includes('http')
    ? profileImageUrl
    : `https:${profileImageUrl}`;

  return email ? ( // FIXME
    <>
      <StyledButton type={'button'} onClick={handleOpen}>
        <StyledButtonImg
          width={30}
          height={30}
          src={imgUrlChk(profileImage)}
          alt={'profile-small'}
        />
      </StyledButton>
      <MyMenuDrawer isOpen={open} onClose={handleClose} />
      <Suspense fallback={<AlarmSuspense />}>
        <Alarm />
      </Suspense>
    </>
  ) : (
    <div />
  );
};

export default Profile;
