import React, { useEffect } from 'react';
import IframeWithLoading from '@/components/IframeWithLoading';
import useToast from '@/hooks/useToast';
import { useTranslation } from 'react-i18next';

export type IframeApprovalMessageAction =
  | 'MODAL_CLOSE'
  | 'APPROVAL_CONFIRM'
  | 'ENABLE_ALERT'
  | 'DISABLE_ALERT';

type IframeApprovalProps = {
  url: string;
  needCloseMessage: boolean;
  onMessage: (action: IframeApprovalMessageAction, data: any) => void;
};

// Web 에서만 사용 중
const IframeApproval = ({
  url,
  needCloseMessage,
  onMessage,
}: IframeApprovalProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  const handleMessageEvent = (event: MessageEvent) => {
    if (event?.data) {
      try {
        const parsed = JSON.parse(event?.data);
        if (!parsed.actionType) return;

        const actionType = parsed.actionType;

        if (actionType === 'MODAL_CLOSE') {
          if (parsed.severity) {
            toast.show({
              message: t(parsed['toastMessage']),
              severity: parsed['severity'],
            });
          }
        }

        onMessage(actionType, parsed);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = `작성화면을 닫으시겠어요?\n임시저장 되지 않은 내용은 사라집니다.`;
  };

  useEffect(() => {
    window.addEventListener('message', handleMessageEvent);

    if (needCloseMessage) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [needCloseMessage]);

  return (
    <IframeWithLoading url={url} sx={{ height: '100%', borderTop: 'none' }} />
  );
};

export default IframeApproval;
