import React from 'react';
import { useSearchPoint } from '@/hooks/apiHooks';
import { MyInfo } from '@/types/api';
import { DataListGrid, DataListGridItem } from '@/components/DataListGrid';
import Button from '@mui/material/Button';
import { PostMessageActionType, sendPostMessage } from '@/utils/postMessage';
import PlusIcon from '@/public/icons/plus_blue.svg';
import { SubContentProps } from 'components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSetting';
import ContentDescription from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';

const PersonalInfoLabels: { [key: string]: string } = {
  name: '이름',
  companyName: '법인',
  organizationFullName: '소속 (Full)',
  organizationShortName: '소속 (Short)',
  positionName: '직급',
  employeeNumber: '사번',
  email: '이메일',
  phoneInside: '내선전화',
  phoneMobile: '휴대폰 번호',
  employeePoint: '직원 구매 포인트',
} as const;

export const PersonalInfoContent = (props: SubContentProps) => {
  const { myInfo } = props;
  const { data: pointData } = useSearchPoint(myInfo.employeeNumber);

  const requestAddEmployeePoint = () => {
    const url = `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/approval/create?apv_type=54`;
    if (window?.isRNWebview) {
      sendPostMessage(PostMessageActionType.MOVE_TAB, {
        tabName: 'approval',
        url,
      });
    } else {
      window.open(url, '_blank', 'noreferrer');
    }
  };

  return (
    <>
      <ContentDescription hasVerticalMargin={true}>
        인사 정보는 HR팀을 통해서만 등록 및 수정이 가능합니다.
      </ContentDescription>
      <DataListGrid
        rowGap={'24px'}
        columnGap={'12px'}
        gridTemplateColumns={'112px 1fr'}
        sx={{
          marginTop: '16px',
          dt: { padding: '16px 0' },
          dd: {
            padding: '16px 12px',
            borderRadius: '8px',
            backgroundColor: '#F9FAFB',
            fontWeight: 500,
          },
        }}
      >
        {Object.keys(PersonalInfoLabels).map((key, index) => (
          <DataListGridItem
            key={`info-${index}`}
            label={PersonalInfoLabels[key]}
          >
            {key === 'employeePoint'
              ? `${(pointData?.employeePoint || 0).toLocaleString()}원`
              : Object.hasOwn(myInfo, key)
              ? `${myInfo[key as keyof MyInfo] || '-'}`
              : '-'}
          </DataListGridItem>
        ))}
      </DataListGrid>

      <Button
        sx={{ marginTop: '6px', marginLeft: 'auto', fontSize: '14px' }}
        onClick={requestAddEmployeePoint}
      >
        <PlusIcon width={'20px'} height={'20px'} />
        추가 요청
      </Button>
    </>
  );
};
