import { Stack, SxProps, Typography } from '@mui/material';
import { EmptyIcon, ErrorEmptyIcon } from '@/theme/overrides/CustomIcons';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { CSSProperties } from 'react';

type EmptyType = 'error' | 'empty';

type P = {
  title: string;
  type?: EmptyType;
  height?: string;
  isFullScreen?: boolean;
  sx?: SxProps;
};

const EmptyContent = (props: P) => {
  const { title, type = 'empty', height, isFullScreen } = props;
  const defaultStyle: CSSProperties = {};

  if (isFullScreen) {
    defaultStyle.height = height ?? 'calc(100vh - 220px)';
    defaultStyle.minHeight = '220px';
  } else if (height) {
    defaultStyle.height = height;
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={defaultStyle}
      spacing={0.5}
      sx={props.sx}
    >
      {type === 'empty' && <EmptyIcon />}
      {type === 'error' && <ErrorEmptyIcon />}
      <Typography fontSize={14} style={{ color: '#B7BBC2' }} component="div">
        {title}
      </Typography>
    </Stack>
  );
};

export default EmptyContent;
