import React, { PropsWithChildren, useState } from 'react';
import { Box, Container, Stack, SxProps } from '@mui/material';
import Footer from '@/components/Footer/Footer';
import GlobalMenuDrawer from '@/components/MenuDrawer/GlobalMenuDrawer';
import { useAuthContext } from '@/auth/useAuthContext';
import LoadingLottie from '@/components/LoadingLottie';
import useLayoutSize from '@/hooks/useLayoutSize';
import { Theme } from '@mui/material/styles';
import { HeaderZIndex } from '@/constant/z-index';
import { HeaderWrapper, IconButton, Logo } from '@/layout/Default/styles';
import { PageURL } from '@/constant/pageURL';
import LogoImage from '@/public/images/logo_black.svg';
import Profile from '@/components/Profile';
import MenuIcon from '@/public/images/icon/menu_28.svg';
import GnbTab from '@/layout/Default/GnbTab';
import { useRouter } from 'next/router';
import { LayoutType } from '@/constant/layout';
import NotiTopics from '@/components/NotiTopics';

type DefaultLayoutProps = PropsWithChildren<{
  layoutType?: LayoutType;
  containerSx?: SxProps<Theme>;
}>;

export const IframeFlag = 'embedded';
export const GNBHeight = '72px';

const DefaultLayout = ({
  children,
  layoutType = LayoutType.DEFAULT,
  containerSx,
}: DefaultLayoutProps) => {
  const { isMobile, maxDesktopWidth } = useLayoutSize(layoutType);
  const { isInitialized } = useAuthContext();

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const router = useRouter();
  const isGNBHidden = Boolean(router.query[IframeFlag]);

  if (!isInitialized) {
    return isGNBHidden ? null : <LoadingLottie opacity={1} loop={true} />;
  }

  const homeReload = () => (location.href = PageURL.HOME);

  return (
    <Container
      sx={{
        minWidth: '360px',
        boxSizing: 'border-box',
        paddingTop: isGNBHidden ? 0 : GNBHeight,
        paddingX: '0 !important', // Container 기본 padding 있어 !important 처리
        maxWidth: `${maxDesktopWidth}px !important`,
        '--page-max-width': `${maxDesktopWidth}px`,
        ...containerSx,
      }}
    >
      {!isGNBHidden && (
        <Box
          sx={{
            paddingX: isMobile ? '10px' : '30px',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: HeaderZIndex,
            backgroundColor: 'white',
            boxSizing: 'border-box',
            padding: '0 30px',
            borderBottom: '1px solid #D4D7DB',
            overflowX: 'auto',
          }}
        >
          <HeaderWrapper>
            <GnbTab />
            <Logo
              id={'header-logo'}
              onClick={homeReload}
              component={Box}
              style={{ cursor: 'pointer' }}
            >
              <LogoImage />
            </Logo>
            <Stack direction={'row'} alignItems={'center'}>
              <Profile />
              <IconButton
                id={'header-menu-button'}
                onClick={() => setIsOpenMenu(true)}
              >
                <MenuIcon />
              </IconButton>
            </Stack>
          </HeaderWrapper>
        </Box>
      )}
      {children}
      <NotiTopics />
      <Footer />
      <GlobalMenuDrawer
        isOpen={isOpenMenu}
        onClose={() => setIsOpenMenu(false)}
      />
    </Container>
  );
};

export default DefaultLayout;
