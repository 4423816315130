import React, { FormEvent, useEffect } from 'react';
import { SubContentProps } from '../../LanguageSetting';
import { CertificateOfEmploymentProps } from '../index';
import useCertificateRequestForm, {
  CertificateRequestForm,
} from './useCertificateRequestForm';
import useToast from '@/hooks/useToast';
import {
  useCertifySmsCode,
  useGetCertifyCode,
  useHistoryEmpProof,
  usePrintEmpProof,
} from '@/hooks/apiHooks';
import { getPhoneNumberFormat } from '@/utils/format';
import useApiRequest from '@/hooks/useApiRequest';

const useCertificateOfEmploymentProps = (
  settings: SubContentProps,
): CertificateOfEmploymentProps => {
  const {
    myInfo: { phoneMobile, passportId, employeeNumber },
  } = settings;

  const { showSuccess, showError } = useToast();

  const methods = useCertificateRequestForm();
  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    getFieldState,
    formState: { isValid, isDirty },
  } = methods;

  const { isLoading, request } = useApiRequest();

  const {
    isLoading: isRequestHistoryLoading,
    data = [],
    mutate: updateList,
  } = useHistoryEmpProof({
    employeeNumber,
    listSize: 500,
    currentPage: 1,
  });

  const isCodeRequested = getValues('verificationId') !== '';
  const isVerified = getValues('verified') === true;
  const isFormValid = isVerified && isDirty && isValid;

  const codeOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      'verificationCode',
      e.target.value.trim().replace(/\D/g, '').slice(0, 6),
      { shouldValidate: true },
    );
  };

  const requestButtonOnClick = async () => {
    await request(
      async () => {
        const {
          data: { smsVerificationId },
        } = await useGetCertifyCode(phoneMobile);
        setValue('verificationId', smsVerificationId);
      },
      (errorData) => {
        showError({
          message: errorData.message || '인증 요청에 실패했습니다.',
        });
      },
    );
  };

  const verifyButtonOnClick = async () => {
    await request(
      async () => {
        await useCertifySmsCode({
          smsVerificationId: getValues('verificationId'),
          smsVerificationCode: getValues('verificationCode'),
        });

        setValue('verified', true);
      },
      (errorData) => {
        let errorMessage = errorData.message || '인증에 실패했습니다.';
        if (errorData.code === '0422') {
          errorMessage = '인증번호가 일치하지 않습니다.';
        }

        showError({
          message: errorMessage,
        });
      },
    );
  };

  const handleFormOnSubmit = (e: FormEvent) => {
    e.preventDefault();

    handleSubmit(async (data: CertificateRequestForm) => {
      await request(
        async () => {
          const res = await usePrintEmpProof({
            passportId,
            outputReason: data.purpose,
            smsVerificationId: data.verificationId,
            smsVerificationCode: data.verificationCode,
          });

          if (window.isRNWebview) {
            const sendData = JSON.stringify({
              actionType: 'OPEN_APP',
              url: res,
            });
            window?.ReactNativeWebView?.postMessage(sendData);
          } else {
            window.open(res, '_blank', 'noopener, noreferrer');
          }

          await updateList();
          reset();
          showSuccess({ message: '발급 신청이 완료되었습니다.' });
        },
        (errorData) => {
          showError({
            message: errorData.message || '발급 신청에 실패했습니다.',
          });
        },
      );
    })();
  };

  useEffect(() => {
    if (phoneMobile) {
      setValue('phoneNumber', getPhoneNumberFormat(phoneMobile));
    }
  }, [phoneMobile]);

  return {
    formProps: {
      methods,
      onSubmit: handleFormOnSubmit,
      id: 'mypage-certificate-of-employment',
    },
    requestCodeButtonTitle: isCodeRequested ? '재전송' : '인증번호 전송',
    requestButtonOnClick,
    isCodeRequested,
    isVerified,
    isRequestButtonDisabled: getFieldState('phoneNumber').invalid,
    isLoading,
    codeOnChange,
    codeHelperText: isVerified ? '인증되었습니다.' : '',
    isVerifyButtonDisabled:
      getFieldState('phoneNumber').invalid ||
      getFieldState('verificationCode').invalid ||
      isVerified,
    isSubmitButtonDisabled: !isFormValid,
    verifyButtonOnClick,
    isRequestHistoryLoading,
    requestHistory: data.map((item) => ({
      id: item.cartNumber,
      requestDate: item.createDate.replaceAll('.', '-'),
      purpose: item.ouputReason ?? '-',
    })),
  };
};

export default useCertificateOfEmploymentProps;

export type EmpCertificateRequestData = {
  readonly id: number;
  readonly requestDate: string;
  readonly purpose: string;
};
