import { IconButton, Popover } from '@mui/material';
import {
  ContentWrapper,
  IconLayout,
  Layout,
  NotiCountBadge,
  StyledButton,
  StyledHeader,
  StyledNodata,
  StyledTitle,
} from '@/components/Alarm/styles';
import {
  useMessageAllRead,
  useNotiHistory,
  useNotiHistoryPagination,
} from '@/hooks/apiHooks';
import NotificationHistoryItem from '@/components/Alarm/NotificationHistoryItem';

import React, { useEffect, useRef, useState } from 'react';
import { faBell } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import { setJwtToken } from '@/utils/tokens';
import { getIntranetDocUrl } from '@/utils/urls';
import useModal from '@/hooks/useModal';
import useApprovalData from '@/components/@hooks/useApprovalData';
import { ModalRef } from 'components/@IntranetLibrary/Modal';
import IframeApprovalModal from 'components/Approval/IframeApprovalModal';

export const AlarmSuspense = () => {
  return (
    <IconButton type={'button'}>
      <FontAwesomeIcon
        icon={faBell}
        style={{
          fontSize: '1.5rem',
          color: 'white',
        }}
        shake={false}
      />
    </IconButton>
  );
};

const listSize = 50;

const Alarm = () => {
  const router = useRouter();
  const { data } = useNotiHistory(listSize);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const {
    data: paginationData,
    size,
    setSize,
    mutate,
  } = useNotiHistoryPagination(listSize);

  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const hasUnreadMessage = unreadMessageCount > 0;

  const noMoreData = (data?.meta.endPage || 0) <= size;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAllRead = () => {
    useMessageAllRead().then(() => mutate());
    setUnreadMessageCount(0);
  };

  const handleRead = async (id: number) => {
    if (!paginationData) {
      return;
    }

    let updatedUnreadCount = false;

    const afterData = paginationData.map((res) =>
      res.map((item) => {
        if (item.id === id) {
          updatedUnreadCount = item.watched === 0;

          return { ...item, watched: 1 as 0 | 1 };
        } else {
          return item;
        }
      }),
    );

    await mutate(afterData);
    setUnreadMessageCount((pre) => (updatedUnreadCount ? pre - 1 : pre));
  };

  useEffect(() => {
    setUnreadMessageCount(data?.meta?.unreadHistoryCount ?? 0);
  }, [data]);
  const { dataItemChange } = useApprovalData();

  const [docId, setDocId] = useState('');
  const approvalModalRef = useRef<ModalRef>(null);
  const { openModal: openApprovalModal, closeModal: closeApprovalModal } =
    useModal(approvalModalRef);

  const onOpen = (docId: string) => {
    setDocId(docId);
    openApprovalModal();
    dataItemChange?.(docId, { read: 1 });
  };

  return router.isReady ? (
    <>
      <IconLayout newMessage={hasUnreadMessage} onClick={handleClick}>
        {hasUnreadMessage && (
          <NotiCountBadge>
            {unreadMessageCount > 99 ? `99+` : unreadMessageCount}
          </NotiCountBadge>
        )}
        <FontAwesomeIcon icon={faBell} shake={hasUnreadMessage} />
      </IconLayout>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Layout>
          <StyledHeader>
            <StyledTitle>NOTIFICATIONS</StyledTitle>
            <StyledButton onClick={handleAllRead}>
              Mark all as read
            </StyledButton>
          </StyledHeader>
          <ContentWrapper>
            {paginationData && paginationData.flat().length > 0 ? (
              paginationData
                .flat()
                .map((res, idx) => (
                  <NotificationHistoryItem
                    handleReadClick={handleRead}
                    key={`notification-${idx}`}
                    {...res}
                    handleOpen={onOpen}
                  />
                ))
            ) : (
              <StyledNodata>알람이 없습니다.</StyledNodata>
            )}
            {!noMoreData && (
              <button
                type={'button'}
                onClick={() => setSize((_size) => _size + 1)}
                style={{ padding: '1rem 0' }}
              >
                Load More
              </button>
            )}
          </ContentWrapper>
        </Layout>
      </Popover>
      <IframeApprovalModal
        ref={approvalModalRef}
        url={setJwtToken(getIntranetDocUrl(docId, true))}
        dataItemChange={dataItemChange}
        handleClose={closeApprovalModal}
      />
    </>
  ) : (
    <AlarmSuspense />
  );
};

export default Alarm;
