import * as React from 'react';
import { Box, Divider, Stack, StackProps } from '@mui/material';
import { Passport } from '@/types/api';
import useToast from '@/hooks/useToast';
import { copyClipBoardText } from '@/utils';
import { imgUrlChk } from '@/utils/function';
import ImageWithErrorHandling from '@/components/ImageWithErrorHandling';
import ProfileContent from './ProfileContent';
import useLayoutSize from '@/hooks/useLayoutSize';
import ProfileIDCard from './ProfileIDCard';
import OpenTeamsLinkButton from '@/components/@IntranetLibrary/Button/OpenTeamsLinkButton';

export enum ProfileCardViewType {
  SIMPLE = 'ProfileCardViewType-SIMPLE',
  BADGE = 'ProfileCardViewType-BADGE',
}

export type ProfileCardContentProps = {
  isLoading: boolean;
  data?: Passport;
  view?: ProfileCardViewType;
} & StackProps;

const ProfileCardContent = (_props: ProfileCardContentProps) => {
  const {
    isLoading,
    data,
    view = ProfileCardViewType.SIMPLE,
    sx,
    ...props
  } = _props;
  const { isMobile } = useLayoutSize();
  const toast = useToast();

  const copyEmail = async () => {
    if (!data?.detail.email) return;
    const resultCopy = await copyClipBoardText(data.detail.email);
    if (resultCopy) {
      toast.show({
        severity: 'success',
        message: '이메일이 복사되었습니다.',
        duration: 3000,
      });
    }
  };

  if (!data) {
    return (
      <Box
        sx={{
          width:
            view === ProfileCardViewType.SIMPLE
              ? '320px'
              : isMobile
              ? '360px'
              : '688px',
          height: view === ProfileCardViewType.SIMPLE ? '394px' : '560px',
          padding: isMobile ? '20px' : '32px 24px',
          fontSize: isMobile ? '12px' : '14px',
        }}
      >
        {isLoading ? (
          '불러오는 중...'
        ) : (
          <>
            프로필 카드가 없습니다.
            <br />
            휴직 혹은 퇴사자일 수 있습니다.
          </>
        )}
      </Box>
    );
  }

  if (view === ProfileCardViewType.SIMPLE) {
    return (
      <Stack
        direction={'column'}
        spacing={'16px'}
        sx={{ padding: '24px 16px 16px', ...sx }}
        {...props}
      >
        <Stack direction={'column'} spacing={'8px'}>
          <ImageWithErrorHandling
            src={imgUrlChk(data.detail.profileImageUrl)}
            width={100}
            height={100}
            alt={'profile-image'}
            style={{
              margin: '0 auto',
              borderRadius: '4px',
              objectFit: 'cover',
            }}
          />
          <Box
            sx={{
              '> p': { textAlign: 'center', margin: 0 },
              '.name': { fontSize: '14px' },
              '.positionName': {
                marginTop: '4px',
                fontSize: '12px',
                color: '#888D96',
              },
            }}
          >
            <p className={'name'}>
              {data.detail.name}{' '}
              {data.detail.nickname && `(${data.detail.nickname})`}
            </p>
            <p className={'positionName'}>{data.detail.positionName}</p>
          </Box>
          <Box
            sx={{
              padding: '8px 0',
              borderTop: '1px solid #E7E9EC',
              borderBottom: '1px solid #E7E9EC',
              textAlign: 'center',
              fontSize: '12px',
              color: '#888D96',
              wordBreak: 'keep-all',
            }}
          >
            {data.detail.companyName} · {data.detail.divisionName} ·{' '}
            {data.detail.departmentName}
          </Box>
        </Stack>
        <Stack
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={'8px'}
          sx={{
            paddingBottom: '16px',
            a: {
              fontSize: '12px',
              textDecoration: 'underline',
              cursor: 'pointer',
            },
          }}
        >
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            divider={
              <Divider
                orientation={'vertical'}
                sx={{
                  height: '8px',
                  marginX: '8px',
                  borderColor: '#D4D7DB',
                }}
              />
            }
          >
            {data.detail.phoneInside && (
              <a href={`tel:${data.detail.phoneInside}`}>
                {data.detail.phoneInside}
              </a>
            )}
            <a href={`tel:${data.detail.phoneMobile}`}>
              {data.detail.phoneMobile}
            </a>
          </Stack>
          {data.detail.email && (
            <a onClick={copyEmail} style={{ wordBreak: 'break-word' }}>
              {data.detail.email}
            </a>
          )}
        </Stack>
        <OpenTeamsLinkButton email={data.detail.email} />
      </Stack>
    );
  }

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={isMobile ? '56px' : '32px'}
      sx={{ minHeight: '568px', '> div': { alignSelf: 'self-end' }, ...sx }}
      {...props}
    >
      <ProfileIDCard
        passport={data}
        sx={{ marginTop: isMobile ? '72px' : 0 }}
      />
      <ProfileContent
        passport={data}
        sx={{
          width: isMobile ? '100%' : '288px',
          paddingTop: isMobile ? 0 : '32px',
        }}
      />
    </Stack>
  );
};

export default ProfileCardContent;
