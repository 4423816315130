import React from 'react';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { SubContentProps } from '../LanguageSetting';
import useCertificateOfEmploymentProps, {
  EmpCertificateRequestData,
} from './@hooks/useCertificateOfEmploymentProps';
import FormProvider, {
  FormProviderProps,
} from '@/components/HookForm/FormProvider';
import Label from '@/components/@IntranetLibrary/Label';
import BoxButton from '@/components/@IntranetLibrary/Button/BoxButton';
import { FormGroup } from '@/components/Board/BoardForm/style';
import { RHFTextFieldProps } from '@/components/HookForm/RHFTextField';
import ContentDescription, {
  ContentDivider,
} from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';
import { Body1, Body2 } from '@/components/@IntranetLibrary/Typography';
import EmptyContent from '@/components/EmptyContent';
import {
  History,
  HistoryItem,
  TextFieldButton,
  TextFieldWithButton,
} from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/CertificateOfEmployment/style';
import RHFTextArea from '@/components/HookForm/RHFTextarea';

export type CertificateOfEmploymentProps = {
  readonly formProps: FormProviderProps;
  readonly isLoading: boolean;
  readonly isCodeRequested: boolean;
  readonly isVerified: boolean;

  readonly requestCodeButtonTitle: string;
  readonly requestButtonOnClick: () => void;
  readonly isRequestButtonDisabled: boolean;
  readonly codeHelperText?: string;
  readonly codeOnChange: RHFTextFieldProps['onChange'];
  readonly verifyButtonOnClick: () => void;
  readonly isVerifyButtonDisabled: boolean;
  readonly isSubmitButtonDisabled: boolean;
  readonly isRequestHistoryLoading: boolean;
  readonly requestHistory: EmpCertificateRequestData[];
};

const CertificateOfEmployment = (props: SubContentProps) => {
  const {
    formProps,
    requestCodeButtonTitle,
    requestButtonOnClick,
    isRequestButtonDisabled,
    isLoading,
    isCodeRequested,
    isVerified,
    codeOnChange,
    codeHelperText,
    verifyButtonOnClick,
    isVerifyButtonDisabled,
    isSubmitButtonDisabled,
    isRequestHistoryLoading,
    requestHistory,
  } = useCertificateOfEmploymentProps(props);

  return (
    <ContentDivider>
      <ContentDescription hasVerticalMargin={true}>
        문자 인증을 통해 직접 재직증명서를 발급할 수 있습니다.
      </ContentDescription>
      <FormProvider {...formProps}>
        <Stack sx={{ padding: '24px 0 32px', gap: '24px' }}>
          <FormGroup gapsize={'s'}>
            <Label isRequired={true} title={'휴대폰 번호'} size={14} />
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                width: '100%',
              }}
            >
              <TextFieldWithButton
                name={'phoneNumber'}
                variant={'outlined'}
                disabled={true}
                helperText={'휴대폰 번호가 변경된 경우 HR팀에게 문의해 주세요.'}
              />
              <TextFieldButton
                onClick={requestButtonOnClick}
                disabled={isRequestButtonDisabled}
                loading={isLoading}
              >
                {requestCodeButtonTitle}
              </TextFieldButton>
            </Box>
          </FormGroup>
          {isCodeRequested && (
            <FormGroup gapsize={'s'}>
              <Label isRequired={true} title={'인증번호'} size={14} />
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  width: '100%',
                }}
              >
                <TextFieldWithButton
                  name={'verificationCode'}
                  helperText={
                    codeHelperText && (
                      <Typography sx={{ color: '#43B16F', fontSize: '12px' }}>
                        {codeHelperText}
                      </Typography>
                    )
                  }
                  disabled={isLoading || isVerified}
                  variant={'outlined'}
                  placeholder={'인증번호 6자리'}
                  onChange={codeOnChange}
                />
                <TextFieldButton
                  onClick={verifyButtonOnClick}
                  disabled={isVerifyButtonDisabled}
                  loading={isLoading}
                >
                  인증
                </TextFieldButton>
              </Box>
            </FormGroup>
          )}
          {isVerified && (
            <>
              <FormGroup gapsize={'s'}>
                <Label isRequired={true} title={'사용 용도'} size={14} />
                <RHFTextArea
                  name={'purpose'}
                  placeholder={'50자 이내로 작성해주세요'}
                  minRows={1}
                  maxLength={50}
                  sx={{
                    resize: 'none',
                  }}
                />
              </FormGroup>
              <BoxButton
                variant={'primary'}
                size={'large'}
                type={'submit'}
                disabled={isSubmitButtonDisabled}
                loading={isLoading}
                sx={{ width: '100%' }}
              >
                발급
              </BoxButton>
            </>
          )}
        </Stack>
      </FormProvider>
      <Stack sx={{ marginTop: '24px' }}>
        <Body1>증명서 발급이력</Body1>
        {isRequestHistoryLoading ? (
          <Stack gap={'12px'} padding={'24px 0'}>
            {[1, 2, 3, 4, 5].map((item) => (
              <Skeleton key={item} variant={'rectangular'} height={'60px'} />
            ))}
          </Stack>
        ) : requestHistory.length > 0 ? (
          <HistoryList requestHistory={requestHistory} />
        ) : (
          <Box sx={{ marginTop: '28px' }}>
            <EmptyContent title={'발급 이력이 없습니다.'} type={'empty'} />
          </Box>
        )}
      </Stack>
    </ContentDivider>
  );
};

export default CertificateOfEmployment;

const HistoryList = ({
  requestHistory: list,
}: Pick<CertificateOfEmploymentProps, 'requestHistory'>) => {
  return (
    <Stack gap={'16px'} marginTop={'16px'}>
      {list.map(({ id, requestDate, purpose = '-' }) => (
        <History key={id} sx={{ gap: '8px' }}>
          {[
            { title: '발급일', content: requestDate },
            { title: '발급 번호', content: id },
            { title: '사용 용도', content: purpose },
          ].map(({ title, content }, index) => (
            <HistoryItem key={`${id}${index}`}>
              <Body2>{title}</Body2>
              <Body2 sx={{ color: '#000000' }}>{content}</Body2>
            </HistoryItem>
          ))}
        </History>
      ))}
    </Stack>
  );
};
