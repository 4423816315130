import { Box, Typography } from '@mui/material';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Mention,
  MentionProps,
  MentionsInputProps,
  SuggestionDataItem,
} from 'react-mentions';
import {
  CommentInputContainer,
  Footer,
  mentionInputClassName,
  MentionItem,
  MentionsInput,
  SubmitButton,
} from '@/components/Comment/CommentInput/styles';
import { useCreateComment, usePassportSearch } from '@/hooks/apiHooks';
import Spinner from '@/components/@IntranetLibrary/Spinner';
import { useDialog } from '@/hooks/useDialog';
import classNames from 'classnames';

type CommentInputProps = {
  readonly id?: string;
  readonly commentGroupId: string;
  readonly updateCommentList?: () => Promise<void>;
  readonly disabled?: boolean;
};

export type CommentInputRef = {
  focus: () => void;
};

type SuggestedItem = SuggestionDataItem & {
  readonly name: string;
  readonly profileUrl: string;
  readonly company: string;
  readonly department: string;
  readonly position: string;
};

const CommentInput = forwardRef<CommentInputRef, CommentInputProps>(
  (
    { commentGroupId, updateCommentList, id = 'comment-input-box', disabled },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const dialog = useDialog();

    const [data, setData] = useState<SuggestedItem[]>([]);
    const [comments, setComments] = useState('');
    const [searchKeywords, setSearchKeywords] = useState('');
    const [hasFocus, setHasFocus] = useState(false);
    const [isCommentSubmitting, setIsCommentSubmitting] = useState(false);

    const { data: searchResult, isLoading: searchResultIsLoading } =
      usePassportSearch({
        keyword: searchKeywords.slice(0, 1),
        listSize: 100000,
      });

    const isButtonDisabled = comments === '';

    const showHelperText =
      searchResultIsLoading ||
      (searchKeywords !== '' &&
        data.every((item) => !item.name.startsWith(searchKeywords)));

    const onAdd: MentionProps['onAdd'] = (id, display) => {
      setData([]);
      setSearchKeywords('');
    };

    const renderSuggestion: MentionProps['renderSuggestion'] = (
      entry,
      search,
      highlightedDisplay,
      index,
      focused,
    ) => {
      const item = entry as SuggestedItem;
      const { name, id, profileUrl, position, company, department } = item;
      return search && entry.display?.startsWith(search) ? (
        <MentionItem data-id={id}>
          <img src={profileUrl} alt={`${name}의 프로필 사진`} />
          <span className={'mention-name'}>
            {name} {position}
          </span>
          <span className={'mention-department'}>
            {company} {department}
          </span>
        </MentionItem>
      ) : null;
    };

    const handleOnChange: MentionsInputProps['onChange'] = (e) => {
      const value = e.target.value;
      setComments(value);

      if (inputRef.current) {
        const replaceCommentMentions = (input: string): string => {
          return input.replace(
            /<CommentMention data-id='\w+'>@([^<]+)<\/CommentMention>/g,
            '@$1',
          );
        };

        const cursorPosition = inputRef.current.selectionStart || 0;
        const textBeforeCursor = replaceCommentMentions(value).slice(
          0,
          cursorPosition,
        );
        const mentionMatch = textBeforeCursor.match(/@([^\s@]*)$/g);
        setSearchKeywords(mentionMatch?.[0]?.slice(1) ?? '');
      }
    };

    const reset = () => {
      setComments('');
      setSearchKeywords('');
      setData([]);
    };

    const addFile = () => {
      // TODO 파일 첨부(추갇된 기획인가? 기획 확인 필요)
    };

    const addComment = async () => {
      try {
        const extractPassportIds = (content: string): string[] => {
          const regex =
            /<CommentMention data-id='(\w+)'>@[^<]+<\/CommentMention>/g;
          const dataIds: string[] = [];
          let match;

          while ((match = regex.exec(content)) !== null) {
            const dataId = match[1];
            dataIds.push(dataId);
          }

          return dataIds;
        };

        const mentions = extractPassportIds(comments);
        setIsCommentSubmitting(true);

        await useCreateComment({
          commentGroupId,
          content: comments.trim(),
          mentions: mentions.length > 0 ? mentions : undefined,
          service: 'board',
        });

        await updateCommentList?.();
        reset();
      } catch (e) {
        dialog.show({
          message: '댓글 등록에 실패했습니다.',
        });
      } finally {
        setIsCommentSubmitting(false);
      }
    };

    useEffect(() => {
      if (searchKeywords.length === 0) {
        setData([]);
      }
    }, [searchKeywords]);

    useEffect(() => {
      if (searchResult) {
        const { data } = searchResult;
        setData(
          data
            .map(
              ({
                passportId,
                detail: {
                  name,
                  positionName,
                  profileImageUrl,
                  departmentName,
                  companyName,
                },
              }) => {
                return {
                  id: passportId,
                  display: name,
                  name,
                  profileUrl: profileImageUrl,
                  company: companyName,
                  department: departmentName,
                  position: positionName,
                };
              },
            )
            .sort((a, b) => a.display.charCodeAt(0) - b.display.charCodeAt(0)),
        );
      }
    }, [searchResult, searchKeywords[0]]);

    useImperativeHandle(ref, () => ({
      focus: () => {
        console.log('[JM] TODO 인풋에 포커스');
      },
    }));

    return (
      <CommentInputContainer
        id={id}
        className={classNames({
          focus: hasFocus,
        })}
      >
        <MentionsInput
          placeholder={'댓글을 입력해주세요.'}
          name={'comment'}
          onChange={handleOnChange}
          value={comments}
          inputRef={inputRef}
          className={classNames(`${mentionInputClassName}`, {
            focus: hasFocus,
          })}
          onFocus={(e) => {
            setHasFocus(true);
          }}
          onBlur={(e) => {
            setHasFocus(false);
          }}
          disabled={disabled}
          customSuggestionsContainer={(children) => {
            if (searchResultIsLoading || data.length === 0) {
              return (
                <Box display={'flex'} justifyContent={'center'} width={'100%'}>
                  <Spinner theme={'black'} />
                </Box>
              );
            }

            if (
              searchKeywords &&
              data.some((item) => item.name.startsWith(searchKeywords))
            ) {
              return children;
            } else {
              return (
                <Typography component={'p'} color={'#888D96'} fontSize={'12px'}>
                  일치하는 사람이 없어요.
                </Typography>
              );
            }
          }}
          rows={1}
        >
          <Mention
            data={(search, callback) =>
              callback(
                data.filter((item) => item.name.startsWith(search)).slice(0, 5),
              )
            }
            isLoading={showHelperText}
            renderSuggestion={renderSuggestion}
            onAdd={onAdd}
            appendSpaceOnAdd={true}
            trigger={'@'}
            markup="<CommentMention data-id='__id__'>@__display__</CommentMention>"
            displayTransform={(id, display) => `@${display}`}
            style={{
              borderRadius: '11px',
              backgroundColor: 'rgba(9, 30, 66, 0.07)',
            }}
          />
        </MentionsInput>
        <Footer>
          <SubmitButton
            disabled={isButtonDisabled}
            loading={isCommentSubmitting}
            loadingIndicator={<Spinner theme={'black'} />}
            onClick={addComment}
          >
            {isCommentSubmitting ? '' : '등록'}
          </SubmitButton>
        </Footer>
      </CommentInputContainer>
    );
  },
);

CommentInput.displayName = 'CommentInput';
export default CommentInput;
