import React from 'react';
import {
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  SxProps,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { DateSelectorNormalizedStyle } from '@/components/@IntranetLibrary/DatePicker/styles';

type SelectProps = Omit<MuiSelectProps, 'variant' | 'label'> & {
  sx?: SxProps<Theme>;
  options: { label: string; value: string }[];
  disablePortal?: boolean;
};

const Select = ({
  sx,
  slots,
  options,
  placeholder,
  disablePortal,
  ...SelectProps
}: SelectProps) => {
  return (
    <MuiSelect
      {...SelectProps}
      sx={{
        ...DateSelectorNormalizedStyle,
        ...sx,
        background: '#ffffff',
        height: '44px',
        boxSizing: 'border-box',
      }}
      displayEmpty
      variant={'outlined'}
      MenuProps={{
        disablePortal,
        PaperProps: {
          sx: {
            backgroundColor: '#ffffff',
            boxShadow: '0 6px 12px 0px rgba(0, 0, 0, 0.08)',
            border: '1px solid #000',
            '& .MuiList-root': {
              padding: '16px',
              maxHeight: 200,
              overflowY: 'auto',
            },
            '& .MuiMenuItem-root': {
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
      }}
      renderValue={
        SelectProps?.value !== ''
          ? undefined
          : () => <span style={{ color: '#B7BBC2' }}>{placeholder}</span>
      }
    >
      {options.map(({ label, value }) => (
        <MenuItem
          key={value}
          value={value}
          sx={{
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px',
            color: '#B7BBC2',

            '&.Mui-selected': {
              color: '#000',
            },
          }}
        >
          {label}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

export default Select;
