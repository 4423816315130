import { BoardDetailData, BoardViewType } from '@/types/board';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, SxProps, Tab } from '@mui/material';
import { Theme } from '@mui/material/styles';
import BoardDetailLikes, {
  BoardDetailLikesRef,
} from '@/components/Board/BoardDetail/Feedback/BoardDetailLikes';
import { downloadFile, scrollToElement } from '@/utils';
import {
  changeBoardBookmark,
  changeLikeBoard,
  useDeleteBoard,
} from '@/hooks/apiHooks';
import BoardDetailHeader from '@/components/Board/BoardDetail/Content/BoardDetailHeader';
import BoardDetailContent from '@/components/Board/BoardDetail/Content/BoardDetailContent';
import ImageSwiper from '@/components/Swiper/ImageSwiper';
import BoardLikeButton from '@/components/@IntranetLibrary/Button/BoardLikeButton';
import { TabContext, TabPanel } from '@mui/lab';
import {
  BackgroundBlockSx,
  BoardDetailSx,
  BoardReactionTabList,
  FileAttachmentWrapper,
} from '@/components/Board/BoardDetail/styles';
import BoardDetailComment from '@/components/Board/BoardDetail/Feedback/BoardDetailComment';
import AttachmentFile from 'components/AttachmentFile';
import { RemoteFile } from '@/components/file-thumbnail/utils';
import { useDialog } from '@/hooks/useDialog';
import { LayoutType } from '@/constant/layout';
import useLayoutSize from '@/hooks/useLayoutSize';
import Head from 'next/head';
import useDetectScroll from '@smakss/react-scroll-direction';
import { ActionType } from '@/constant/button';
import { BoardDetailHeaderId } from '@/components/Board/BoardDetailModal/BoardDetailModal';
import classNames from 'classnames';
import { boardWebPushCall } from '@/components/NotiTopics';
import { getNowFormat } from '@/utils/format';
import useToast from '@/hooks/useToast';
import { useRecoilState } from 'recoil';
import { socialState } from '@/recoil/atom';
import BoardEdit from '@/components/Board/BoardEdit';
import useCustomModal from '@/hooks/useCustomModal';
import StackWithDivider from '@/components/StackWithDivider';

export enum ReactionTab {
  COMMENT = 'comment',
  LIKE = 'like',
}

export type BoardDetailProps = {
  layoutType?: LayoutType;
  isModal?: boolean;
  scrollRef?: React.RefObject<HTMLDivElement>;
  data: BoardDetailData;
  isRowDirection?: boolean;
  headerId?: string;
  disabled?: boolean;
  onDeleted?: (documentId: string) => void;
  onEdited?: () => void;
  onChange?: (id: string, changes: Record<string, any>) => void;
  mutate?: any;
  sx?: SxProps<Theme>;
};

const BoardDetail = (props: BoardDetailProps) => {
  const {
    layoutType = LayoutType.DEFAULT,
    isModal = true,
    scrollRef,
    data,
    isRowDirection = false,
    headerId = BoardDetailHeaderId,
    disabled,
    onDeleted,
    onEdited,
    onChange,
    mutate,
    sx,
  } = props;

  const { isMobile } = useLayoutSize(layoutType);
  const dialog = useDialog();
  const toast = useToast();
  const {
    isOpen: isBoardEditModalOpen,
    openModal: openBoardEditModal,
    closeModal,
  } = useCustomModal();
  const isLoadingSet = new Set<ActionType>();
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const [state, setState] = useRecoilState(socialState);
  const [commentCount, setCommentCount] = useState(0);
  const [isBookmark, setIsBookmark] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [selectedTab, setSelectedTab] = useState<ReactionTab>(
    ReactionTab.COMMENT,
  );
  const boardDetailRef = useRef<HTMLElement | null>(null);
  const boardReactionWrapRef = useRef<HTMLElement | null>(null);
  const boardDetailLikesRef = useRef<BoardDetailLikesRef | null>(null);

  const [scrollTarget, setScrollTarget] = useState<HTMLDivElement>();
  const { scrollDir, scrollPosition } = useDetectScroll({
    target: scrollTarget,
  });
  const [isScrollDown, setIsScrollDown] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (!data) return;
    setIsBookmark(data.feedback.isBookmark);
    setIsLike(data.feedback.isLike);
  }, [data]);

  useEffect(() => {
    const headerEl = document.getElementById(headerId);
    if (headerEl) setHeaderHeight(headerEl.clientHeight);
  }, [isRowDirection]);

  useEffect(() => {
    if (scrollRef?.current) setScrollTarget(scrollRef.current);
  }, [scrollRef]);

  useEffect(() => {
    setIsScrollDown(scrollPosition.top > 0);
  }, [scrollDir, scrollPosition]);

  const scrollToCommentSection = useCallback(() => {
    setSelectedTab(ReactionTab.COMMENT);
    if (!isRowDirection) {
      const element = boardReactionWrapRef.current;

      if (!element) return;

      if (!isModal) {
        let offset = 24 + 28; // 리액션 섹션 marginTop + 탭 크기
        if (boardDetailRef.current) {
          const style = getComputedStyle(boardDetailRef.current);
          offset += Number(
            style
              .getPropertyValue('--board-detail-offset-top')
              .replace('px', ''),
          );
          offset += Number(
            style
              .getPropertyValue('--board-detail-padding-top')
              .replace('px', ''),
          );
        }
        scrollToElement(element, offset);
      } else {
        const scrollTarget =
          document.getElementsByClassName('modal-content')[0];
        if (scrollTarget) {
          let offset = 28; // 탭 크기
          if (boardDetailRef.current) {
            const style = getComputedStyle(boardDetailRef.current);
            offset += Number(
              style.getPropertyValue('--modal-padding').replace('px', ''),
            );
          }
          scrollTarget.scrollTo({
            top: element.offsetTop - offset,
            behavior: 'smooth',
          });
        }
      }
    }
  }, [isRowDirection, isModal]);

  const onAction = async (action: ActionType, params?: any) => {
    if (isLoadingSet.has(action)) return;

    isLoadingSet.add(action);
    switch (action) {
      case ActionType.BOOKMARK: {
        const bookmark = !isBookmark;
        const result = await changeBoardBookmark(data.id, bookmark);

        if (result) {
          setIsBookmark(bookmark);
          onChange?.(data.id, { isBookmark: bookmark });
          mutate?.(
            { ...data, feedback: { ...data.feedback, isBookmark: bookmark } },
            false,
          );
        }
        break;
      }
      case ActionType.LIKE: {
        const like = !isLike;
        const result = await changeLikeBoard(data.id, like);

        if (result) {
          setIsLike(like);
          boardDetailLikesRef.current?.refresh();
          const likeCount = (data.feedback.likesCount += like ? 1 : -1);
          onChange?.(data.id, { isLike: like, likeCount });
          mutate?.(
            { ...data, feedback: { ...data.feedback, isLike: like } },
            false,
          );
        }
        break;
      }
      case ActionType.DELETE:
        dialog.show({
          message: '게시글을 삭제하시겠습니까?',
          cancelButtonText: '취소',
          confirmButtonText: '삭제',
          onConfirm: async () => {
            const result = await useDeleteBoard(data.id);
            if (result === true) {
              toast.show({
                severity: 'success',
                message: '게시글이 삭제되었습니다.',
              });
              if (onDeleted) onDeleted(data.id);
              if (!data.config.isReserved) {
                // 리프레쉬 푸쉬 발송은 예약 게시글이 아니어야 한다.
                boardWebPushCall();
              }

              setState({
                ...state,
                currentDate: getNowFormat('yyyy-MM-dd HH:mm:ss'),
              });
            } else {
              toast.showError({
                message: result || '삭제 실패. 새로고침 후 다시 시도해 주세요.',
              });
            }
          },
        });
        break;
      case ActionType.EDIT:
        openBoardEditModal();
        break;
    }

    isLoadingSet.delete(action);
  };

  const handleComment = (isComment: boolean, commentCount: number) => {
    onChange?.(data.id, { isComment: isComment, commentCount: commentCount });
  };

  const onDownloadAttachmentFile = async (file: RemoteFile) => {
    setIsLoadingDownload(true);
    const isCompleteDownload = await downloadFile(file);
    if (isCompleteDownload !== true) {
      dialog.show({ message: isCompleteDownload });
    }
    setIsLoadingDownload(false);
  };

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <Box
        ref={boardDetailRef}
        component={isModal ? 'div' : 'main'}
        className={classNames({ isRowDirection, isModal, isMobile })}
        sx={{
          ...BoardDetailSx,
          '#board-content': { paddingTop: `${headerHeight}px` },
          ...sx,
        }}
      >
        {/* iOS 에서 스크롤 시 Header 위, 옆 틈으로 게시글 내용이 보여서 가리기 위함 */}
        {/* 운영 배포를 위한 처리. 추후 게시글 코드 리팩토링 시 깨끗한 코드로 변경 필요 */}
        {isModal && isMobile && <Box sx={BackgroundBlockSx} />}

        <BoardDetailHeader
          isMobile={isMobile}
          isModal={isModal}
          id={headerId}
          commentCount={commentCount}
          isCompact={isScrollDown}
          disabled={disabled}
          onClickComment={scrollToCommentSection}
          onAction={onAction}
          data={data}
          isBookmark={isBookmark}
        />

        <StackWithDivider
          className={'board-content-wrap'}
          direction={'column'}
          alignItems={'stretch'}
          dividerProps={{
            orientation: isRowDirection ? 'vertical' : 'horizontal',
            flexItem: true,
          }}
        >
          <Box component={'section'} id={'board-content'}>
            {data.content && <BoardDetailContent content={data.content} />}
            {data.config.viewType === BoardViewType.SLIDE && (
              <ImageSwiper
                imageList={data.files.slideImages.map((item) => item.link)}
                sx={{ margin: '56px auto 44px' }}
              />
            )}
            {data.files.attachments.length > 0 && (
              <FileAttachmentWrapper>
                {data.files.attachments.map((file, index) => (
                  <AttachmentFile
                    key={index}
                    file={file}
                    loading={isLoadingDownload}
                    disabled={disabled}
                    buttonOnClick={onDownloadAttachmentFile}
                    buttonIcon={<DownloadIcon />}
                    useLink={true}
                  />
                ))}
              </FileAttachmentWrapper>
            )}
            <BoardLikeButton
              isLike={isLike}
              disabled={disabled}
              onClick={() => onAction(ActionType.LIKE)}
              sx={{ display: 'flex', margin: '60px auto' }}
            />
          </Box>
          <Box
            ref={boardReactionWrapRef}
            component={'section'}
            className={'board-reaction-wrap'}
          >
            <Box id={'board-reaction'}>
              <TabContext value={selectedTab}>
                <BoardReactionTabList
                  id={'board-reaction-tab-list'}
                  onChange={(event, newValue) => setSelectedTab(newValue)}
                >
                  <Tab
                    label={`댓글 ${commentCount || ''}`}
                    value={ReactionTab.COMMENT}
                    disabled={disabled}
                  />
                  <Tab
                    label={`좋아요 ${data.feedback.likesCount || ''}`}
                    value={ReactionTab.LIKE}
                    disabled={disabled}
                  />
                </BoardReactionTabList>
                <TabPanel value={ReactionTab.COMMENT} sx={{ padding: 0 }}>
                  <BoardDetailComment
                    commentGroupId={data.commentGroupId}
                    isFixedInput={isRowDirection}
                    onChangeCount={setCommentCount}
                    handleComment={handleComment}
                    disabled={disabled}
                  />
                </TabPanel>
                <TabPanel value={ReactionTab.LIKE} sx={{ padding: 0 }}>
                  <BoardDetailLikes
                    ref={boardDetailLikesRef}
                    documentId={data.id}
                    disabled={disabled}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </Box>
        </StackWithDivider>

        <BoardEdit
          isModalOpen={isBoardEditModalOpen}
          closeModal={closeModal}
          documentId={data.id}
          onWriteComplete={onEdited}
        />
      </Box>
    </>
  );
};

export default BoardDetail;

const DownloadIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3006_18821)">
      <path
        d="M4 16.8994H16"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.001 12.8993V4.89941"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.001 12.8992L14 9.69922"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99902 12.8994L6 9.69946"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3006_18821">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.899414)"
        />
      </clipPath>
    </defs>
  </svg>
);
