import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import { fetchLeaveHistory, useGetVacationStatus } from '@/hooks/apiHooks';
import process from 'process';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { VacationStatus } from '@/types/api';
import { getApprovalDocUrl, getIntranetDocUrl } from '@/utils/urls';
import { AxiosError } from 'axios';

type TableData = {
  hasLink: boolean;
  date: string;
  title: string;
  detail: string;
  isRed: boolean;
  onClick?: () => void;
};

const currYear = new Date().getFullYear();
const useVacationProps = () => {
  const { passportId, joinedAt } = useRecoilValue(MyInfoState);

  const joinedYear = useMemo(() => Number(joinedAt.split('-')[0]), [joinedAt]);

  const yearOptions = useMemo(() => {
    function generateYearArray(startYear: number): number[] {
      const currentYear = new Date().getFullYear();
      if (startYear > currentYear) {
        throw new Error('시작 연도는 현재 연도보다 클 수 없습니다.');
      }

      const years: number[] = [];
      for (let year = startYear; year <= currentYear; year++) {
        years.unshift(year);
      }

      return years;
    }

    try {
      return generateYearArray(joinedYear).map((item) => ({
        label: `${item}년`,
        value: `${item}`,
      }));
    } catch (error) {
      return [{ label: `${currYear}년`, value: `${currYear}` }];
    }
  }, [joinedYear]);

  const [prevYear, setPrevYear] = useState(currYear);
  const [prevYearTableData, setPrevYearTableData] = useState<TableData[]>([]);

  const {
    data: statusData,
    isLoading: isStatusDataLoading,
    error: statusError,
  } = useGetVacationStatus(passportId);

  const {
    data: prevYearData,
    isLoading: isPrevYearDataLoading,
    error: prevYearError,
  } = fetchLeaveHistory({
    passportId,
    year: prevYear,
  });

  const sendPostMessage = () => {
    const sendData = JSON.stringify({
      actionType: 'MOVE_TAB',
      tabName: 'approval',
      url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/approval/create?apv_type=32`,
    });
    window?.ReactNativeWebView?.postMessage(sendData);
  };

  const sendDetailPostMessage = (documentId: string) => {
    const sendData = JSON.stringify({
      actionType: 'MOVE_TAB',
      tabName: 'approval',
      url: getIntranetDocUrl(documentId, false),
    });
    window?.ReactNativeWebView?.postMessage(sendData);
  };

  const handleOpen = () => {
    if (window?.isRNWebview) {
      sendPostMessage();
    } else {
      window.open(
        `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/approval/create?apv_type=32`,
        '_blank',
        'noreferrer',
      );
    }
  };

  const handleDetail = (documentId?: string) => {
    if (window?.isRNWebview) {
      documentId && sendDetailPostMessage(documentId);
    } else {
      documentId &&
        window.open(
          getApprovalDocUrl(documentId, false),
          '_blank',
          'noreferrer',
        );
    }
  };

  const status = useMemo(() => {
    let status = isStatusDataLoading ? 'loading' : 'success';

    if (statusError) {
      if (statusError as AxiosError) {
        const e = statusError as AxiosError;
        const data = e.response?.data as any;
        if (data && data.code === '0404') {
          return 'empty';
        }
      }

      status = 'error';
    }

    return status;
  }, []);

  useEffect(() => {
    if (prevYearError || status === 'empty') {
      setPrevYearTableData([]);
      return;
    }

    if (prevYearData) {
      const conversionDate = (str: string) => {
        const date = new Date(str);
        return `${('00' + (date.getMonth() + 1).toString()).slice(-2)}월 ${(
          '00' + date.getDate().toString()
        ).slice(-2)}일`;
      };

      const {
        leaves = [],
        overages,
        monthlyLeaveGranted,
        annualLeaveGranted,
      } = prevYearData;

      const result: TableData[] = leaves.map((item) => ({
        onClick: () => handleDetail(item.documentId),
        hasLink: Boolean(item.documentId),

        date:
          item.startDate === item.endDate
            ? conversionDate(item.startDate)
            : `${conversionDate(item.startDate)} ~ ${conversionDate(
                item.endDate,
              )}`,
        title: t('사용'),
        detail: `-${item.days}${t('일')}`,
        isRed: false,
      }));

      const startDate =
        joinedYear === prevYear ? conversionDate(joinedAt) : '01월 01일';

      if (overages > 0) {
        result.push({
          hasLink: false,
          date: startDate,
          title: t('전년초과사용'),
          detail: `-${overages ?? 0}일`,
          isRed: true,
        });
      }

      const availableDays = monthlyLeaveGranted + annualLeaveGranted;

      if (availableDays > 0) {
        result.push({
          hasLink: false,
          date: startDate,
          title: t('발급'),
          detail: `+${availableDays ?? 0}일`,
          isRed: false,
        });
      }

      setPrevYearTableData(result);
    }
  }, [prevYearData, joinedYear, joinedAt, prevYear, prevYearError, status]);

  return {
    status: {
      status,
      data: getStatusData(statusData),
    },
    applyButtonOnClick: handleOpen,
    prevYear,
    prevYearData: {
      status:
        status === 'empty'
          ? 'success'
          : isPrevYearDataLoading
          ? 'loading'
          : prevYearError
          ? 'error'
          : 'success',
      data: prevYearTableData,
      granted:
        (prevYearData?.annualLeaveGranted ?? 0) > 0 ||
        (prevYearData?.monthlyLeaveGranted ?? 0) > 0
          ? {
              annual: prevYearData?.annualLeaveGranted ?? 0,
              monthly: prevYearData?.monthlyLeaveGranted ?? 0,
            }
          : undefined,
    },
    yearOptions,
    yearOnChange: (e: any) => {
      setPrevYear(Number(e.target.value));
    },
  };
};

export default useVacationProps;

const getStatusData = (data?: VacationStatus) => {
  if (!data) {
    return {
      remainingDays: 0,
      overages: 0,
    };
  }

  const { monthlyLeave, annualLeave, overage = 0 } = data;

  const remainingDays =
    (monthlyLeave.granted ?? 0) +
    (annualLeave.granted ?? 0) -
    ((monthlyLeave.used ?? 0) + (annualLeave.used ?? 0) + overage);

  return {
    monthly: monthlyLeave,
    annual: annualLeave,
    remainingDays,
    overages: overage,
  };
};
