import React, { PropsWithChildren } from 'react';
import { Divider, DividerProps, Stack, StackProps } from '@mui/material';

const StackWithDivider = ({
  children,
  dividerProps,
  ...stackProps
}: PropsWithChildren<
  Omit<StackProps, 'divider'> & {
    dividerProps?: DividerProps;
  }
>) => {
  return (
    <Stack
      divider={<Divider orientation="horizontal" {...dividerProps} />}
      {...stackProps}
    >
      {children}
    </Stack>
  );
};

export default StackWithDivider;
