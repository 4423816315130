import React from 'react';
import { SpinnerBox } from './styles';

type SpinnerProps = {
  readonly theme: 'white' | 'black';
  readonly size?: string;
};

const Spinner = ({ theme, size }: SpinnerProps) => {
  return (
    <SpinnerBox
      background={theme === 'white' ? '#fff' : '#333'}
      width={size || '30px'}
    />
  );
};

export default Spinner;
