import {
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummaryProps,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import React from 'react';
import { styled } from '@mui/system';
import { ExpendIcon } from '@/components/Board/BoardForm';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const Section = styled(Stack)`
  padding: 0 20px;
`;

export const ButtonSection = styled(Section)`
  margin-top: 16px;
`;

export const DetailSection = styled(Section)`
  margin-top: 32px;
`;

export const PolicySection = styled('section')`
  margin-top: 32px;
  border-top: 1px solid #e7e9ec;
`;

export const LeaveHistoryTable = styled('table')`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-collapse: collapse;

  thead {
    border-bottom: 1px solid #e7e9ec;
    background: #f9fafb;
    color: #888d96;

    tr {
      th {
        padding: 8px 4px;
        font-weight: inherit;
        text-align: center;
      }

      &.right {
        th {
          text-align: right;

          &:first-of-type {
            text-align: left;
          }
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #e7e9ec;

      &:last-of-type {
        border-bottom: 1px solid #e7e9ec;
      }

      td {
        padding: 8px 4px;
        color: #000;
        font-weight: inherit;
        text-align: center;

        &:first-of-type {
          color: #888d96;

          &.link {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      &.right {
        td {
          text-align: right;

          &:first-of-type {
            text-align: left;
          }
        }
      }

      &.red {
        td {
          color: #f55060;
        }
      }
    }
  }

  tfoot {
    border-bottom: 1px solid #e7e9ec;

    th {
      padding: 8px 4px;

      color: #000;
      text-align: right;
      font-weight: inherit;

      &:first-of-type {
        text-align: left;
      }
    }
  }
`;

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters {...props} />
))(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  '&.MuiAccordion-root': {
    padding: 0,
    border: 'none',
  },
}));

export const AccordionHeader = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpendIcon />} {...props} />
))(() => ({
  backgroundColor: 'transparent',
  padding: '16px 16px 16px 20px',
  minHeight: 'auto',
  '&.MuiAccordionSummary-root': {
    margin: 0,
    borderBottom: '1px solid #E7E9EC',
    paddingBottom: '12px',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    color: '#000',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',

    '& .primary': {
      color: '#0066CC',
      fontWeight: 700,
      padding: '0 4px',
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    padding: 0,
  },
}));

export const AccordionBody = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails {...props} />
))(() => ({
  '&.MuiAccordionDetails-root': {
    padding: '16px 20px',
  },
}));

export const SummarySubTitle = styled(Typography)`
  font-size: 14px;
  color: #000;
`;

export const StyledDivider = styled(Divider)(({ theme }) => ({
  '&:last-of-type': {
    display: 'none', // 마지막 Divider 숨기기
  },
}));
