import React from 'react';
import { Box, List, Skeleton, Stack } from '@mui/material';
import { SubContentProps } from '../LanguageSetting';
import useShoppingMallVerificationProps from './@hooks/useShoppingMallVerificationProps';
import FormProvider, {
  FormProviderProps,
} from '@/components/HookForm/FormProvider';
import BoxButton from '@/components/@IntranetLibrary/Button/BoxButton';
import ContentDescription, {
  ContentDivider,
} from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';
import { Body1, Caption } from '@/components/@IntranetLibrary/Typography';
import { FormGroup } from '@/components/Board/BoardForm/style';
import Label from '@/components/@IntranetLibrary/Label';
import RHFPasswordField from '@/components/HookForm/RHFPasswordField';
import EmptyContent from '@/components/EmptyContent';
import RHFTextField from '@/components/HookForm/RHFTextField';
import { ListItem } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/ShoppingMallVerification/style';

export type ShoppingMallVerificationProps = {
  readonly formProps: FormProviderProps;
  readonly isLoading: boolean;
  readonly isSubmitButtonDisabled: boolean;
  readonly verificationStatus: 'loading' | 'success' | 'error';
  readonly verificationInfo: { label: string; value: string }[];
};

const ShoppingMallVerification = (props: SubContentProps) => {
  const {
    formProps,
    isSubmitButtonDisabled,
    isLoading,
    verificationStatus,
    verificationInfo,
  } = useShoppingMallVerificationProps(props);

  return (
    <ContentDivider>
      <ContentDescription hasVerticalMargin={true}>
        <Box>
          임직원 할인을 위한 계정 인증화면입니다.
          <br />
          자사 온라인몰에서 가입한 계정을 등록해 주세요.
          <br />
          등록된 계정 변경을 원하는 경우, 재인증 시 갱신됩니다.
        </Box>
        <Box>
          대상 브랜드
          <List
            sx={{
              listStyleType: 'disc',
              listStylePosition: 'inside',
              marginLeft: '5px',
            }}
          >
            {['MLB', 'Discovery', 'BANILA CO'].map((brand, index) => (
              <ListItem key={index}>{brand}</ListItem>
            ))}
          </List>
        </Box>
      </ContentDescription>
      <FormProvider {...formProps}>
        <Stack sx={{ padding: '24px 0 32px', gap: '24px' }}>
          <FormGroup gapsize={'s'}>
            <Label isRequired={true} title={'아이디'} size={14} />
            <RHFTextField
              name={'id'}
              variant={'outlined'}
              placeholder={'아이디를 입력해 주세요'}
              sx={{
                // TODO: styling 공통화
                flex: 1,

                '& .MuiInputBase-root': {
                  height: '48px',
                  fontSize: '14px',
                  lineHeight: '20px',
                },
              }}
              inputProps={{ maxLength: 15 }}
            />
          </FormGroup>
          <FormGroup gapsize={'s'}>
            <Label isRequired={true} title={'비밀번호'} size={14} />
            <RHFPasswordField
              name={'password'}
              placeholder={'비밀번호를 입력해 주세요'}
              disabled={isLoading}
              inputProps={{ maxLength: 12 }}
            />
          </FormGroup>
          <BoxButton
            variant={'primary'}
            size={'large'}
            type={'submit'}
            disabled={isSubmitButtonDisabled}
            loading={isLoading}
            sx={{ width: '100%' }}
          >
            인증
          </BoxButton>
        </Stack>
      </FormProvider>
      <Stack sx={{ padding: '24px 0 80px', gap: '16px' }}>
        <Body1>인증 정보</Body1>
        {(() => {
          switch (verificationStatus) {
            case 'loading':
              return (
                <Stack gap={'12px'}>
                  <Skeleton width={'100%'} height={'30px'} animation={'wave'} />
                  <Skeleton width={'100%'} height={'30px'} animation={'wave'} />
                  <Skeleton width={'100%'} height={'30px'} animation={'wave'} />
                </Stack>
              );
            case 'error':
              return (
                <EmptyContent title={'에러가 발생했습니다'} type={'error'} />
              );
            case 'success':
              return (
                <>
                  {verificationInfo.length > 0 ? (
                    <Stack gap={'8px'}>
                      {verificationInfo.map(({ label, value }) => {
                        return (
                          <Box key={'label'} sx={{ display: 'flex' }}>
                            <Caption
                              sx={{
                                minWidth: '80px',
                              }}
                            >
                              {label}
                            </Caption>
                            <Caption
                              sx={{
                                color: '#000',
                              }}
                            >
                              {value}
                            </Caption>
                          </Box>
                        );
                      })}
                    </Stack>
                  ) : (
                    <EmptyContent
                      title={'인증 정보가 없습니다'}
                      type={'empty'}
                    />
                  )}
                </>
              );
          }
        })()}
      </Stack>
    </ContentDivider>
  );
};

export default ShoppingMallVerification;
