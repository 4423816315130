import Lottie from 'lottie-react';
import loadingLottie from '@/public/lottie/fnf.json';
import { LoadingScreenContainer, LottieContainer } from './styles';
import { useRouter } from 'next/router';
import CircularProgress from '@mui/material/CircularProgress';

type P = {
  opacity: 0 | 1;
  loop?: boolean;
};

const LoadingLottie = ({ opacity, loop = false }: P) => {
  const router = useRouter();
  const mobileMode = router.route.startsWith('/m');

  return (
    <LoadingScreenContainer opacity={opacity}>
      <LottieContainer>
        {mobileMode ? (
          <CircularProgress style={{ color: '#b7bbc2' }} />
        ) : (
          <Lottie animationData={loadingLottie} autoplay={true} loop={loop} />
        )}
      </LottieContainer>
    </LoadingScreenContainer>
  );
};

export default LoadingLottie;
