import { useCallback, useState } from 'react';

// TODO 추후 useModal 사용하도록 변경 후 삭제 예정 @JM
const useCustomModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  return { isOpen, openModal, closeModal };
};

export default useCustomModal;
