export enum ActionType {
  ADD = 'add',
  UPDATE = 'update',
  DELETE = 'delete',
  LIKE = 'like',
  LINK_COPY = 'link-copy',
  PRINT = 'print',
  EDIT = 'edit',
  SORT_RECENT = 'sort-recent',
  SORT_OLD = 'sort-old',
  BOOKMARK = 'bookmark',
}
