import React from 'react';
import { Box } from '@mui/material';
import { SubContentProps } from 'components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSetting';
import ContentDescription, {
  ContentDivider,
} from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';
import Switch from '@/components/@IntranetLibrary/Switch';
import useAlarmSettingProps from './useAlarmSettingProps';
import ApprovalAlarmSetting from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/AlarmSetting/Approval';
import {
  Description,
  DescriptionContent,
  DescriptionTitle,
  Icon,
  Menu,
} from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/AlarmSetting/style';
import FormControlLabel from '@mui/material/FormControlLabel';

const AlarmSettingContent = (props: SubContentProps) => {
  const {
    approvalSummary,
    isJiraActivated,
    jiraServiceName,
    isLoading,
    alarmOnChange,
    approvalMenuOnClick,
    ...approvalAlarmProps
  } = useAlarmSettingProps(props);

  return (
    <>
      <ContentDivider>
        <ContentDescription hasVerticalMargin={true}>
          알림을 켜면 푸시알림과 E-mail로 알림을 받을 수 있어요.
        </ContentDescription>
        <Menu
          onClick={approvalMenuOnClick}
          sx={{
            margin: '16px 0',
          }}
        >
          <Icon>
            <Box
              sx={{
                width: '32px',
                height: '32px',
                background: 'rgba(0, 102, 204, 0.12)',
                borderRadius: '4px',
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M15.1861 26.2401H10.0661C8.65224 26.2401 7.50609 25.0939 7.5061 23.6801L7.5062 8.32012C7.50621 6.90627 8.65236 5.76013 10.0662 5.76013H21.5865C23.0003 5.76013 24.1465 6.90628 24.1465 8.32013V15.3601M19.0265 22.6135L21.3732 24.9601L26.4932 19.8399M11.9865 10.8801H19.6665M11.9865 14.7201H19.6665M11.9865 18.5601H15.8265"
                  stroke="#0066CC"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Box>
          </Icon>
          <Description>
            <DescriptionTitle>{'APPROVAL'}</DescriptionTitle>
            <DescriptionContent>{approvalSummary}</DescriptionContent>
          </Description>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_593_99426)">
                <path
                  d="M10 7L15 12L10 17"
                  stroke="#888D96"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_593_99426">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Box>
        </Menu>
        <FormControlLabel
          sx={{
            margin: '16px 0',
          }}
          control={
            <Menu>
              <Icon>
                <img
                  src={'/icons/jira/jira.png'}
                  srcSet="/icons/jira/jira.png 1x, /icons/jira/jira@2x.png 2x, /icons/jira/jira@3x.png 3x"
                  alt={'jira icon'}
                  width={56}
                  height={56}
                />
              </Icon>
              <Description>
                <DescriptionTitle>{jiraServiceName}</DescriptionTitle>
                <DescriptionContent>
                  나와 관련된 티켓의 업데이트가 있을 때 알림을 받아요.
                </DescriptionContent>
              </Description>
              <Switch
                checked={isJiraActivated}
                onChange={alarmOnChange}
                disabled={isLoading}
                name={'jira'}
              />
            </Menu>
          }
          label={''}
        />
      </ContentDivider>
      <ApprovalAlarmSetting
        {...approvalAlarmProps}
        alarmOnChange={alarmOnChange}
        isLoading={isLoading}
      />
    </>
  );
};

export default AlarmSettingContent;
