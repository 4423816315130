import React, { useCallback } from 'react';
import { ModalRef } from 'components/@IntranetLibrary/Modal';

function useModal(
  ref: React.RefObject<ModalRef>,
  handleOpen?: () => void,
  handleClose?: () => void,
) {
  const openModal = useCallback(
    (props?: any) => {
      ref.current?.open(props);
      handleOpen?.();
    },
    [ref, handleOpen],
  );

  const closeModal = useCallback(() => {
    ref.current?.close();
    handleClose?.();
  }, [ref, handleClose]);

  return {
    openModal,
    closeModal,
  };
}

export default useModal;
