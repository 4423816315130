import {
  ALL_STORE_SALES,
  APPROVAL_FAVORITE_FILTER,
  APPROVAL_RECENT_SEARCH_KEYWORD,
  APPROVAL_RECOMMEND_FILTER_URL,
  APPROVAL_TYPE_ALL_URL,
  AUTH_SHOP_ID_URL,
  AZURE_LOGIN_URL,
  AZURE_TOKEN_URL,
  BASE_CALENDAR_URL,
  BASE_JARDIN_URL,
  BEST_SHOP_URL,
  BIRTHDAY_API_URL,
  BOARD_BASE_API_URL,
  BOARD_COMMENTS_API_URL,
  BOARD_FILTER_URL,
  BOARD_LIKES_API_URL,
  BOOK_PERIOD_URL,
  BRAND_PERMISSION_MISSING_URL,
  CHANGE_JOB_DESCRIPTION_URL,
  CHANGE_LANGUAGE_URL,
  CHANGE_PASSWORD_URL,
  CHECK_NICKNAME_URL,
  COMBINED_DEPARTMENT_URL,
  CREATE_BOARD_URL,
  DELETE_PASSPORT_URL,
  FILE_DOWNLOAD_URL,
  FILE_UPLOAD_URL,
  FIND_USER_BASE_URL,
  GET_ACTIVATED_TOKEN_URL,
  GET_AUTH_SHOP_INFO_URL,
  GET_CERTIFY_CODE_URL,
  I_READ_BOARD_URL,
  IMPORTANT_BOARD_URL,
  ITEM_SALES_DETAIL_URL,
  JARDIN_IMAGE_URL,
  JIRA_ID_URL,
  JIRA_ISSUE_URL,
  JOB_LIST_URL,
  LEGACY_APPROVAL_AUTHOR_URL,
  LEGACY_APPROVAL_ME,
  LEGACY_APPROVAL_RECENT_READ,
  LEGACY_APPROVAL_REFERRER_URL,
  LEGACY_APPROVAL_SEARCH_URL,
  LEGACY_APPROVAL_TEAM_APPROVAL_URL,
  LEGACY_APPROVAL_TEAM_AUTHOR_URL,
  LINK_LIST_URL,
  LOGIN_BY_AZURE_URL,
  LOGIN_BY_ISSUE_PORTAL_TOKEN_URL,
  MENU_URL,
  MESSAGE_ALL_READ_URL,
  MESSAGE_READ_URL,
  MY_NOTIFICATION_OPTION_LIST_URL,
  MY_NOTIFICATION_OPTION_URL,
  MY_NOTIFICATION_PORTAL_OPTION_LIST_URL,
  MY_RESERVATION_BOOK_URL,
  MYINFO_URL,
  NEW_BOOK_LIST_URL,
  NEW_BOOK_URL,
  NEW_EMPLOYEE_INTERVIEWS_URL,
  NOTIFICATION_HISTORY_URL,
  NOTIFICATION_TOPICS_URL,
  ORGANIZATION_BASE_URL,
  ORGANIZATION_URL,
  PASSPORT_BASE_URL,
  PASSPORT_SEARCH_URL,
  PASSPORT_SYNCED_TIME_URL,
  PATCH_PASSPORT_URL,
  PING_URL,
  PORTAL_PASSPORT_URL,
  PRODUCT_DETAIL_URL,
  PRODUCT_SALES_BY_COLORS_URL,
  PROOF_EMP_HISTORY_URL,
  RIVAL_RANK_STANDARD_COMPANY_URL,
  RIVAL_RANK_STORE_URL,
  RIVAL_RANK_URL,
  SALES_ANALYSIS_URL,
  SALES_BY_BEST_URL,
  SALES_BY_CHANNEL_URL,
  SALES_BY_Item_URL,
  SALES_BY_SEASON_URL,
  SALES_BY_TYPE_URL,
  SALES_MAIN_URL,
  SEARCH_EMP_HISTORY_URL,
  SEARCH_GPT_EMPLOYEE_URL,
  SEARCH_PASSPORT_URL,
  SEARCH_POINT_URL,
  SEASONS_URL,
  SEND_EMAIL_URL,
  SET_TOKEN_URL,
  SHOP_INFO_URL,
  SIGNATURE_URL,
  UNREAD_EMAIL_URL,
  UPDATE_COMMENT_LIKE_URL,
  UPDATE_LIKES_URL,
  UPDATE_NICKNAME_URL,
  VERIFY_CERTIFY_CODE_URL,
} from '@/const/apiList';
import useSWR, { SWRConfiguration } from 'swr';
import {
  AnalysisData,
  ApiTokenType,
  Approval,
  ApprovalList,
  ApprovalParams,
  ApprovalType,
  BestItemType,
  Board,
  BoardContent,
  BoardContentData,
  BoardLikes,
  BoardListItem,
  BookData,
  BookPeriod,
  BrandStore,
  CalendarId,
  CategoryType,
  ChannelInfo,
  CreateBoardData,
  Dday,
  DownLoadResponse,
  EmpProofHistoryData,
  FavoriteApprovalFilter,
  FavoriteFilter,
  FavoriteFilterData,
  GetComments,
  GptResponse,
  IBirthdayObj,
  ItemDetail,
  ItemDetailParams,
  ItemDetailRow,
  ItemImageList,
  ItemInfo,
  ItemParams,
  ItemSalesByStoreRanking,
  JardinClosedTime,
  JardinInfoParams,
  JardinMenu,
  JardinReservationData,
  JardinReservationUpdateParams,
  JiraAccountData,
  JiraData,
  LinkFilterList,
  MenuResponse,
  MSLoginUrl,
  MyBook,
  MyInfo,
  MyJob,
  NewBook,
  NewEmployeeInterview,
  NlResponse,
  Notification,
  NotiHistoryData,
  NotiToken,
  NotiTopic,
  NotiWithMeta,
  Organizations,
  Passport,
  PassportSearchParams,
  Period,
  PortalLoginType,
  PortalToken,
  PortalUserResponse,
  ProfileResponse,
  QuickSearch,
  ReadData,
  RecommendFilterResponse,
  ReserveJardinParams,
  RivalCompanyStandardResponse,
  RivalRankResponse,
  RivalStoreResponse,
  SaleByColorData,
  SalesBrandStoresParams,
  SalesByStoreRanking,
  SalesDetail,
  SalesMain,
  SalesMainParams,
  SalesTypeResponse,
  SearchJobList,
  SearchResponse,
  Season,
  SeasonInfo,
  SendMailData,
  ShopInfo,
  ShopInfoParams,
  TokenInfo,
  TotalApprovalParams,
  UnreadCount,
  UploadResponse,
  User,
  UserV0,
  VacationStatus,
  Weather,
} from '@/types/api';
import {
  appFetchConfig,
  deleteDataFetcher,
  getNestedDataFetcher,
  getNestedMetaDataFetcher,
  getNestedNotificationDataFetcher,
  getSocialDataNestedDataFetcherWithMeta,
  makeQueryString,
  patchDataFetcher,
  postDataFetcher,
  putDataFetcher,
} from '@/utils/apis';
import { fetchApi } from '@/utils/api/customAxios';
import { NickName } from '@/components/Profile/SecondItem/MyInfo';
import { Password } from '@/components/Profile/SecondItem/PassWord';
import process from 'process';
import { Elaw, Futube, Meta } from '@/types/legacyApi';
import { E_LAW_INFO, FUTUBE_INFO } from '@/const/legacyApiList';
import useSWRInfinite from 'swr/infinite';
import { getCookie } from '@/utils/tokens';
import { JWT_ACCESS_TOKEN } from '@/const/tokens';
import { msInstance } from '@/utils/api/msAzure/instance';
import { BoardDetailData } from '@/types/board';
import { Comment } from '@/components/Comment/CommentList';
import { BoardFormData } from '@/components/Board/BoardForm/@hooks/useBoardForm';
import { queryBuilder } from '@/utils/urls';
import { boardDetailDataBoardFormData } from '@/components/Board/BoardForm/@hooks/util';
import { useMemo } from 'react';
import { addDays, format } from 'date-fns';
import { apiURL } from '@/constant/apiURL';
import { isFnCo } from '@/utils/passport';
import { isAxiosError } from 'axios';

const swrConfig: SWRConfiguration = {
  onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
    // Never retry on 404.
    if (error?.response?.status === 404) return;
    if (error?.response?.status === 400) return;
    // Only retry up to 10 times.
    if (retryCount >= 3) return;
    // Retry after 5 seconds.
    setTimeout(() => revalidate({ retryCount }), 5000);
  },
};

export type ListParams = {
  listSize?: number;
  currentPage?: number;
  orderType?: 'desc' | 'asc';
  sortType?: 'createdAt' | 'updatedAt';
};

export const useMyInfo = () => {
  return fetchApi<{ data: MyInfo }>({
    method: 'GET',
    url: MYINFO_URL,
  }).then((res) => res.data);
};

export const useJardinImage = () =>
  useSWR<JardinMenu>(
    JARDIN_IMAGE_URL,
    getNestedDataFetcher<JardinMenu>,
    swrConfig,
  );

export type BoardSearchParams = {
  listSize: number;
  currentPage: number;
  sortType: string;
  orderType: 'desc' | 'acs';
  boardNos: string;
  documentNo: string;
  category: string;
  dDay: string;
};

export type BoardParams = Pick<BoardSearchParams, 'documentNo' | 'category'> & {
  boardNo: string;
};

export const useBoard = (obj: BoardParams, shouldFetch = true) => {
  const params = new URLSearchParams(obj);
  return useSWR<BoardContent>(
    shouldFetch ? `${BOARD_BASE_API_URL}?${params.toString()}` : null,
    getNestedDataFetcher<BoardContent>,
    {
      shouldRetryOnError: false,
    },
  );
};

export const useBoardLikes = (
  obj: Pick<BoardParams, 'boardNo' | 'documentNo'>,
) => {
  const params = new URLSearchParams(obj);
  return useSWR<BoardLikes>(
    `${BOARD_BASE_API_URL}${BOARD_LIKES_API_URL}?${params.toString()}`,
    getNestedDataFetcher<BoardLikes>,
    swrConfig,
  );
};

export const useNewEmployees = (
  obj: Partial<BoardSearchParams> = {},
  fallback: NewEmployeeInterview[] = [],
) => {
  const newEntries = Object.entries(obj).map(([k, v]) => [
    k,
    typeof v === 'number' ? v.toString() : v,
  ]);
  const newObject = Object.fromEntries(newEntries) as Record<string, string>;

  const params = new URLSearchParams(newObject);
  return useSWR<NewEmployeeInterview[]>(
    `${NEW_EMPLOYEE_INTERVIEWS_URL}?${params.toString()}`,
    getNestedDataFetcher<NewEmployeeInterview[]>,
    { fallbackData: fallback },
  );
};

export const useFindUser = (userId: string, shouldFetch = true) => {
  return useSWR<UserV0>(
    shouldFetch ? `${FIND_USER_BASE_URL}${userId}` : null,
    getNestedDataFetcher<UserV0>,
    swrConfig,
  );
};

export const useUpdateLikes = (
  obj: Pick<BoardParams, 'boardNo' | 'documentNo'>,
) => {
  return postDataFetcher(UPDATE_LIKES_URL, obj);
};

export const useComments = (
  obj: Pick<BoardParams, 'boardNo' | 'documentNo'>,
) => {
  const params = new URLSearchParams(obj);
  return useSWR<GetComments>(
    `${BOARD_BASE_API_URL}${BOARD_COMMENTS_API_URL}?${params.toString()}`,
    getNestedDataFetcher<GetComments>,
    swrConfig,
  );
};

export const useUpdateComment = (
  obj: Pick<BoardParams, 'boardNo' | 'documentNo'>,
) => {
  return postDataFetcher(UPDATE_LIKES_URL, obj);
};

export const useUpdateCommentLike = (obj: { snsKey: number }) => {
  return postDataFetcher(UPDATE_COMMENT_LIKE_URL, obj);
};

const REDIRECT_URL = `${process.env.NEXT_PUBLIC_ORIGIN_URL}/redirect`;

export const useAzureLoginUrl = () => {
  return useSWR<MSLoginUrl>(
    `${AZURE_LOGIN_URL}?redirectUri=${REDIRECT_URL}`,
    getNestedDataFetcher<MSLoginUrl>,
    swrConfig,
  );
};

export const useAzureChangeLoginUrl = () => {
  return useSWR<MSLoginUrl>(
    `${AZURE_LOGIN_URL}?prompt=select_account&redirectUri=${REDIRECT_URL}`,
    getNestedDataFetcher<MSLoginUrl>,
    swrConfig,
  );
};

export type MSLoginResponse = {
  token_type: string;
  access_token: string;
  expires_at: number;
  refresh_token: string;
};

export const useAzureToken = (code?: string) => {
  return useSWR<MSLoginResponse>(
    code ? `${AZURE_TOKEN_URL}?code=${code}&redirectUri=${REDIRECT_URL}` : null,
    getNestedDataFetcher<MSLoginResponse>,
    swrConfig,
  );
};

export const useLoginByAzure = (accessToken?: string) => {
  return fetchApi<{ data: TokenInfo }>({
    method: 'POST',
    url: LOGIN_BY_AZURE_URL,
    data: {
      accessToken: accessToken,
    },
  });
};

export const usePortalLogin = (params: PortalLoginType) => {
  return postDataFetcher<PortalLoginType, PortalToken>(
    LOGIN_BY_ISSUE_PORTAL_TOKEN_URL,
    params,
  );
};

type LinkCategory = 'link' | 'club' | 'office365' | 'powerBi' | 'guide';

export const useLinkList = (category: LinkCategory) => {
  return useSWR<LinkFilterList>(
    `${LINK_LIST_URL}?category=${category}&service=portal&sortType=sortNo&displayed=1`,
    getNestedDataFetcher<LinkFilterList>,
    swrConfig,
  );
};

export const usePassportSearch = (
  payload: PassportSearchParams,
  minimumSearchLength = 1,
  config?: SWRConfiguration,
) => {
  const { listSize = 1000, keyword = '', ...otherOptions } = payload;

  return useSWR<SearchResponse>(
    () =>
      keyword.length < minimumSearchLength
        ? null
        : `${SEARCH_PASSPORT_URL}${queryBuilder(
            { keyword: keyword.trim(), listSize, ...otherOptions },
            { includeQuestionMark: true },
          )}`,
    getNestedMetaDataFetcher<SearchResponse>,
    config,
  );
};
export const useSearchPassport = (obj: PassportSearchParams) => {
  const queryParam = queryBuilder(obj);

  return useSWR<{ meta: Meta; data: Passport[] }>(
    obj ? `${SEARCH_PASSPORT_URL}?${queryParam}` : null,
    getNestedMetaDataFetcher,
  );
};

export const useEmpSearch = (search: string) => {
  return useSWR<SearchResponse>(
    () =>
      search.length < 2
        ? null
        : `${SEARCH_PASSPORT_URL}?listSize=1000&sort=detail.positionCode,asc,detail.name,asc&keyword=${search}`,
    getNestedMetaDataFetcher<SearchResponse>,
    swrConfig,
  );
};

export const useFindPassport = (passportId: string) => {
  return useSWR<Passport>(
    passportId ? `${PASSPORT_BASE_URL}/${passportId}` : null,
    getNestedDataFetcher<Passport>,
    swrConfig,
  );
};

export type BoardFilterParams = BoardSearchParams & {
  mainBannerType: 'Y' | 'R' | 'N';
  title: string;
  userId: string;
  userName: string;
  companyCode: string;
  displayed: number;
};

export const useFilterBoard = (
  obj: Pick<
    BoardFilterParams,
    'listSize' | 'currentPage' | 'mainBannerType' | 'companyCode'
  >,
  fallback?: Board[],
) => {
  const queryParams = `${BOARD_FILTER_URL}?listSize=${obj.listSize}&currentPage=${obj.currentPage}&mainBannerType=${obj.mainBannerType}`;
  const companyCodeQueryParam = isFnCo(obj.companyCode)
    ? `&companyCode=${obj.companyCode}`
    : '';

  return useSWR<Board[]>(
    queryParams + companyCodeQueryParam,
    getNestedDataFetcher<Board[]>,
    { fallbackData: fallback },
  );
};

export const useImportantBoard = () => {
  return useSWR<Board[]>(IMPORTANT_BOARD_URL, getNestedDataFetcher);
};

export const useMyBook = () => {
  return useSWR<MyBook[]>(MY_RESERVATION_BOOK_URL, getNestedDataFetcher);
};

export const useSearchHistory = () => {
  return useSWR<QuickSearch[]>(
    SEARCH_EMP_HISTORY_URL,
    getNestedDataFetcher<QuickSearch[]>,
    swrConfig,
  );
};

export const useDeleteSearchHistory = () => {
  return deleteDataFetcher(DELETE_PASSPORT_URL);
};

export const useDeleteSearchEmployee = (passportId: string) => {
  return deleteDataFetcher(`${DELETE_PASSPORT_URL}/${passportId}`);
};

export type SaveSearchParams = {
  name: string;
  userId: string;
  positionNameKor: string;
  organizationShortName: string;
  profileImageUrl: string;
};

export const useSaveSearchData = (obj: QuickSearch) => {
  return postDataFetcher(SEARCH_EMP_HISTORY_URL, obj);
};

export const useUpdateNickName = (obj: NickName) => {
  return patchDataFetcher(UPDATE_NICKNAME_URL, obj);
};

export const useSearchPoint = (userNo: string) => {
  return useSWR<{ employeePoint: number }>(
    `${SEARCH_POINT_URL}/${userNo}`,
    getNestedDataFetcher,
    swrConfig,
  );
};

export type SignatureData = {
  companyAddress: string;
  companyLogoImageUrl: string;
  displayOrganizationShortName: string;
  email: string;
  homePageUrl: string;
  insidePhone: string;
  mobilePhone: string;
  name: string;
  nickName: string;
};
export const useSignatureData = (userNo: string) => {
  return useSWR<SignatureData>(
    userNo ? `${SIGNATURE_URL}/${userNo}` : null,
    getNestedDataFetcher,
    swrConfig,
  );
};

export const useChangePassWord = (obj: Password) => {
  return postDataFetcher(CHANGE_PASSWORD_URL, obj);
};

type CertifyCodeOption = {
  telephoneNumber: string;
  serviceName: string;
};
export const useGetCertifyCode = (mobileNumber: string) => {
  return postDataFetcher<
    CertifyCodeOption,
    { data: { smsVerificationId: string } }
  >(GET_CERTIFY_CODE_URL, {
    telephoneNumber: mobileNumber,
    serviceName: 'employmentVerification',
  });
};

export type CertifyForm = {
  smsVerificationId: string;
  smsVerificationCode: string;
};

export const useCertifySmsCode = (obj: CertifyForm) => {
  return postDataFetcher(`${VERIFY_CERTIFY_CODE_URL}`, obj);
};

export type PrintParams = {
  passportId: string;
  outputReason: string;
  smsVerificationId: string;
  smsVerificationCode: string;
};

export const usePrintEmpProof = (obj: PrintParams) => {
  return fetchApi<{ data: string }>({
    method: 'GET',
    url: `/api/v1/passport/${obj.passportId}/certificate-employment/pdf?outputReason=${obj.outputReason}&smsVerificationId=${obj.smsVerificationId}&smsVerificationCode=${obj.smsVerificationCode}`,
  }).then((res) => res.data);
};

export type EmpProofHistoryParams = {
  employeeNumber: string;
  listSize: number;
  currentPage: number;
};

export const useHistoryEmpProof = (obj: EmpProofHistoryParams) => {
  return useSWR<EmpProofHistoryData[]>(
    `${PROOF_EMP_HISTORY_URL}?employeeNumber=${obj.employeeNumber}&listSize=${obj.listSize}&currentPage=${obj.currentPage}`,
    getNestedDataFetcher,
    swrConfig,
  );
};

export type AuthShopId = {
  onlineMallId: string;
  onlineMallPassword: string;
  employeeNumber: string;
};

export const useAuthShopId = (obj: AuthShopId) => {
  return postDataFetcher(AUTH_SHOP_ID_URL, obj);
};

export const useShopAuthInfo = (employeeNumber: string) => {
  return useSWR<{ customerId: string }>(
    `${GET_AUTH_SHOP_INFO_URL}/${employeeNumber}`,
    getNestedDataFetcher,
    swrConfig,
  );
};

export const useCreateBoard = () => {
  return useSWR<CreateBoardData[]>(
    CREATE_BOARD_URL,
    getNestedDataFetcher,
    swrConfig,
  );
};

export const useSendMail = (obj: SendMailData) => {
  return postDataFetcher(SEND_EMAIL_URL, obj);
};

export const useNewBooks = () => {
  return useSWR<BookData[]>(NEW_BOOK_LIST_URL, getNestedDataFetcher, swrConfig);
};

export const useBookPeriod = () => {
  return useSWR<BookPeriod>(BOOK_PERIOD_URL, getNestedDataFetcher, swrConfig);
};

export const useReadBoard = (obj: ReadData) => {
  return postDataFetcher(I_READ_BOARD_URL, obj);
};

export const useJiraId = () => {
  return useSWR<JiraAccountData>(JIRA_ID_URL, getNestedDataFetcher, swrConfig);
};

export const useJiraData = (obj?: JiraAccountData) => {
  const queryParam = new URLSearchParams(obj);
  return useSWR<JiraData[]>(
    obj ? `${JIRA_ISSUE_URL}?${queryParam}` : null,
    getNestedDataFetcher,
    swrConfig,
  );
};

export const useNotiHistory = (listSize = 10) => {
  return useSWR<NotiWithMeta>(
    `${NOTIFICATION_HISTORY_URL}?listSize=${listSize}`,
    (url: string) =>
      fetchApi<NotiWithMeta>({
        method: 'GET',
        url,
        baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
      }).then((res) => res),
  );
};

export const useNotiHistoryPagination = (size = 10) => {
  return useSWRInfinite<NotiHistoryData[]>(
    (page: number) =>
      `${NOTIFICATION_HISTORY_URL}?listSize=${size}&currentPage=${page + 1}`,
    getNestedNotificationDataFetcher<NotiHistoryData[]>,
  );
};

export const useNotiTopics = (listSize = 10) => {
  return useSWR<NotiTopic[]>(
    `${NOTIFICATION_TOPICS_URL}?listSize=${listSize}`,
    getNestedNotificationDataFetcher<NotiTopic[]>,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
      errorRetryCount: 0,
    },
  );
};

export const useNotiTokens = (listSize = 10) => {
  return useSWR<NotiToken[]>(
    `${GET_ACTIVATED_TOKEN_URL}?listSize=${listSize}`,
    getNestedNotificationDataFetcher<NotiToken[]>,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
      errorRetryCount: 0,
    },
  );
};

export const useElawInfo = () => useSWR<Elaw>(E_LAW_INFO, getNestedDataFetcher);

export const useFutubeInfo = () =>
  useSWR<Futube>(FUTUBE_INFO, getNestedDataFetcher);

export const useSetToken = (token: string) => {
  return fetchApi({
    method: 'POST',
    url: SET_TOKEN_URL,
    baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
    data: {
      token: token,
      os: 'web',
      agent: navigator.userAgent,
      tokenKind: 'firebase',
      serviceName: 'portal',
    },
  }).catch((e) => {
    if (e?.response?.status === 400) return;
    return e;
  });
};

export const updateProfileImage = ({ id, obj }: { id: string; obj: File }) => {
  return fetchApi<{ data: ProfileResponse }>({
    extraHeaders: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    url: `${PASSPORT_BASE_URL}/${id}/profile-image`,
    data: {
      file: obj,
    },
  });
};

export const checkNickname = (nickname: string) => {
  return fetchApi({
    method: 'POST',
    url: `${CHECK_NICKNAME_URL}`,
    data: {
      nickname,
    },
  });
};

export const updateNickname = (id: string, nickname: string) => {
  return fetchApi({
    method: 'PATCH',
    url: UPDATE_NICKNAME_URL,
    data: {
      nickname,
    },
  });
};

export const useMessageAllRead = () => {
  return fetchApi({
    method: 'PATCH',
    url: MESSAGE_ALL_READ_URL,
    baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
  });
};

export const useMessageRead = (id: string) => {
  return fetchApi({
    method: 'PATCH',
    url: `${MESSAGE_READ_URL}/${id}`,
    baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
  });
};

export const useMenu = (myInfo?: MyInfo) => {
  return useSWR<MenuResponse>(
    myInfo?.companyCode
      ? `${MENU_URL}?type=global_navigation_bar&service=portal&companyCode=${myInfo.companyCode}`
      : null,
    getNestedDataFetcher,
    swrConfig,
  );
};

export const useMobileMenu = (myInfo?: MyInfo) => {
  return useSWR<MenuResponse>(
    myInfo?.companyCode
      ? `${MENU_URL}?type=global_navigation_bar&service=portal_mobile&companyCode=${myInfo.companyCode}`
      : null,
    getNestedDataFetcher,
    swrConfig,
  );
};

export const usePassport = () => {
  return useSWR<PortalUserResponse>(
    PORTAL_PASSPORT_URL,
    (key) =>
      fetchApi<{ data: PortalUserResponse }>({
        method: 'GET',
        url: key,
        extraHeaders: {
          Authorization: `Bearer ${getCookie(JWT_ACCESS_TOKEN)}`,
        },
      }).then((res) => {
        if (res.data.assignedPassports.length > 1) {
          // 법인명 가나다순
          res.data.assignedPassports.sort((a, b) =>
            a.detail.companyName.localeCompare(b.detail.companyName, 'ko'),
          );
        }
        return res.data;
      }),
    swrConfig,
  );
};

export const useChangePassport = (passportId: string) => {
  return fetchApi({
    method: 'PATCH',
    url: PATCH_PASSPORT_URL,
    data: {
      passportId: passportId,
    },
    extraHeaders: {
      Authorization: `Bearer ${getCookie(JWT_ACCESS_TOKEN)}`,
    },
  });
};

export const useChangeLanguage = (language: string) => {
  return patchDataFetcher(CHANGE_LANGUAGE_URL, { lang: language });
};

export const useUploadFile = (obj?: File) => {
  return fetchApi<{ data: UploadResponse }>({
    extraHeaders: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    url: FILE_UPLOAD_URL,
    timeout: 60000,
    data: {
      downloadType: 'public',
      file: obj,
    },
  });
};

export const useGetFileLink = (uuid: string) => {
  return fetchApi<{ data: DownLoadResponse }>({
    method: 'GET',
    url: `${FILE_DOWNLOAD_URL}?uuid=${uuid}`,
  });
};

export const useMyNotiList = () => {
  return useSWR<Notification[]>(
    MY_NOTIFICATION_OPTION_LIST_URL,
    getNestedNotificationDataFetcher<Notification[]>,
  );
};

export type PortalNoti = {
  templateKey: string;
  description: string;
  emailActivated: boolean;
  webActivated: boolean;
  appActivated: boolean;
};

export const useMyPortalNotiList = () => {
  return useSWR<{
    approval: PortalNoti[];
    jira: PortalNoti[];
  }>(MY_NOTIFICATION_PORTAL_OPTION_LIST_URL, getNestedNotificationDataFetcher);
};

export type NotificationType = 'email' | 'web' | 'app';

export const updateMyPortalOption = async (payload: {
  templateKey: string;
  notificationType?: NotificationType;
  activated: 0 | 1;
}) => {
  await putDataFetcher(
    `${process.env.NEXT_PUBLIC_NOTIFICATION_URL}/api/v1/notificationCenter/portal/option`,
    payload,
  );
};
export const updateMyPortalOptions = async (payload: {
  templateKeys: string[];
  activated: 0 | 1;
}) => {
  await putDataFetcher(
    `${process.env.NEXT_PUBLIC_NOTIFICATION_URL}/api/v1/notificationCenter/portal/option/options`,
    payload,
  );
};

export const useMyNotiOptionChange = ({
  templateKey,
  activated,
}: Pick<Notification, 'templateKey' | 'activated'>) => {
  return fetchApi({
    method: 'POST',
    url: MY_NOTIFICATION_OPTION_URL,
    baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
    data: {
      templateKey,
      activated: activated ? 1 : 0,
    },
  });
};

export const useMsUnreadCount = () => {
  return useSWR<UnreadCount>(UNREAD_EMAIL_URL, (url: string) =>
    fetchApi<UnreadCount>({
      method: 'GET',
      instance: msInstance,
      url: url,
      apiTokenType: ApiTokenType.MS,
    }).then((res) => res),
  );
};

export const usePassportInfo = (jwtAccessToken: string) => {
  return fetchApi<{ data: PortalUserResponse }>({
    method: 'GET',
    url: PORTAL_PASSPORT_URL,
    extraHeaders: {
      Authorization: `Bearer ${jwtAccessToken}`,
    },
  });
};

export const useSendMessage = (id: string, obj: { message: string }) =>
  postDataFetcher(`${PASSPORT_BASE_URL}/${id}/happy-birthday/email`, obj);

export const fetchLeaveHistory = ({
  passportId,
  year,
}: {
  passportId?: string | null;
  year: number;
}) =>
  useSWR<{
    leaves: {
      documentId: string;
      startDate: string;
      endDate: string;
      days: number;
    }[];
    overages: number;
    monthlyLeaveGranted: number;
    annualLeaveGranted: number;
  }>(
    `${PASSPORT_BASE_URL}/${passportId}/leave/history?year=${year}`,
    getNestedDataFetcher,
  );

export const useGetDday = () =>
  useSWR<Dday[]>(`${BASE_CALENDAR_URL}/event/d-day`, getNestedDataFetcher);

/* Social API */
export const useSocialCategories = (existData: boolean, config = swrConfig) =>
  useSWR<CategoryType[]>(
    existData
      ? null
      : apiURL.BOARD_CATEGORY_LIST_FILTER(
          makeQueryString({ listSize: 20000 }, true),
        ),
    getNestedDataFetcher,
    config,
  );
export const useSocialSearch = (
  searchText = '',
  currentDate = '',
  isHome = false,
) => {
  const params: any = {
    subject: searchText,
    isDelete: false,
  };
  const shouldFetch = Boolean(currentDate && searchText);
  if (isHome) {
    params.homeDisplayEndedAt = currentDate;
    params.homeDisplayStartedAt = currentDate;
    params.displaySections = 'rightSection,rightFixSection';
  } else {
    params.displayEndedAt = currentDate;
    params.displayStartedAt = currentDate;
  }
  return useSWR<BoardListItem[]>(
    shouldFetch
      ? `${apiURL.BOARD_SEARCH}/${makeQueryString(params, true, true)}`
      : null,
    getNestedDataFetcher,
  );
};

export const useReservedBoardList = (passportId = '', currentDate = '') => {
  const { data, size, setSize, isValidating, mutate, isLoading } =
    useSWRInfinite<any>(
      (index, previousPageData) => {
        const params = {
          createdBy: passportId,
          reservedDisplayStartedAt: currentDate,
          isDelete: false,
        };
        const shouldFetch = Boolean(currentDate);
        return shouldFetch
          ? `${apiURL.BOARD_SEARCH}/${makeQueryString(params, true, true)}`
          : null;
      },
      getSocialDataNestedDataFetcherWithMeta,
      { revalidateFirstPage: true, revalidateOnFocus: false }, // 북마크 반영 가져오려면 revalidateFirstPage true여야 함
    );

  const { content, totalPage } = useMemo(() => {
    if (data) {
      const flatData = [...data.flatMap((page) => page)];
      return {
        content: [...flatData.map((page) => page.content)],
        totalPage: flatData[0].meta.endPage,
      };
    }
    return { content: [], totalPage: null };
  }, [data]);

  const loadNext = () => setSize((prev) => prev + 1);
  const hasNext = useMemo(() => {
    if (!totalPage) return false;
    return size < totalPage;
  }, [size, totalPage]);
  const isRefreshing = isValidating && data && data.length < size;

  return {
    data: data ? content : [],
    realData: data,
    mutate,
    loadNext,
    hasNext,
    isValidating,
    isLoading,
    isRefreshing,
    size,
  };
};

export const useMainBoardWithFilter = (
  displaySection = '',
  currentDate = '',
  listSize?: number,
  searchText?: string,
) => {
  const shouldFetch = Boolean(currentDate);
  const params: any = {
    displaySections: displaySection,
    homeDisplayEndedAt: currentDate,
    homeDisplayStartedAt: currentDate,
  };
  if (listSize) {
    params.listSize = listSize;
  }
  if (searchText) {
    params.subject = searchText;
  }

  return useSWR<BoardContentData[]>(
    shouldFetch
      ? `${apiURL.BOARD_SEARCH}${makeQueryString(params, true)}`
      : null,
    getNestedDataFetcher,
  );
};

export const useSearchSocialBoardWithFilter = (
  searchText = '',
  categoryId = '',
  groupId = '',
  isBookmark = false,
  isUnRead = false,
  displaySections = '',
  currentDate = '',
) => {
  const { data, size, setSize, isValidating, mutate, isLoading } =
    useSWRInfinite<any>(
      (index, previousPageData) => {
        const params: any = {
          subject: searchText,
          categoryId: categoryId === 'all' ? '' : categoryId,
          categoryGroupId: groupId,
          listSize: 48,
          displayEndedAt: currentDate,
          displayStartedAt: currentDate,
          isDelete: false,
          currentPage: index + 1,
          // sortType: 'displayStartedAt', [portal-3701 이슈 참고]
          // orderType: 'desc',
          // displaySections: 'bannerSection', //todo 테스트용
        };

        const shouldFetch = Boolean(currentDate);

        if (displaySections) {
          params.displaySections = displaySections;
        }

        //[PORTAL-3705 이슈 참고] off시 isBookmark 호출x, on시 isBookmark 1으로 호출
        if (isBookmark) {
          params.isBookmark = true;
        }

        //[PORTAL-3704 이슈 참고] off시 isRead 호출x, on시 isRead 0으로 호출
        if (isUnRead) {
          params.isRead = false;
        }

        return shouldFetch
          ? `/api/v1/board${makeQueryString(params, true, true)}`
          : null;
      },
      getSocialDataNestedDataFetcherWithMeta,
      { revalidateFirstPage: true, revalidateOnFocus: false }, // 북마크 반영 가져오려면 revalidateFirstPage true여야 함
    );

  const { content, totalPage } = useMemo(() => {
    if (data) {
      const flatData = [...data.flatMap((page) => page)];
      return {
        content: [...flatData.map((page) => page.content)],
        totalPage: flatData[0].meta.endPage,
      };
    }
    return { content: [], totalPage: null };
  }, [data]);

  const loadNext = () => setSize((prev) => prev + 1);
  const hasNext = useMemo(() => {
    if (!totalPage) return false;
    return size < totalPage;
  }, [size, totalPage]);
  const isRefreshing = isValidating && data && data.length < size;

  return {
    data: data ? content : [],
    realData: data,
    mutate,
    loadNext,
    hasNext,
    isValidating,
    isLoading,
    isRefreshing,
    size,
  };
};

type BoardPostFile = {
  name: string;
  link: string;
};

export type BoardPost = {
  subject: string;
  content: string;
  files: {
    attachments: BoardPostFile[];
    thumbnails: BoardPostFile[];
    slideImages: BoardPostFile[];
  };
  categories: string[];
  config: {
    isTemporary: boolean;
    viewType: 'normal' | 'slide';
    displaySections: BoardFormData['displaySections'][];
    displayStartedAt?: string;
    displayEndedAt?: string;
    commentSection: BoardFormData['commentPosition'];
    isImportant: boolean;
    editorType: 'Froala' | 'LexicalEditor';
    homeDisplayStartedAt?: string;
    homeDisplayEndedAt?: string;
  };
};

const getBoardPostPayloadWidthDefaultValues = ({
  config,
  ...payload
}: BoardPost) => {
  return {
    ...payload,
    config: {
      ...config,
      isCommentEnable: true,
      feedbackSettings: {
        like: true,
        recommend: true,
      },
    },
    commentGroupConfig: {
      type: 'normal',
      reply: 0,
      anonymous: 0,
      feedback: 1,
      attachment: 0,
      delete: 1,
    },
  };
};

/** 게시글 작성 */
//TODO: 기존 useCreateBoard랑 비교
export const useCreateBoardPost = async (
  payload: BoardPost,
): Promise<BoardDetailData> => {
  const {
    data: { documentId },
  } = await postDataFetcher<BoardPost, { data: { documentId: string } }>(
    apiURL.BOARD_CREATE,
    getBoardPostPayloadWidthDefaultValues(payload),
  );

  return await getNestedDataFetcher(apiURL.BOARD_DETAIL(documentId));
};
/** 게시글 수정 */
export const useUpdateBoardPost = async (
  payload: BoardPost & { documentId: string },
) => {
  await putDataFetcher(apiURL.BOARD_UPDATE, {
    ...getBoardPostPayloadWidthDefaultValues(payload),
    documentId: payload.documentId,
  });

  return true;
};

/* 게시글 읽음 처리 */
export const readBoard = (documentId: string, mutate?: () => void) => {
  putDataFetcher(apiURL.BOARD_READ(documentId), {}).then(() => mutate?.());
};
/* 게시글 조회 */
export const useGetBoardDetail = (
  documentId?: string,
  config?: SWRConfiguration,
) => {
  return useSWR<BoardDetailData>(
    documentId ? apiURL.BOARD_DETAIL(documentId) : null,
    getNestedDataFetcher,
    config,
  );
};

/** 게시글 수정용 데이터 조회 */
export const useGetEditDetailBoard = (
  isTemporary: boolean,
  documentId?: string,
) => {
  const {
    data: boardDetailData,
    error: boardDetailError,
    isLoading: boardDetailIsLoading,
    isValidating,
  } = useGetBoardDetail(documentId, {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const {
    data: fullCategoryList,
    error: categoryError,
    isLoading: categoryIsLoading,
  } = useSWR<CategoryType[]>(
    boardDetailData
      ? apiURL.BOARD_CATEGORY_LIST_FILTER(
          makeQueryString({ listSize: 20000 }, true),
        )
      : null,
    getNestedDataFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const error = boardDetailError || categoryError;
  const isLoading = boardDetailIsLoading || categoryIsLoading || isValidating;
  const data = boardDetailDataBoardFormData(
    isTemporary,
    boardDetailData,
    fullCategoryList,
  );

  return {
    data,
    error,
    isLoading,
  };
};
/** 임시글 조회 */
export const useGetTempBoard = () => {
  return useSWR<BoardDetailData[]>(
    `${apiURL.BOARD_SEARCH}?isTemporary=1&isDelete=0`,
    getNestedDataFetcher,
    {
      revalidateOnFocus: false,
    },
  );
};

/* 게시글 삭제 */
export const useDeleteBoard = async (
  documentId: string,
): Promise<boolean | string> => {
  if (!documentId) return '잘못된 요청입니다. 새로고침 후 다시 시도해 주세요.';
  try {
    await deleteDataFetcher(apiURL.BOARD_DETAIL(documentId));
    return true;
  } catch (error: any) {
    console.error(error);
    return error.response?.data?.message || false;
  }
};

/* 임시글 전체 삭제 */
export const useDeleteTempBoard = async (
  tempBoardList: string[],
): Promise<boolean> => {
  if (tempBoardList.length === 0) {
    return true;
  }

  await Promise.all(
    tempBoardList.map((id) => deleteDataFetcher(apiURL.BOARD_DETAIL(id))),
  );

  return true;
};

/* 게시글 북마크 변경 */
export const changeBoardBookmark = async (
  documentId: string,
  isBookmark: boolean,
) => {
  try {
    if (isBookmark) {
      await putDataFetcher(apiURL.BOARD_FEEDBACK(documentId), {
        type: 'bookmarks',
      });
      return true;
    } else {
      await deleteDataFetcher(apiURL.BOARD_FEEDBACK(documentId), {
        type: 'bookmarks',
      });
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

/* 게시글 피드백 유저 조회 */
export const getFeedbackUser = (documentId: string, type = 'likes') => {
  const swrKey = documentId
    ? `${apiURL.BOARD_FEEDBACK(documentId)}?type=${type}&listSize=9999`
    : null;
  return useSWR<User[]>(swrKey, getNestedDataFetcher);
};
/* 게시글 좋아요 변경 */
export const changeLikeBoard = async (documentId: string, isLike: boolean) => {
  try {
    if (isLike) {
      await putDataFetcher(apiURL.BOARD_FEEDBACK(documentId), {
        type: 'likes',
      });
      return true;
    } else {
      await deleteDataFetcher(apiURL.BOARD_FEEDBACK(documentId), {
        type: 'likes',
      });
      return true;
    }
  } catch (error: any) {
    console.error(error);
    return false;
  }
};
/* 댓글 조회 */
export const useCommentList = (
  commentGroupId: string,
  paramsObj: ListParams,
) => {
  const swrKey = commentGroupId
    ? `${
        apiURL.COMMENT_LIST
      }?commentGroupId=${commentGroupId}&${makeQueryString(
        paramsObj,
        false,
        false,
      )}`
    : null;
  return useSWR<Comment[]>(swrKey, getNestedDataFetcher);
};
/* 댓글 삭제 */
export const useDeleteComment = async (
  commentId: string,
): Promise<boolean | string> => {
  if (!commentId) return '잘못된 요청입니다. 새로고침 후 다시 시도해 주세요.';
  try {
    await deleteDataFetcher(apiURL.COMMENT_DETAIL(commentId));
    return true;
  } catch (error: any) {
    console.error(error);
    return error.response?.data?.message || false;
  }
};
/* 댓글 반응 업데이트 */
export const useLikeComment = async (
  commentId: string,
  isLike: boolean,
): Promise<boolean | string> => {
  if (!commentId) return '잘못된 요청입니다. 새로고침 후 다시 시도해 주세요.';
  try {
    await postDataFetcher(apiURL.COMMENT_FEEDBACK, {
      commentId,
      feedback: { recommend: isLike ? 1 : 2 },
    });
    return true;
  } catch (error: any) {
    console.error(error);
    return error.response?.data?.message || false;
  }
};
/* 댓글 추가 */
export const useCreateComment = async (payload: {
  commentGroupId: string;
  content: string;
  service: string;
  customData?: unknown;
  mentions?: string[];
}) => {
  return await postDataFetcher(apiURL.COMMENT_CREATE, payload);
};

export const useGetBirthdays = () =>
  useSWR<IBirthdayObj>(BIRTHDAY_API_URL, getNestedDataFetcher<IBirthdayObj>);

export const useGetNewBookCount = () =>
  useSWR<NewBook>(NEW_BOOK_URL, getNestedDataFetcher);

export const useChangedJob = (arg: MyJob) =>
  putDataFetcher(CHANGE_JOB_DESCRIPTION_URL, arg);

export const useGetJobList = (search?: string) =>
  useSWR<SearchJobList>(
    search
      ? `${JOB_LIST_URL}?listSize=500&currentPage=1&keyword=${search}`
      : `${JOB_LIST_URL}?listSize=500&currentPage=1`,
    getNestedMetaDataFetcher,
  );

export const useGetOrganization = (config?: SWRConfiguration) =>
  useSWR<Organizations>(ORGANIZATION_URL, getNestedMetaDataFetcher, config);

export const useGetApprovalData = (obj: ApprovalParams) => {
  const newEntries = Object.entries(obj).map(([k, v]) => [
    k,
    typeof v === 'number' ? v.toString() : v,
  ]);
  const newObject = Object.fromEntries(newEntries) as Record<string, string>;

  const queryParam = new URLSearchParams(newObject);

  return useSWR<{ meta: Meta; data: Approval[] }>(
    `${LEGACY_APPROVAL_AUTHOR_URL}?${queryParam}`,
    getNestedMetaDataFetcher,
  );
};

export const useGetMyReferrerApprovalData = (
  obj: ApprovalParams,
  config?: SWRConfiguration,
) => {
  const newEntries = Object.entries(obj).map(([k, v]) => [
    k,
    typeof v === 'number' ? v.toString() : v,
  ]);
  const newObject = Object.fromEntries(newEntries) as Record<string, string>;

  const queryParam = new URLSearchParams(newObject);

  return useSWR<{ meta: Meta; data: Approval[] }>(
    `${LEGACY_APPROVAL_REFERRER_URL}?${queryParam}`,
    getNestedMetaDataFetcher,
    config,
  );
};

export const useGetMyTeamWriteApprovalData = (obj: ApprovalParams) => {
  const newEntries = Object.entries(obj).map(([k, v]) => [
    k,
    typeof v === 'number' ? v.toString() : v,
  ]);
  const newObject = Object.fromEntries(newEntries) as Record<string, string>;

  const queryParam = new URLSearchParams(newObject);

  return useSWR<{ meta: Meta; data: Approval[] }>(
    `${LEGACY_APPROVAL_TEAM_AUTHOR_URL}?${queryParam}`,
    getNestedMetaDataFetcher,
  );
};

export const useGetMyTeamApprovalData = (obj: ApprovalParams) => {
  const newEntries = Object.entries(obj).map(([k, v]) => [
    k,
    typeof v === 'number' ? v.toString() : v,
  ]);
  const newObject = Object.fromEntries(newEntries) as Record<string, string>;

  const queryParam = new URLSearchParams(newObject);

  return useSWR<{ meta: Meta; data: Approval[] }>(
    `${LEGACY_APPROVAL_TEAM_APPROVAL_URL}?${queryParam}`,
    getNestedMetaDataFetcher,
  );
};

export const useGetApprovalType = () =>
  useSWR<ApprovalType[]>(
    `${APPROVAL_TYPE_ALL_URL}?isEnabled=true&isDisplayed=true`,
    getNestedDataFetcher,
  );

export const useGetApprovalAllType = () =>
  useSWR<ApprovalType[]>(APPROVAL_TYPE_ALL_URL, getNestedDataFetcher);

export const useGetMyApprovalData = (
  obj: ApprovalParams,
  config?: SWRConfiguration,
) => {
  const newEntries = Object.entries(obj).map(([k, v]) => [
    k,
    typeof v === 'number' ? v.toString() : v,
  ]);
  const newObject = Object.fromEntries(newEntries) as Record<string, string>;

  const queryParam = new URLSearchParams(newObject);

  return useSWR<{ meta: Meta; data: Approval[] }>(
    `${LEGACY_APPROVAL_ME}?${queryParam}`,
    getNestedMetaDataFetcher,
    config,
  );
};

export const useRecentReadApproval = () =>
  useSWR<Approval[]>(LEGACY_APPROVAL_RECENT_READ, getNestedDataFetcher);

export const useSearchApproval = (obj: TotalApprovalParams) => {
  const filteredEntries = Object.entries(obj).filter(
    ([_, v]) =>
      v !== undefined && v !== '' && (Array.isArray(v) ? v.length > 0 : true),
  );

  const newEntries = filteredEntries.map(([k, v]) => [
    k,
    typeof v === 'number' ? v.toString() : v,
  ]);
  const newObject = Object.fromEntries(newEntries) as Record<string, string>;

  const queryParam = new URLSearchParams(newObject);

  return useSWR<ApprovalList>(
    `${LEGACY_APPROVAL_SEARCH_URL}?${queryParam}`,
    getNestedMetaDataFetcher,
  );
};

export const useGetFilterRecommend = () =>
  useSWR<RecommendFilterResponse>(
    APPROVAL_RECOMMEND_FILTER_URL,
    getNestedDataFetcher,
  );

export const useGetCombinedDepartment = (code: string) => {
  return useSWR<string[]>(
    code ? `${COMBINED_DEPARTMENT_URL}/${code}` : null,
    getNestedDataFetcher,
  );
};

export const useGetPassportSearchHistory = () =>
  useSWR<string[]>(PASSPORT_SEARCH_URL, getNestedDataFetcher);

export const useDeletePassportSearchHistory = (keyword: string) => {
  return deleteDataFetcher(`${PASSPORT_SEARCH_URL}/${keyword}`);
};

export const useGetLeader = (orgCode: string) =>
  useSWR<Passport[]>(
    orgCode ? `${ORGANIZATION_BASE_URL}/${orgCode}` : null,
    getNestedDataFetcher,
  );

export const useGetJardinCloseTime = (obj: JardinInfoParams) =>
  useSWR<JardinClosedTime>(
    obj
      ? `${BASE_JARDIN_URL}/closedTime?startDate=${obj.startDate}&endDate=${obj.endDate}`
      : null,
    getNestedDataFetcher,
  );

export type RES<T extends Record<string, any>> = {
  status: boolean;
  response: T;
  message: string;
};

export const useJardinMyInfo = () => {
  const currentData = new Date();
  const params = {
    startDate: format(currentData, 'yyyy-MM-dd'),
    endDate: format(addDays(currentData, 7), 'yyyy-MM-dd'),
  };
  return useSWR<RES<JardinReservationData>>(
    `${BASE_JARDIN_URL}/reservation?startDate=${params.startDate}&endDate=${params.endDate}`,
    getNestedDataFetcher,
  );
};

type JardinResponse = {
  data: {
    message: string;
    status: boolean;
    response: any;
  };
};
export const useUpdateJardin = (obj: JardinReservationUpdateParams) =>
  putDataFetcher<JardinReservationUpdateParams, JardinResponse>(
    `${BASE_JARDIN_URL}/reservation`,
    obj,
  );

export const useReserveJardin = (obj: ReserveJardinParams) =>
  postDataFetcher<ReserveJardinParams, JardinResponse>(
    `${BASE_JARDIN_URL}/reservation`,
    obj,
  );

export const useGetSeasons = () =>
  useSWR<Season[]>(SEASONS_URL, getNestedDataFetcher);

export const useGetSaleMainData = ({ season, date }: SalesMainParams) =>
  useSWR<SalesMain>(
    `${SALES_MAIN_URL(season)}?date=${date}`,
    getNestedDataFetcher,
    {
      shouldRetryOnError: false, // 에러 발생 시 재시도를 하지 않도록 설정
      revalidateOnFocus: false,
    },
  );

export const usePing = () =>
  useSWR(PING_URL, getNestedDataFetcher, appFetchConfig);

export const useGetBrandStoreData = ({
  season,
  date,
  brand,
  period,
}: SalesBrandStoresParams) =>
  useSWR<BrandStore>(
    `${ALL_STORE_SALES(season, brand)}?date=${date}&period=${period}`,
    getNestedDataFetcher,
  );

export const useGetTypeMode = ({
  season,
  date,
  brand,
  period,
  shopCode = 'ALL',
}: SalesBrandStoresParams & { shopCode?: string }) =>
  useSWR<SalesTypeResponse[]>(
    `${SALES_BY_TYPE_URL(
      season,
      brand,
      shopCode,
    )}?date=${date}&period=${period}`,
    getNestedDataFetcher,
  );
export const useGetBestItems = ({
  season,
  date,
  brand,
  period,
  productKindCode,
  shopCode = 'ALL',
}: SalesBrandStoresParams & {
  shopCode?: string;
  productKindCode: 'L' | 'A';
}) =>
  useSWR<BestItemType[]>(
    `${SALES_BY_BEST_URL(
      season,
      brand,
      shopCode,
    )}?date=${date}&period=${period}&productKindCode=${productKindCode}`,
    getNestedDataFetcher,
  );

export const useGetItemSaleDetail = ({
  season,
  brand,
  productCode,
  shopCode,
  date,
  period,
}: ItemDetailParams & { date: string; period: Period }) =>
  useSWR<SalesDetail[]>(
    productCode
      ? `${ITEM_SALES_DETAIL_URL({
          season,
          brand,
          productCode,
          shopCode,
        })}?date=${date}&period=${period}`
      : null,
    getNestedDataFetcher,
  );

export const useGetItemByStoreRank = ({
  season,
  brand,
  productCode,
  colorCode,
  date,
  period,
}: SalesByStoreRanking & { date: string; period: Period }) =>
  useSWR<ItemSalesByStoreRanking[]>(
    productCode && colorCode
      ? `${BEST_SHOP_URL({
          season,
          brand,
          productCode,
          colorCode,
        })}?date=${date}&period=${period}`
      : null,
    getNestedDataFetcher,
  );

export const useGetRivalRank = (date: string) =>
  useSWR<RivalRankResponse[]>(
    `${RIVAL_RANK_URL}?date=${date}`,
    getNestedDataFetcher,
  );

export const useGetRivalRankStandardCompany = ({
  date,
  brand,
  shopCode,
}: {
  date: string;
  brand: string;
  shopCode?: string;
}) =>
  useSWR<RivalCompanyStandardResponse[]>(
    `${RIVAL_RANK_STANDARD_COMPANY_URL(brand, shopCode)}?date=${date}`,
    getNestedDataFetcher,
  );

export const useGetRivalRankStore = ({
  date,
  brand,
}: {
  date: string;
  brand: string;
}) =>
  useSWR<RivalStoreResponse[]>(
    `${RIVAL_RANK_STORE_URL(brand)}?date=${date}&brand=${brand}`,
    getNestedDataFetcher,
  );

export const useGetCalendarId = () =>
  useSWR<CalendarId[]>(
    `${BASE_CALENDAR_URL}?keyword=대한민국 날씨`,
    getNestedDataFetcher,
  );

export const useGetWeather = ({
  calendarId,
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
  calendarId?: string;
}) =>
  useSWR<Weather[]>(
    calendarId
      ? `${BASE_CALENDAR_URL}/${calendarId}/event?startDatetime=${startDate}&endDatetime=${endDate}&timezone=Asia/Seoul`
      : null,
    getNestedDataFetcher,
  );

export const useGetAnalysis = ({ season, date }: SalesMainParams) =>
  useSWR<AnalysisData[]>(
    `${SALES_ANALYSIS_URL}?date=${date}&season=${season}`,
    getNestedDataFetcher,
  );

export const useGetDetailByChannel = ({
  brand,
  date,
  total,
}: {
  brand: string;
  date: string;
  total: boolean;
}) =>
  useSWR<ChannelInfo[]>(
    `${SALES_BY_CHANNEL_URL(brand)}?date=${date}&total=${total}`,
    getNestedDataFetcher,
  );

export const useGetDetailBySeason = ({
  brand,
  date,
}: {
  brand: string;
  date: string;
}) =>
  useSWR<SeasonInfo[]>(
    `${SALES_BY_SEASON_URL(brand)}?date=${date}`,
    getNestedDataFetcher,
  );

export const useGetDetailByItem = ({
  brand,
  date,
}: {
  brand: string;
  date: string;
}) =>
  useSWR<ItemInfo[]>(
    `${SALES_BY_Item_URL(brand)}?date=${date}`,
    getNestedDataFetcher,
  );

export const useGetItemDetail = ({
  season,
  brand,
  productCode,
  date,
}: ItemParams) =>
  useSWR<{
    itemImageList: ItemImageList[];
    itemDetail: ItemDetail;
    display_rows: ItemDetailRow[];
  }>(
    `${PRODUCT_DETAIL_URL({ season, brand, productCode, date })}`,
    getNestedDataFetcher,
  );

export const useGetItemSalesByColor = ({
  season,
  brand,
  productCode,
  date,
}: ItemParams) =>
  useSWR<SaleByColorData[]>(
    `${PRODUCT_SALES_BY_COLORS_URL({ season, brand, productCode, date })}`,
    getNestedDataFetcher,
  );
//SHOP_INFO_URL

export const useGetShopInfo = ({
  season,
  date,
  brand,
  period,
  shopCode,
}: ShopInfoParams) =>
  useSWR<ShopInfo>(
    `${SHOP_INFO_URL(season, brand, shopCode)}?date=${date}&period=${period}`,
    getNestedDataFetcher,
  );

export const useGetPassportSyncTime = () =>
  useSWR<{ latestSyncedAt: string }>(
    PASSPORT_SYNCED_TIME_URL,
    getNestedDataFetcher,
  );

export const useGetApprovalSearchKeyword = () =>
  useSWR<string[]>(APPROVAL_RECENT_SEARCH_KEYWORD, getNestedDataFetcher);

export const useDeleteApprovalKeyword = (keyword: string) =>
  deleteDataFetcher(`${APPROVAL_RECENT_SEARCH_KEYWORD}/${keyword}`);

export const useGetVacationStatus = (passportId: string) =>
  useSWR<VacationStatus>(
    passportId ? `${PASSPORT_BASE_URL}/${passportId}/leave/status` : null,
    getNestedDataFetcher,
  );

export const useGetBrandPermissionMissing = () =>
  useSWR<string[]>(BRAND_PERMISSION_MISSING_URL, getNestedDataFetcher);

export const useSearchGpt = async (keyword?: string) => {
  const { data } = await fetchApi<{ data: NlResponse }>({
    method: 'GET',
    url: `${SEARCH_GPT_EMPLOYEE_URL}?answer=${keyword}`,
  });

  const completedData = await getComplete({
    threadId: data.threadId,
    id: data.id,
  });

  if (!completedData) {
    throw new Error('getComplete failed');
  }

  return getMessage({ threadId: data.threadId });
};

const getComplete = async ({
  threadId,
  id,
  maxRetries = 100, // 재시도 제한
  interval = 300, // 요청 간격
}: {
  threadId: string;
  id: string;
  maxRetries?: number;
  interval?: number;
}): Promise<NlResponse | null> => {
  let retries = 0;

  while (retries < maxRetries) {
    try {
      const res = await fetchApi<{ data: NlResponse }>({
        method: 'GET',
        url: `/api/v1/labs/assistant/threads/${threadId}/runs/${id}`,
      });

      if (res.data.status === 'completed') {
        return res.data;
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response?.data?.message || 'Unknown error';
        throw new Error(`getComplete failed with status ${status}: ${message}`);
      } else {
        throw new Error(`Unexpected error in getComplete: ${String(error)}`);
      }
    }
    retries++;
    await new Promise((resolve) => setTimeout(resolve, interval));
  }

  throw new Error(`getComplete over try count: ${maxRetries}`);
};

const getMessage = async ({ threadId }: { threadId: string }) => {
  try {
    const { data } = await fetchApi<{ data: GptResponse }>({
      method: 'GET',
      url: `/api/v1/labs/assistant/threads/${threadId}/messages/latest`,
    });

    return data;
  } catch (error) {
    throw new Error(`getMessage failed for threadId: ${threadId}`);
  }
};

export const useSearchGptEmployee = (keyword?: string) =>
  useSWR<SearchResponse>(
    keyword ? `${SEARCH_GPT_EMPLOYEE_URL}?answer=${keyword}` : null,
    getNestedMetaDataFetcher,
  );

export const useBookMark = (documentId: string) =>
  fetchApi<{ data: TokenInfo }>({
    method: 'POST',
    url: `${LEGACY_APPROVAL_SEARCH_URL}/${documentId}/bookmark`,
  });

export const useSetApprovalFavoriteFilter = (obj: FavoriteFilter) =>
  postDataFetcher(APPROVAL_FAVORITE_FILTER, obj);

export const useGetApprovalFavoriteFilter = () =>
  useSWR<FavoriteApprovalFilter>(
    `${APPROVAL_FAVORITE_FILTER}/me?listSize=50`,
    getNestedMetaDataFetcher,
  );

export const useDeleteApprovalFavoriteFilter = (id: string) =>
  deleteDataFetcher(`${APPROVAL_FAVORITE_FILTER}/${id}`);

export const useGetFavoriteFilter = (id?: string) =>
  useSWR<FavoriteFilterData>(
    id ? `${APPROVAL_FAVORITE_FILTER}/${id}` : null,
    getNestedDataFetcher,
  );

export const useModifyFavoriteFilter = ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => putDataFetcher(`${APPROVAL_FAVORITE_FILTER}/${id}/name`, { name: name });
