import { IconButton, IconButtonProps, Typography } from '@mui/material';
import {
  BookmarkChecked,
  BookmarkDefault,
} from '@/theme/overrides/CustomIcons';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { MouseEvent } from 'react';
import { BoxProps } from '@mui/material/Box';
import { EtcMenuWrapper } from '@/components/Social/styles';
import { useSearchSocialBoardWithFilter } from '@/hooks/apiHooks';
import { useRecoilState } from 'recoil';
import { socialState } from '@/recoil/atom';

type P = {
  isChecked: boolean;
  onChange: (v: boolean) => void;
} & Omit<IconButtonProps, 'onChange'>;
const BookmarkToggleButton = ({ isChecked, onChange, ...props }: P) => {
  const handleChange = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(!isChecked);
  };

  return (
    <IconButton
      sx={{
        width: 44,
        height: 24,
        p: 0,
      }}
      onClick={handleChange}
      {...props}
    >
      {isChecked ? (
        <BookmarkChecked width={24} height={24} />
      ) : (
        <BookmarkDefault width={24} height={24} />
      )}
    </IconButton>
  );
};

export default BookmarkToggleButton;

type RHFP = {
  name: string;
  isChecked: undefined | boolean;
} & Omit<IconButtonProps, 'onChange'>;

export const RHFBookmarkToggleButton = (props: RHFP) => {
  const { name, isChecked, ...other } = props;
  const { setValue } = useFormContext();
  const bookmarkChange = (changedValue: boolean) => {
    setValue(name, changedValue);
  };

  return (
    <BookmarkToggleButton
      isChecked={!!isChecked}
      onChange={bookmarkChange}
      {...other}
    />
  );
};

type buttonP = { bookmarkToggle: () => void; isBookmark: boolean } & BoxProps;

export function CategoryBookmarkButton({
  bookmarkToggle,
  isBookmark,
  ...props
}: buttonP) {
  const [state] = useRecoilState(socialState);
  const { data } = useSearchSocialBoardWithFilter(
    '',
    '',
    '',
    true,
    false,
    '',
    state.currentDate,
  );
  return (
    <EtcMenuWrapper onClick={bookmarkToggle} isSelect={isBookmark}>
      <Typography fontSize={16} fontWeight={400}>
        <span>북마크</span>
        {data && data.length > 0 && data[0].length > 0 && (
          <span style={{ marginLeft: '10px' }}>{data[0].length}</span>
        )}
      </Typography>
      <RHFBookmarkToggleButton
        name={'isBookmark'}
        isChecked={isBookmark}
        disabled={true}
      />
    </EtcMenuWrapper>
  );
}
