import { FormEvent, useEffect, useMemo, useState } from 'react';
import { SubContentProps } from '../../LanguageSetting';
import { ShoppingMallVerificationProps } from '../index';
import useShoppingMallVerificationForm, {
  ShoppingMallVerificationForm,
} from './useShoppingMallVerificationForm';
import useApiRequest from '@/hooks/useApiRequest';
import { useDialog } from '@/hooks/useDialog';
import { useAuthShopId, useShopAuthInfo } from '@/hooks/apiHooks';

const useShoppingMallVerificationProps = (
  settings: SubContentProps,
): ShoppingMallVerificationProps => {
  const dialog = useDialog();

  const {
    myInfo: { name, employeeNumber },
  } = settings;
  const {
    data: verificationData,
    error: verificationError,
    isLoading: verificationLoading,
    mutate: updateVerificationData,
  } = useShopAuthInfo(employeeNumber);

  const [verificationInfo, setVerificationInfo] = useState<
    ShoppingMallVerificationProps['verificationInfo']
  >([]);
  const verificationStatus = useMemo(() => {
    if (verificationError) {
      if ((verificationError.response?.status ?? 0) === 404) {
        return 'success';
      } else {
        return 'error';
      }
    }
    if (verificationLoading) {
      return 'loading';
    }
    return 'success';
  }, [verificationError, verificationLoading]);

  const methods = useShoppingMallVerificationForm();
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

  const { isLoading, request } = useApiRequest();

  const handleFormOnSubmit = (e: FormEvent) => {
    e.preventDefault();

    handleSubmit(async ({ id, password }: ShoppingMallVerificationForm) => {
      await request(
        async () => {
          await useAuthShopId({
            onlineMallId: id,
            onlineMallPassword: password,
            employeeNumber,
          });
          updateVerificationData();
          reset();
          dialog.show({
            message: '인증이 완료되었습니다.',
          });
        },
        (response) => {
          dialog.show({
            message:
              response.code === '0422'
                ? '일치하는 계정이 없습니다.\n입력 정보를 다시 확인해 주세요.'
                : '에러가 발생했습니다.',
          });
        },
      );
    })();
  };

  useEffect(() => {
    if (verificationData) {
      setVerificationInfo([
        { label: '이름', value: name },
        { label: '사번', value: employeeNumber },
        { label: 'CID', value: verificationData.customerId },
      ]);
    }
  }, [verificationData, name, employeeNumber]);

  return {
    formProps: {
      methods,
      onSubmit: handleFormOnSubmit,
      id: 'mypage-shopping-mall-verification',
    },
    isSubmitButtonDisabled: !isValid,
    isLoading,
    verificationInfo,
    verificationStatus,
  };
};

export default useShoppingMallVerificationProps;
