import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, ButtonProps, Stack, StackProps } from '@mui/material';
import CloseIcon from '@/public/icons/close_black.svg';
import ArrowIcon from '@/public/icons/arrow.svg';

export const MyMenuContentWrapper = styled(
  ({
    children,
    hasSidePadding,
    sx,
    ...props
  }: StackProps & { hasSidePadding: boolean }) => (
    <Stack
      {...props}
      sx={{
        width: '400px',
        height: '100vh',
        overflow: 'hidden auto',
        ...sx,
      }}
    >
      <Stack
        sx={{
          paddingX: hasSidePadding ? '20px' : 0,
          marginBottom: '80px',
        }}
      >
        {children}
      </Stack>
    </Stack>
  ),
)();

export const BackButton = styled(({ children, ...props }: ButtonProps) => (
  <Button {...props}>
    <ArrowIcon />
  </Button>
))(() => ({
  display: 'inline-block',
  width: '28px',
  height: '28px',
  minWidth: 'auto',
  padding: 0,
}));

export const CloseButton = styled(({ children, ...props }: ButtonProps) => (
  <Button {...props}>
    <CloseIcon />
  </Button>
))(() => ({
  display: 'inline-block',
  width: '28px',
  height: '28px',
  minWidth: 'auto',
  padding: 0,
}));

type MyMenuHeaderStackProps = {
  readonly onBack: () => void;
  readonly onClose: () => void;
  readonly title?: React.ReactNode;
  readonly hasBackButton: boolean;
} & StackProps;

export const MyMenuHeader = styled(
  ({
    onBack,
    onClose,
    children,
    title,
    hasBackButton,
    ...props
  }: MyMenuHeaderStackProps) => {
    return (
      <Stack
        direction={'row'}
        justifyContent={!children ? 'flex-end' : 'space-between'}
        alignItems={'center'}
        spacing={'4px'}
        {...props}
      >
        <>
          {hasBackButton && (
            <BackButton onClick={onBack} sx={{ marginRight: '8px' }} />
          )}
          <Box
            sx={{
              display: 'flex',
              flex: '1',
              fontSize: '16px',
              color: '#000',
              lineHeight: ' 24px',
            }}
          >
            {title}
          </Box>
        </>
        <CloseButton onClick={onClose} />
      </Stack>
    );
  },
)(() => ({ width: '100%', padding: '14px 20px' }));
