export const DateSelectorNormalizedStyle = {
  borderRadius: '8px',
  '& .MuiInputBase-root': {
    background: '#ffffff',
    height: '44px',
    boxSizing: 'border-box',
  },
  '.MuiOutlinedInput-notchedOutline': {
    top: 0,
    borderWidth: '1px',
    borderColor: '#E7E9EC',
    '> legend': {
      display: 'none',
    },
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000',
      borderWidth: '1px',
    },
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    padding: 0,
    margin: 0,

    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000 !important',
        borderWidth: '1px !important',
      },
    },
  },
  '.MuiFormLabel-root': {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '44px',
    transform: 'none',
    margin: '0 0 0 16px',
    padding: 0,
    color: '#B7BBC2',
    '&.MuiInputLabel-shrink': {
      display: 'none',
    },
  },
  '& .MuiInputAdornment-root': {
    width: '20px',
    height: '20px',
    margin: '0 16px 0 0',
    padding: 0,

    '.MuiButtonBase-root': {
      padding: 0,
      margin: 0,
      width: '100%',
      height: '100%',
    },
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    padding: '12px 0 12px 16px',

    '&::placeholder': {
      color: 'transparent',
    },
  },
};
