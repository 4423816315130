import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import { Tab, Tabs } from '@mui/material';
import Home from '@/components/Home';
import Approval from '@/components/Approval';
import Social from '@/components/Social';
import NewHome from '@/components/Home/V2';

const DefaultTab = 'home';

const GnbTab = () => {
  const router = useRouter();

  const TabMenu = useMemo(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.hostname === 'portal.fnf.co.kr'
    ) {
      return [
        { value: 'HOME', url: DefaultTab, selectedPath: [] },
        {
          value: 'APPROVAL',
          url: 'approval',
          selectedPath: [/^\/approval\/viewer/],
        },
        { value: 'SOCIAL', url: 'social', selectedPath: [/^\/board/] },
      ];
    }

    return [
      { value: 'HOME', url: DefaultTab, selectedPath: [] },
      {
        value: 'APPROVAL',
        url: 'approval',
        selectedPath: [/^\/approval\/viewer/],
      },
      { value: 'SOCIAL', url: 'social', selectedPath: [/^\/board/] },
      { value: 'NEWHOME', url: 'newhome', selectedPath: [/^\/newhome/] },
    ];
  }, []);

  const { pathname, query } = router;

  const activeTab = TabMenu?.findIndex?.(({ value, selectedPath }) => {
    const matchesPath = selectedPath.some((regex) => regex.test(pathname));

    const isHome =
      pathname === '/' &&
      value.toLowerCase() ===
        ((query.tab as string) ?? DefaultTab).toLowerCase();

    return matchesPath || isHome;
  });

  return (
    <Tabs
      value={activeTab}
      aria-label="navigation tab"
      role={'navigation'}
      centered
      sx={{
        marginTop: '4px',
        '& .MuiTabs-indicator': {
          display: 'none',
        },
      }}
    >
      {TabMenu.map(({ value }, index) => (
        <Tab
          key={value}
          label={value}
          sx={{
            minWidth: 'auto',
            minHeight: '40px',
            height: '40px',
            fontWeight: 500,
            fontSize: '20px',
            color: '#B7BBC2',
            verticalAlign: 'top',
            padding: 0,
            '&:not(:first-of-type)': {
              marginLeft: '32px',
            },
            '&.Mui-selected': {
              color: 'black',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: '0',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '6px',
                height: '6px',
                backgroundColor: '#000000',
                borderRadius: '50%',
              },
            },
          }}
          onClick={() => {
            if (index === 0) {
              router.push('/', undefined, {
                shallow: true,
              });
            } else {
              router.push(
                {
                  pathname: '/',
                  query: {
                    tab: TabMenu[index].url,
                  },
                },
                undefined,
                {
                  shallow: true,
                },
              );
            }
          }}
          disableRipple
        />
      ))}
    </Tabs>
  );
};

export default GnbTab;
