import styled from '@emotion/styled';

const LoadingScreenContainer = styled.div<{
  opacity: number;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: ${({ opacity }) => opacity};
  transition: opacity 900ms ease-in-out;
  z-index: 10000;
`;
const LottieContainer = styled.div`
  display: flex;
  width: 300px;
  height: 300px;
  align-items: center;
  justify-content: center;
`;

export { LoadingScreenContainer, LottieContainer };
