import { FormEvent, useState } from 'react';
import useToast from '@/hooks/useToast';
import usePasswordForm, { PasswordForm } from './usePasswordForm';
import { useChangePassWord } from '@/hooks/apiHooks';
import { AxiosError } from 'axios';
import { SubContentProps } from 'components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSetting';

const usePasswordSettingProps = (props: SubContentProps) => {
  const methods = usePasswordForm();
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

  const { showSuccess, showError } = useToast();

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const handleFormOnSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(async (data: PasswordForm) => {
      try {
        setIsFormSubmitting(true);
        await useChangePassWord(data);
        showSuccess({ message: '비밀번호가 변경되었습니다.' });
        reset();
      } catch (e) {
        const defaultMessage = '비밀번호 변경에 실패했습니다.';
        if (e instanceof AxiosError) {
          showError({
            message: e?.response?.data?.message ?? defaultMessage,
          });
        } else {
          showError({
            message: defaultMessage,
          });
        }
      } finally {
        setIsFormSubmitting(false);
      }
    })();
  };

  return {
    formProps: { methods, onSubmit: handleFormOnSubmit, id: 'mypage-password' },
    passwordSettingLink: MSPasswordLink,
    isSubmitButtonDisabled: !isValid || isFormSubmitting,
    isLoading: isFormSubmitting,
  };
};

export default usePasswordSettingProps;

export const MSPasswordLink =
  'https://account.activedirectory.windowsazure.com/ChangePassword.aspx';
