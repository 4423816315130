import React from 'react';
import { Button, Tooltip } from '@mui/material';
import Popper, { PopperProps } from 'components/@IntranetLibrary/Popper';
import { ProfileCardPopperZIndex } from '@/constant/z-index';
import LinkIcon from '@/public/icons/link2.svg';
import { copyClipBoardText } from '@/utils';
import process from 'process';
import useToast from '@/hooks/useToast';
import { useFindPassport } from '@/hooks/apiHooks';
import ProfileCardContent, {
  ProfileCardViewType,
} from 'components/@IntranetLibrary/ProfileCard/ProfileCardCont';

type ProfileCardPopperProps = {
  passportId: string;
} & Pick<PopperProps, 'anchorEl' | 'isClickAwayClose' | 'onClose'>;

const ProfileCardPopper = (props: ProfileCardPopperProps) => {
  const { passportId, ...popperProps } = props;
  const { data, isLoading } = useFindPassport(passportId);
  const toast = useToast();

  const copyProfileCardLink = async () => {
    if (!data?.passportId) return;
    const resultCopy = await copyClipBoardText(
      `${process.env.NEXT_PUBLIC_ORIGIN_URL}?search=${data.passportId}`,
    );
    if (resultCopy) {
      toast.show({
        severity: 'success',
        message: '프로필 카드 링크가 복사되었습니다.',
        duration: 3000,
      });
    }
  };

  return (
    <Popper
      title={'프로필 카드'}
      zIndex={ProfileCardPopperZIndex}
      additionalButton={
        <Tooltip
          title={'프로필 카드 링크 복사'}
          placement="top"
          arrow
          slotProps={{
            tooltip: { sx: { backgroundColor: 'black', color: 'white' } },
            arrow: { sx: { color: 'black' } },
            popper: {
              modifiers: [{ name: 'offset', options: { offset: [0, -8] } }],
            },
          }}
        >
          <Button onClick={copyProfileCardLink}>
            <LinkIcon width={'24px'} height={'24px'} />
          </Button>
        </Tooltip>
      }
      sx={{ width: '320px' }}
      {...popperProps}
    >
      <ProfileCardContent
        isLoading={isLoading}
        data={data}
        view={ProfileCardViewType.SIMPLE}
      />
    </Popper>
  );
};

export default ProfileCardPopper;
