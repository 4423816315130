import { styled } from '@mui/material/styles';
import React from 'react';
import { ListItem as MUIListItem, ListItemProps } from '@mui/material';

export const ListItem = styled((props: ListItemProps) => (
  <MUIListItem {...props} />
))(() => ({
  display: 'list-item',
  padding: 0,
  margin: 0,
}));
