import {
  useNewApprovalConfirmMe,
  useNewApprovalUnreadReferee,
  useNewMyApproval,
} from '@/hooks/legacyApiHooks';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';

const useApprovalData = () => {
  const { passportId } = useRecoilValue(MyInfoState);
  const {
    data,
    isLoading: isNewApprovalConfirmMeLoading,
    mutate: isNewApprovalConfirmMeMutate,
  } = useNewApprovalConfirmMe(passportId);
  const {
    data: myApprovalData,
    isLoading: isNewMyApprovalLoading,
    mutate: isNewMyApprovalMutate,
  } = useNewMyApproval(passportId);
  const {
    data: unreadData,
    isLoading: isNewApprovalUnreadRefereeLoading,
    mutate: isNewApprovalUnreadRefereeMutate,
  } = useNewApprovalUnreadReferee(passportId);

  const isLoading =
    isNewApprovalConfirmMeLoading ||
    isNewMyApprovalLoading ||
    isNewApprovalUnreadRefereeLoading;

  const dataItemChange = (id: string, changes: Record<string, any>) => {
    //H 보류, C 승인, R 거절, P 상태 전
    isNewApprovalConfirmMeMutate((currentData: any) => {
      if (!currentData) return currentData;
      const newData = currentData?.data.filter(
        (item: any) => item.docId !== id,
      );
      return {
        data:
          changes['status'] === 'C' || changes['status'] === 'R'
            ? newData
            : currentData?.data.map((item: any) =>
                item.docId == id ? { ...item, ...changes } : item,
              ),
        meta: {
          ...currentData?.meta,
          totalCount:
            changes['status'] === 'C' || changes['status'] === 'R'
              ? newData.length
              : currentData?.meta.totalCount,
        },
      };
    }, false);

    isNewMyApprovalMutate((currentData: any) => {
      if (!currentData) return currentData;
      const newData = currentData?.data.filter(
        (item: any) => item.docId !== id,
      );
      return {
        data:
          changes['status'] === 'C' || changes['status'] === 'R'
            ? newData
            : currentData?.data.map((item: any) =>
                item.docId == id ? { ...item, ...changes } : item,
              ),
        meta: {
          ...currentData?.meta,
          totalCount:
            changes['status'] === 'C' || changes['status'] === 'R'
              ? newData.length
              : currentData?.meta.totalCount,
        },
      };
    }, false);

    isNewApprovalUnreadRefereeMutate((currentData: any) => {
      if (!currentData) return currentData;
      const newData = currentData?.data.filter(
        (item: any) => item.docId !== id,
      );
      return {
        data: changes['read'] === 1 ? newData : currentData?.data,
        meta: {
          ...currentData?.meta,
          totalCount:
            changes['read'] === 1
              ? newData.length
              : currentData?.meta.totalCount,
        },
      };
    }, false);
  };
  return {
    data,
    isNewApprovalConfirmMeMutate,
    myApprovalData,
    isNewMyApprovalMutate,
    unreadData,
    isNewApprovalUnreadRefereeMutate,
    isLoading,
    dataItemChange,
    update: async (type: 'approval' | 'my' | 'unread') => {
      if (type === 'approval') {
        await isNewApprovalConfirmMeMutate();
      } else if (type === 'my') {
        await isNewMyApprovalMutate();
      } else if (type === 'unread') {
        await isNewApprovalUnreadRefereeMutate();
      }
    },
  };
};

export default useApprovalData;
