import { LanguageSettingContentProps } from 'components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSetting';
import React, { useState } from 'react';
import { Language, LanguageValue, MyInfo } from '@/types/api';
import { useDialog } from '@/hooks/useDialog';
import { useChangeLanguage } from '@/hooks/apiHooks';
import { RnMessage, sendToRN } from '@/utils/function';
import { useRouter } from 'next/router';

const useLanguageSettingContentProps = ({
  myInfo: { language = 'ko' },
}: {
  myInfo: MyInfo;
}): LanguageSettingContentProps => {
  const router = useRouter();
  const dialog = useDialog();

  const [selectedLanguage, setSelectedLanguage] =
    useState<LanguageValue>(language);

  const [isLoading, setIsLoading] = useState(false);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value as LanguageValue;
    setSelectedLanguage(value);
  };

  const handleChange = () => {
    dialog.show({
      message: `언어 설정을 ‘${Language[selectedLanguage]}’로 변경하시겠습니까?`,
      cancelButtonText: '취소',
      confirmButtonText: '변경하기',
      onConfirm: async () => {
        setIsLoading(true);
        try {
          await useChangeLanguage(selectedLanguage);
          if (window.isRNWebview) {
            sendToRN(RnMessage.RESTART);
          } else {
            router.reload();
          }
        } catch (e) {
          dialog.show({
            message: '에러가 발생했습니다',
          });
        } finally {
          setIsLoading(false);
        }
      },
    });
  };

  return {
    language,
    selectedLanguage,
    selectOptions: Object.keys(Language)
      .filter((item) => item !== 'default')
      .map((item) => ({
        value: item,
        label: Language[item as LanguageValue],
      })),
    handleSelect,
    changeButtonOnClick: handleChange,
    isChangeButtonDisabled: selectedLanguage === language,
    isLoading,
  };
};

export default useLanguageSettingContentProps;
