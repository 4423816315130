import { atom } from 'recoil';
import {
  AppConfig,
  ApprovalFilter,
  ApprovalState,
  ApprovalTempFilter,
  FavoriteList,
  MyInfo,
  NotiState,
  OpenState,
  SalesData,
  SocialLayoutState,
  SocialState,
  VersionInfo,
  WebApprovalState,
} from '@/types/api';
import {
  INIT_APP_SETTINGS,
  INIT_APPROVAL_STATE,
  INIT_MY_INFO,
  INIT_NOTI_STATE,
  INIT_SALES_DATA,
  INIT_SALES_OPEN_STATE,
  INIT_SOCIAL_LAYOUT_STATE,
  INIT_SOCIAL_STATE,
  INIT_VERSION_INFO,
  INIT_WEB_APPROVAL_FILTER_STATE,
  INIT_WEB_APPROVAL_STATE,
  INIT_WEB_APPROVAL_TEMP_FILTER_STATE,
} from '@/const/initData';
import { recoilPersist } from 'recoil-persist';

const sessionStorage =
  typeof window !== 'undefined' ? window.sessionStorage : undefined;
const localStorage =
  typeof window !== 'undefined' ? window.localStorage : undefined;

const { persistAtom } = recoilPersist({
  key: 'localStorage',
  storage: localStorage,
});
export const MyInfoState = atom<MyInfo>({
  key: 'myInfoState',
  default: INIT_MY_INFO,
  effects_UNSTABLE: [persistAtom],
});

const { persistAtom: approvalData } = recoilPersist({
  key: 'approvalKey',
  storage: sessionStorage,
});

export const approvalState = atom<ApprovalState>({
  key: 'approvalState',
  default: INIT_APPROVAL_STATE,
  effects_UNSTABLE: [approvalData],
});

export const webApprovalState = atom<WebApprovalState>({
  key: 'webApprovalState',
  default: INIT_WEB_APPROVAL_STATE,
});

const { persistAtom: approvalFilterData } = recoilPersist({
  key: 'approvalFilterKey',
  storage: sessionStorage,
});

export const approvalFilter = atom<ApprovalFilter>({
  key: 'approvalFilterState',
  default: INIT_WEB_APPROVAL_FILTER_STATE,
  effects_UNSTABLE: [approvalFilterData],
});
export const INIT_FILTER = {
  ...INIT_WEB_APPROVAL_FILTER_STATE,
  id: 1,
  endAuthoredAt: [],
};

export const approvalTempFilter = atom<ApprovalTempFilter>({
  key: 'approvalTempFilterState',
  default: INIT_WEB_APPROVAL_TEMP_FILTER_STATE,
});

export const initTempFilter = (
  filters: ApprovalFilter | FavoriteList,
): ApprovalTempFilter => ({
  types: filters.types,
  authorPassportIds: filters.authorPassportIds,
  approverPassportIds: filters.approverPassportIds,
  currentApproverPassportIds: filters.currentApproverPassportIds,
  coApproverPassportIds: filters.coApproverPassportIds,
  finalApproverPassportIds: filters.finalApproverPassportIds,
});

export const swipeState = atom({ key: 'swipeState', default: true });

const { persistAtom: appSettingsData } = recoilPersist({
  key: 'appConfigKey',
  storage: localStorage,
});

export const appSettingState = atom<AppConfig>({
  key: 'appConfigState',
  default: INIT_APP_SETTINGS,
  effects_UNSTABLE: [appSettingsData],
});

export const salesDataState = atom<SalesData>({
  key: 'saleDataState',
  default: INIT_SALES_DATA,
});

export const appVersionState = atom<VersionInfo>({
  key: 'versionInfoState',
  default: INIT_VERSION_INFO,
});

export const salesOpenState = atom<OpenState>({
  key: 'saleOpenState',
  default: INIT_SALES_OPEN_STATE,
});

export const socialState = atom<SocialState>({
  key: 'socialState',
  default: INIT_SOCIAL_STATE,
});

export const notiState = atom<NotiState>({
  key: 'notiState',
  default: INIT_NOTI_STATE,
});

export const socialLayoutState = atom<SocialLayoutState>({
  key: 'socialLayoutState',
  default: INIT_SOCIAL_LAYOUT_STATE,
});
