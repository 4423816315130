import useSWR, { SWRConfiguration } from 'swr';

import {
  APPROVAL_AUTHOR_ME_URL,
  APPROVAL_UNREADREFEEREE_ME_URL,
  FINSTA_FEEDBACK,
  FINSTA_OKR,
  SA_APPROVAL_REJECT_CARD,
  SA_APPROVAL_REJECT_TAX,
  SA_HOLIDAY,
  SA_NO_APPROVAL,
  SA_NO_PROGRESS_CARD,
  SA_NO_PROGRESS_TAX,
} from '@/const/legacyApiList';
import { Approval, Finstagram, SaData } from '@/types/legacyApi';
import { getNestedDataFetcher, getNestedMetaDataFetcher } from '@/utils/apis';
import {
  LEGACY_APPROVAL_REFERRER_URL,
  LEGACY_APPROVAL_SEARCH_URL,
} from '@/const/apiList';

type ApprovalPayload = {
  passportId: string;
  currPage?: number;
  listSize?: number;
};

export const useApprovalAuthorByMe = (
  payload?: ApprovalPayload,
  config?: SWRConfiguration,
) => {
  const params = new URLSearchParams({
    listSize: `${payload?.listSize ?? '5'}`,
    currentPage: `${payload?.currPage ?? '1'}`,
    sortType: 'authoredAt',
    orderType: 'desc',
    authorPassportIds: `${payload?.passportId ?? ''}`,
  });

  return useSWR<Approval>(
    payload ? `${LEGACY_APPROVAL_SEARCH_URL}?${params.toString()}` : null,
    getNestedMetaDataFetcher,
    config,
  );
};

export const useApprovalConfirmByMe = (
  payload?: ApprovalPayload,
  config?: SWRConfiguration,
) => {
  const params = new URLSearchParams({
    currentApproverPassportIds: payload?.passportId ?? '',
    listSize: `${payload?.listSize ?? '5'}`,
    currentPage: `${payload?.currPage ?? '1'}`,
  });

  return useSWR<Approval>(
    payload ? `${LEGACY_APPROVAL_SEARCH_URL}?${params.toString()}` : null,
    getNestedMetaDataFetcher,
    config,
  );
};

export const useApprovalUnreadByRefereeMe = (
  payload?: ApprovalPayload,
  config?: SWRConfiguration,
) => {
  const params = new URLSearchParams({
    sortType: 'authoredAt',
    orderType: 'desc',
    isUnread: `true`,
  });

  return useSWR<Approval>(
    payload ? `${LEGACY_APPROVAL_REFERRER_URL}?${params.toString()}` : null,
    getNestedMetaDataFetcher,
    config,
  );
};

type ApprovalParam = {
  authorId?: string;
  confirmId?: string;
  status: string;
};

export const useApprovalAuthorMe = (obj: Pick<ApprovalParam, 'status'>) => {
  const params = new URLSearchParams(obj);
  return useSWR<Approval>(
    `${APPROVAL_AUTHOR_ME_URL}?${params.toString()}`,
    getNestedMetaDataFetcher,
  );
};

export const useNewApprovalConfirmMe = (passportId: string) =>
  useSWR<Approval>(
    passportId
      ? `${LEGACY_APPROVAL_SEARCH_URL}?currentApproverPassportIds=${passportId}`
      : null,
    getNestedMetaDataFetcher,
  );

export const useApprovalUnreadReferee = (
  obj: Pick<ApprovalParam, 'status'>,
) => {
  const params = new URLSearchParams(obj);
  return useSWR<Approval>(
    `${APPROVAL_UNREADREFEEREE_ME_URL}?${params.toString()}`,
    getNestedMetaDataFetcher,
    { revalidateOnFocus: false },
  );
};

export const useNewApprovalUnreadReferee = (passportId: string) =>
  useSWR<Approval>(
    passportId
      ? `${LEGACY_APPROVAL_SEARCH_URL}?referrerPassportIds=${passportId}&unreadPassportIds=${passportId}`
      : null,
    getNestedMetaDataFetcher,
  );

export const useNewMyApproval = (passportId: string) =>
  useSWR<Approval>(
    passportId
      ? `${LEGACY_APPROVAL_SEARCH_URL}?authorPassportIds=${passportId}&statuses=P`
      : null,
    getNestedMetaDataFetcher,
  );
export const useNewMyApprovalEnd = (
  passportId: string,
  startLatestApprovedAt: string,
) =>
  useSWR<Approval>(
    passportId
      ? `${LEGACY_APPROVAL_SEARCH_URL}?authorPassportIds=${passportId}&statuses=R,C,H&startLatestApprovedAt=${startLatestApprovedAt}`
      : null,
    getNestedMetaDataFetcher,
  );

export const useSaHoliday = () =>
  useSWR<SaData>(SA_HOLIDAY, getNestedDataFetcher);

export const useSaNoProgressTax = () =>
  useSWR<number>(SA_NO_PROGRESS_TAX, getNestedDataFetcher);
export const useSaNoProgressCard = () =>
  useSWR<number>(SA_NO_PROGRESS_CARD, getNestedDataFetcher);

export const useSaNoApproval = () =>
  useSWR<number>(SA_NO_APPROVAL, getNestedDataFetcher);

export const useSaApprovalRejectTax = () =>
  useSWR<number>(SA_APPROVAL_REJECT_TAX, getNestedDataFetcher);
export const useSaApprovalRejectCard = () =>
  useSWR<number>(SA_APPROVAL_REJECT_CARD, getNestedDataFetcher);

export const useFinstaFeedBack = () =>
  useSWR<Finstagram>(FINSTA_FEEDBACK, getNestedDataFetcher);

export const useFinstaOkr = () =>
  useSWR<Finstagram>(FINSTA_OKR, getNestedDataFetcher);
