import React, { useCallback } from 'react';

function usePopper<HTMLElementType extends HTMLElement>(
  handleOpen?: (element: HTMLElementType) => void,
  handleClose?: () => void,
) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElementType | null>(null);

  const openPopper = useCallback(
    (event: React.MouseEvent<HTMLElementType>) => {
      const el = event.currentTarget as HTMLElementType;
      setAnchorEl(el);
      handleOpen?.(el);
    },
    [handleOpen],
  );

  const closePopper = useCallback(() => {
    setAnchorEl(null);
    handleClose?.();
  }, [handleClose]);

  return {
    anchorEl,
    openPopper,
    closePopper,
  };
}

export default usePopper;
