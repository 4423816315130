import React from 'react';
import process from 'process';
import {
  Box,
  BoxProps,
  Button,
  Chip,
  Divider,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import {
  BoardDetailHeaderSx,
  BoardDetailMenu,
  BoardDetailSubText,
} from '@/components/Board/BoardDetail/styles';
import { PageURL } from '@/constant/pageURL';
import { copyClipBoardText } from '@/utils';
import { useReactToPrint } from 'react-to-print';
import EyeIcon from '@/public/icons/eye/eye_show_24.svg';
import CommentIcon from '@/public/icons/comment.svg';
import ReadMoreIcon from '@/public/images/icon/read_more_24.svg';
import LinkIcon from '@/public/icons/link2.svg';
import PrintIcon from '@/public/images/icon/print_20.svg';
import useToast from '@/hooks/useToast';
import { ActionType } from '@/constant/button';
import { BoardDetailData } from '@/types/board';
import { BoardDetailHeaderId } from '@/components/Board/BoardDetailModal/BoardDetailModal';
import classNames from 'classnames';
import { ImportantBadgeIcon } from '@/theme/overrides/CustomIcons';
import { formatFullDateString } from '@/utils/date';
import BookmarkToggleButton from '@/components/BookmarkToggleButton';
import ProfileCardPopper from '@/components/@IntranetLibrary/ProfileCard/ProfileCardPopper';
import usePopper from '@/hooks/usePopper';
import StackWithDivider from '@/components/StackWithDivider';

type BoardDetailHeaderProps = {
  isMobile: boolean;
  isModal: boolean;
  id?: string;
  commentCount: number;
  isCompact?: boolean;
  disabled?: boolean;
  onClickComment: () => void;
  onAction: (action: ActionType, params?: any) => void;
  data: BoardDetailData;
  isBookmark: boolean;
} & BoxProps;

const BoardDetailHeader = (_props: BoardDetailHeaderProps) => {
  const {
    isMobile,
    isModal,
    id = BoardDetailHeaderId,
    commentCount,
    isCompact = false,
    disabled,
    onClickComment,
    onAction,
    data,
    isBookmark,
    sx,
    ...props
  } = _props;

  const {
    subject,
    id: documentId,
    categories,
    watchCount,
    createdBy,
    config,
  } = data;

  const { isImportant, isReserved, isDeleteEnable, displayStartedAt } = config;
  const now = new Date();
  const startDate = new Date(displayStartedAt);
  const createdAt = formatFullDateString(startDate);

  const { anchorEl, openPopper, closePopper } = usePopper();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const isMenuOpen = Boolean(menuAnchorEl);
  const toast = useToast();
  const print = useReactToPrint({
    content: () => document.getElementById('board-content'),
  });

  const closeMenu = () => setMenuAnchorEl(null);
  const clickMenu = async (action: ActionType) => {
    if (disabled) return;

    switch (action) {
      case ActionType.LINK_COPY: {
        const resultCopy = await copyClipBoardText(
          `${process.env.NEXT_PUBLIC_ORIGIN_URL}${PageURL.BOARD_DETAIL(
            documentId,
          )}`,
        );
        if (resultCopy) {
          toast.showSuccess({ message: '링크가 복사되었습니다.' });
        }
        break;
      }
      case ActionType.PRINT:
        print();
        break;
      case ActionType.DELETE:
      case ActionType.EDIT:
        onAction(action);
        break;
    }
    // 메뉴 닫기
    setMenuAnchorEl(null);
  };

  return (
    <Box
      id={id}
      component={'header'}
      className={classNames({ isCompact, isMobile, isModal })}
      sx={{ ...BoardDetailHeaderSx, ...sx }}
      {...props}
    >
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        rowGap={'4px'}
        columnGap={'8px'}
        className={'board-category'}
      >
        {isReserved && startDate > now && (
          <Chip
            key={`category_isReserved`}
            variant={'outlined'}
            color={'primary'}
            label={'예약게시글'}
          />
        )}
        {isImportant && (
          <Chip
            key={`category_isImportant`}
            variant={'filled'}
            color={'primary'}
            label={
              <Stack alignItems={'center'} direction={'row'}>
                <ImportantBadgeIcon style={{ marginLeft: '-2px' }} />
                {'중요'}
              </Stack>
            }
          />
        )}
        {categories.map((item, index) => (
          <Chip
            key={`category${item.id}_${index}`}
            variant={'outlined'}
            color={'default'}
            label={item.name}
          />
        ))}
      </Stack>
      <Typography component={'h1'} className={'board-title'}>
        {subject}
      </Typography>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        marginTop={'12px'}
      >
        <StackWithDivider
          direction={'row'}
          alignItems={'center'}
          spacing={'12px'}
          dividerProps={{
            orientation: 'vertical',
            variant: 'middle',
            flexItem: true,
            sx: {
              display: isMobile ? 'none' : 'inline-block',
              height: '16px',
              alignSelf: 'auto',
            },
          }}
        >
          <Typography
            fontSize={isMobile ? '12px' : '16px'}
            color={'black'}
            sx={{
              cursor: 'pointer',
              span: {
                display: 'inline-block',
                marginLeft: '8px',
                color: '#888D96',
              },
            }}
            onClick={openPopper}
          >
            {createdBy.name} {createdBy.positionName}
            <span>{createdBy.departmentName}</span>
          </Typography>
          <BoardDetailSubText className={'board-createAt'}>
            {createdAt}
          </BoardDetailSubText>
        </StackWithDivider>

        <Stack direction={'row'} alignItems={'center'} spacing={'8px'}>
          {/* 조회수 */}
          <Stack direction={'row'} alignItems={'center'} spacing={'2px'}>
            <EyeIcon width={'24px'} height={'24px'} />
            <BoardDetailSubText fontSize={isMobile ? '12px' : '14px'}>
              {watchCount.toLocaleString()}
            </BoardDetailSubText>
          </Stack>

          {/* 댓글 수 */}
          <Button
            disabled={disabled}
            onClick={onClickComment}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '2px',
              padding: '0 4px 0 0',
              '&:hover': { backgroundColor: '#F3F5F6' },
            }}
          >
            <CommentIcon width={'24px'} height={'24px'} />
            <BoardDetailSubText fontSize={isMobile ? '12px' : '14px'}>
              {commentCount.toLocaleString()}
            </BoardDetailSubText>
          </Button>

          {/* 북마크 */}
          <BookmarkToggleButton
            isChecked={isBookmark}
            onChange={() => onAction(ActionType.BOOKMARK)}
            style={{ width: '24px' }}
          />

          {/* 메뉴 버튼 */}
          <Button
            id={'menu-button'}
            aria-controls={isMenuOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isMenuOpen ? 'true' : undefined}
            disabled={disabled}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setMenuAnchorEl(event.currentTarget);
            }}
            sx={{ padding: 0, minWidth: '24px' }}
          >
            <ReadMoreIcon />
          </Button>
          {/* 메뉴 */}
          <BoardDetailMenu
            id={'menu'}
            anchorEl={menuAnchorEl}
            open={disabled ? false : isMenuOpen}
            onClose={closeMenu}
            MenuListProps={{ 'aria-labelledby': 'menu-button' }}
          >
            <MenuItem
              data-menu-type={ActionType.LINK_COPY}
              onClick={() => clickMenu(ActionType.LINK_COPY)}
            >
              링크 복사
              <LinkIcon width={'20px'} height={'20px'} />
            </MenuItem>
            <MenuItem
              data-menu-type={ActionType.PRINT}
              onClick={() => clickMenu(ActionType.PRINT)}
            >
              인쇄
              <PrintIcon />
            </MenuItem>
            <MenuItem
              data-menu-type={ActionType.EDIT}
              onClick={() => clickMenu(ActionType.EDIT)}
              sx={{ display: isDeleteEnable ? undefined : 'none' }}
            >
              수정
            </MenuItem>
            <MenuItem
              data-menu-type={ActionType.DELETE}
              onClick={() => clickMenu(ActionType.DELETE)}
              sx={{ display: isDeleteEnable ? undefined : 'none' }}
            >
              삭제
            </MenuItem>
          </BoardDetailMenu>
        </Stack>
      </Stack>

      <ProfileCardPopper
        anchorEl={anchorEl}
        passportId={createdBy.passportId}
        onClose={closePopper}
      />
    </Box>
  );
};

export default BoardDetailHeader;
