import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';

const MasonryItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isAppMainBanner',
})<{ isAppMainBanner: boolean | undefined }>(({ theme, isAppMainBanner }) => ({
  backgroundColor: isAppMainBanner ? 'transparent' : '#fff',
  borderRadius: isAppMainBanner ? '0' : '8px',
  border: isAppMainBanner ? 'none' : '1px solid #E7E9EC',
  cursor: 'pointer',
  textAlign: 'left',
  '&:hover': {
    backgroundColor: isAppMainBanner ? 'transparent' : '#F9FAFB',
  },
}));

const MasonryItemSkeleton = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  border: '1px solid #E7E9EC',
  cursor: 'pointer',
  textAlign: 'left',
  '&:hover': {
    backgroundColor: '#F9FAFB',
  },
  overflow: 'hidden',
}));

const CategoryTable = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  color: '#888D96',
}));

const MainBannerTitle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isApp',
})<{ isApp: boolean | undefined }>(({ theme, isApp }) => ({
  fontSize: isApp ? '20px' : '24px',
  fontWeight: isApp ? 700 : 500,
  lineHeight: isApp ? '28px' : '26px',
  textAlign: 'center',
  color: '#000',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: isApp ? 2 : 1,
  WebkitBoxOrient: 'vertical',
}));

const Line = styled(Box)(({ theme }) => ({
  height: '1px',
  borderTop: '1px solid #E7E9EC',
  width: '100%',
}));

const Title = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  color: '#888D96',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',

  '&.table': {
    WebkitLineClamp: 1,
  },

  '& mark': {
    backgroundColor: 'rgba(0, 102, 204, 0.10)',
  },

  '&.isNotRead': {
    color: '#000',
  },

  // '&.isNotRead::after': {
  //   content: '""',
  //   display: 'inline-block',
  //   width: '4px',
  //   height: '4px',
  //   borderRadius: '2px',
  //   background: '#F55060',
  //   marginLeft: '4px',
  //   marginTop: '6px',
  //   marginRight: '4px',
  //   verticalAlign: 'top',
  // },
}));

const Content = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: '#888D96',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '20px',
  height: '20px',
}));

const StickyWrapper = styled(Box)(({ theme }) => ({
  textAlign: 'right',
  position: 'sticky',
  zIndex: 1,
  top: '-1px',
  paddingTop: '13px',
  paddingBottom: '12px',
  marginTop: '36px',
  background: '#fff',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  borderBottom: '3px solid black',
}));

const RightStickyWrapper = styled(Box)(({ theme }) => ({
  textAlign: 'right',
  position: 'sticky',
  zIndex: 1,
  top: 0,
  background: 'rgb(243, 245, 246)',
}));

const SearchInputWrapper = styled(Box)(({ theme }) => ({
  width: '330px',
}));

const EtcMenuWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelect',
})<{
  isSelect?: boolean;
}>(({ theme, isSelect }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  caretColor: 'transparent',
  padding: '6px 0 6px 10px',
  borderRadius: '4px',
  background: isSelect ? 'rgba(0, 102, 204, 0.10)' : 'transparent',
  color: isSelect ? '#06C' : 'black',
}));

const CreatedByName = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'count',
})<{
  count: number;
}>(({ theme, count }) => ({
  fontSize: '14px',
  fontWeight: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  whiteSpace: 'nowrap', // 한 줄로 유지하면서 줄임표 처리
  flexShrink: count > 15 ? 1 : 0, // 공간이 부족할 때 크기 축소 허용
  minWidth: 0, // 부모 컨테이너 크기에 맞춰 동작
}));

const CreatedByDepartment = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'line',
})<{
  line: number;
}>(({ theme, line }) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: '#888D96',
  display: '-webkit-box',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  wordWrap: 'break-word',
  WebkitLineClamp: line,
  WebkitBoxOrient: 'vertical',
}));

const CreateCategoryButton = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  color: '#06C',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  marginTop: '12px',
  padding: '4px 8px',
  width: '100%',
  justifyContent: 'flex-start',
}));

export {
  MasonryItem,
  MasonryItemSkeleton,
  CategoryTable,
  MainBannerTitle,
  Title,
  Content,
  IconWrapper,
  StickyWrapper,
  RightStickyWrapper,
  SearchInputWrapper,
  EtcMenuWrapper,
  Line,
  CreatedByName,
  CreatedByDepartment,
  CreateCategoryButton,
};
