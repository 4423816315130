import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const SpinnerBox = styled(Box)<{ background: string; width: string }>`
  width: ${({ width }) => width};
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l13 1s infinite linear;
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);

    background:  ${({
      background,
    }) => `radial-gradient(farthest-side, ${background} 94%, #0000)
          top/3px 3px no-repeat,
        conic-gradient(#0000 30%, ${background});
    `};
  }

  @keyframes l13 {
    100% {
      transform: rotate(1turn);
    }
  }
`;

export { SpinnerBox };
