import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export type ProfileForm = {
  nickname: string;
  job: string;
  summary: string;
  content: string;
};

const defaultValues: ProfileForm = {
  nickname: '',
  job: '',
  summary: '',
  content: '',
};

const Schema = Yup.object().shape({
  nickname: Yup.string()
    .required('영어이름을 입력해주세요.')
    .transform((value) => value.trim())
    .matches(/^[a-zA-Z\s]*$/, '영어만 입력이 가능해요.')
    .matches(/^[a-z]*$/, '소문자만 사용할 수 있어요.')
    .max(15, '최대 15자까지 입력 가능해요.'),
  job: Yup.string().required('직무를 선택해주세요'),
  summary: Yup.string()
    .optional()
    .transform((value) => value.trim())
    .max(40, '최대 40자까지 입력 가능해요.'),
  content: Yup.string()
    .optional()
    .transform((value) => value.trim())
    .max(300, '최대 300자까지 입력 가능해요.'),
});

const useProfileForm = () => {
  return useForm<ProfileForm>({
    resolver: yupResolver(Schema),
    defaultValues,
    mode: 'onChange',
  });
};

export default useProfileForm;
