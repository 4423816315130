import React, { PropsWithChildren, useMemo } from 'react';
import { Tag, Wrapper } from './styles';
import ProfileCardPopper from '@/components/@IntranetLibrary/ProfileCard/ProfileCardPopper';
import usePopper from '@/hooks/usePopper';

const CommentMention: React.FC<PropsWithChildren<{ id: string }>> = ({
  id: passportId,
  children,
}) => {
  const { anchorEl, openPopper, closePopper } = usePopper<HTMLButtonElement>();

  return (
    <>
      <Tag onClick={openPopper}>{children}</Tag>
      <ProfileCardPopper
        passportId={passportId}
        anchorEl={anchorEl}
        onClose={closePopper}
      />
    </>
  );
};

type ContentWithMentionsProps = {
  readonly content: string;
};

const ContentWithMentions = (props: ContentWithMentionsProps) => {
  const content = props.content.replaceAll('\n', '<br />');

  return (
    <>
      {useMemo(() => {
        const parts = [];
        let lastIndex = 0;

        const mentionRegex =
          /<CommentMention data-id='(\w+)'>@([^<]+)<\/CommentMention>/g; //TODO: 하나로 합치기

        let match;
        while ((match = mentionRegex.exec(content)) !== null) {
          const [_, id, mentionText] = match;
          const startIndex = match.index;

          if (startIndex > lastIndex) {
            parts.push(
              <span
                key={`html-${lastIndex}`}
                dangerouslySetInnerHTML={{
                  __html: content.substring(lastIndex, startIndex),
                }}
              />,
            );
          }

          parts.push(
            <CommentMention key={`mention-${id}`} id={id}>
              @{mentionText}
            </CommentMention>,
          );

          lastIndex = mentionRegex.lastIndex;
        }

        if (lastIndex < content.length) {
          parts.push(
            <span
              key={`html-last`}
              dangerouslySetInnerHTML={{ __html: content.substring(lastIndex) }}
            />,
          );
        }

        return <Wrapper>{parts}</Wrapper>;
      }, [content])}
    </>
  );
};

export default ContentWithMentions;
