import { Box, Stack, styled } from '@mui/material';

export const ProfileContentWrapper = styled(Box)`
  .updateAt {
    margin: 24px 0 0;
    font-weight: 400;
    font-size: 12px;
    color: #888d96;
  }
`;

export const ContentsWrapper = styled(Box)`
  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #e7e9ec;
    .title {
      margin: 0;
      font-size: 14px;
      color: #888d96;
    }
    .content {
      margin: 0;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      button {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 0;
        font-size: 14px;
        text-decoration: underline;
        svg path {
          stroke: #888d96;
        }
      }
    }
  }
`;

export const JobDescriptionWrapper = styled(Stack)`
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
  h2 {
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    margin: 0;
  }
  p.summary {
    font-weight: 500;
    font-size: 16px;
    overflow-wrap: break-word;
  }
  p.content {
    font-weight: 400;
    font-size: 14px;
    word-break: break-all;
    white-space: pre-wrap;
  }
  p.empty {
    font-size: 12px;
    color: #b5b5c3;
  }
`;
