import React from 'react';
import { Divider, Skeleton, Stack } from '@mui/material';

import ContentDescription from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';
import useMailSignatureProps from './@hooks/useMailSignatureProps';
import { SubContentProps } from 'components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSetting';
import SignatureCard from './SignatureCard';
import SloganCard from './SignatureCard/SloganCard';
import Link from '@/components/@IntranetLibrary/Link';

const MarginTop = `${32 - 24}px`;

const MailSignature = (props: SubContentProps) => {
  const { signData, copyButtonOnClick, mailLink, signatureCards, showSlogan } =
    useMailSignatureProps(props);

  return (
    <>
      <ContentDescription
        hasVerticalMargin={true}
        sx={{
          marginBottom: '16px',
        }}
      >
        원하시는 서명을 아래에서 복사 후 Outlook에서 설정해 사용하세요.
      </ContentDescription>
      <Link
        size="large"
        variant={'secondary'}
        href={mailLink}
        target="_blank"
        sx={{
          '& img': {
            width: '20px',
            height: '20px',
            marginRight: '4px',
          },
        }}
      >
        <img src={'/icons/mypage/outlook.png'} alt={'outlook icon'} />
        Outlook에서 설정하기
      </Link>
      <Stack gap={'24px'} mt={'28px'}>
        <Divider />
        {signData ? (
          <>
            {signatureCards.map((item, index) => (
              <SignatureCard
                {...item}
                key={item.id}
                data={signData}
                copyButtonOnClick={copyButtonOnClick}
                wrapperStyle={{ marginTop: index > 0 ? MarginTop : 0 }}
              />
            ))}
            {showSlogan && (
              <SloganCard
                marginTop={MarginTop}
                copyButtonOnClick={copyButtonOnClick}
              />
            )}
          </>
        ) : (
          <>
            <Skeleton width={'100%'} height={'180px'} animation={'wave'} />
            <Skeleton
              width={'100%'}
              height={'180px'}
              animation={'wave'}
              sx={{ marginTop: MarginTop }}
            />
          </>
        )}
      </Stack>
    </>
  );
};

export default MailSignature;
