import { ItemDetailParams, ItemParams, SalesByStoreRanking } from '@/types/api';

export const MYINFO_URL = '/api/auth/apix/my';

export const JARDIN_IMAGE_URL =
  '/api/v1/jardin/findLatestJardinImageByType/{type}?type=jardin_menu';

export const JARDIN_GET_RESERVATION_URL = '/jardin/get-reservation';

export const BIRTHDAY_API_URL = '/api/v1/passport/birthday';

export const BOARD_BASE_API_URL = '/api/v0/board';

export const BOARD_LIKES_API_URL = '/likes';

export const BOARD_COMMENTS_API_URL = '/comments';

export const NEW_EMPLOYEE_INTERVIEWS_URL = '/api/v0/board/filter/newEmployees';

export const IMPORTANT_BOARD_URL = '/api/v0/board/filter/important';

export const BOARD_BASE_API_URL_V1 = '/api/v1/board';

export const BOARD_FEEDBACK_V1 = `${BOARD_BASE_API_URL_V1}/feedback`;

export const MY_RESERVATION_BOOK_URL = '/api/v0/book/list/my';

export const BOARD_URL = 'https://i.fnf.co.kr/bbs/V';

export const FIND_USER_BASE_URL = '/api/v1/user/findByUserId/';

export const UPDATE_LIKES_URL = `${BOARD_BASE_API_URL}/likes/like`;

export const BOARD_FILTER_URL = `${BOARD_BASE_API_URL}/filter`;

export const UPDATE_COMMENT_LIKE_URL = `${BOARD_BASE_API_URL}${BOARD_COMMENTS_API_URL}/like`;

export const AZURE_LOGIN_URL = `/api/auth/azure/login/url`;

export const AZURE_TOKEN_URL = '/api/auth/azure/token';

export const LOGIN_BY_AZURE_URL = '/api/auth/loginBy/msAzure';

export const LOGIN_BY_ISSUE_PORTAL_TOKEN_URL = '/api/auth/portal/token';

export const LINK_LIST_URL = `/api/v1/link/list/filter`;

export const SEARCH_API_URL = '/api/v2/user/users/search';

export const PASSPORT_BASE_URL = '/api/v1/passport';

export const SEARCH_PASSPORT_URL = `${PASSPORT_BASE_URL}/search`;

export const UPDATE_NICKNAME_URL =
  '/api/v1/portal-user/passport/default/nickname';

export const CHECK_NICKNAME_URL = '/api/v1/passport/nickname/check';
export const UPDATE_NICKNAME_URL_V2 = (id: string) =>
  `/api/v1/control/passport/${id}/nickname`;

export const SEARCH_POINT_URL = '/api/v1/user/findPointByEmployeeNumber';

export const SIGNATURE_URL = 'api/v1/user/getEmailSignature';

export const CHANGE_PASSWORD_URL = '/api/v1/user/updateUserPassword';

export const GET_CERTIFY_CODE_URL = '/api/auth/code/sms/createCodeAndSendBySms';

export const VERIFY_CERTIFY_CODE_URL =
  '/api/auth/code/sms/checkSmsVerificationCode';

export const PRINT_EMP_PROOF_URL =
  '/api/v1/company/hr/getEmploymentVerificationLetter';

export const PROOF_EMP_HISTORY_URL = '/api/v1/company/hr/getHrCart';

export const AUTH_SHOP_ID_URL = '/api/v1/user/authenticateEmployeeOnlineMall';

export const GET_AUTH_SHOP_INFO_URL = '/api/v1/user/getUserCustomerId';

export const CREATE_BOARD_URL = '/api/v0/board/create/creatableBoards';

export const BASE_BOOK_URL = '/api/v0/book';

export const NEW_BOOK_LIST_URL = `${BASE_BOOK_URL}/list/latest`;

export const NEW_BOOK_URL = `${BASE_BOOK_URL}/latest`;

export const BOOK_PERIOD_URL = '/api/v0/book/request/period';

export const SEND_EMAIL_URL = '/api/v1/email/sendByTemplate';

export const UNREAD_EMAIL_URL = '/api/v1/labs/msAzureClient/email/unreadCount';

export const I_READ_BOARD_URL = '/api/v0/board/readers/reader';

export const JIRA_ID_URL = '/api/v1/labs/jiraClient/accountId';

export const JIRA_ISSUE_URL = '/api/v1/labs/jiraClient/issues';

export const CREATE_COMMENT_URL = '/api/v0/board/comments';

export const NOTIFICATION_HISTORY_URL = '/api/v1/notificationCenter/history/my';

export const NOTIFICATION_TOPICS_URL = '/api/v1/notificationCenter/topics';

export const CHANGE_PROFILE_URL = '/api/v1/user/update/profileImage';

export const SET_TOKEN_URL = '/api/v1/notificationCenter/tokens/my/create';

export const GET_ACTIVATED_TOKEN_URL =
  '/api/v1/notificationCenter/tokens/my/activated';

export const MESSAGE_READ_URL = '/api/v1/notificationCenter/history/watch';

export const MESSAGE_ALL_READ_URL = `${MESSAGE_READ_URL}/my`;

export const MENU_URL = '/api/v1/menu';

export const PORTAL_PASSPORT_URL = '/api/v1/portal-user/me';

export const PATCH_PASSPORT_URL = '/api/v1/portal-user/passport/default';

export const FILE_UPLOAD_URL = '/api/v1/file/upload';

export const FILE_DOWNLOAD_URL = '/api/v1/file/download';

export const MY_NOTIFICATION_OPTION_LIST_URL =
  '/api/v1/notificationCenter/option/my';

export const MY_NOTIFICATION_PORTAL_OPTION_LIST_URL =
  '/api/v1/notificationCenter/portal/option/my';

export const MY_NOTIFICATION_OPTION_URL =
  '/api/v1/notificationCenter/option/update';

export const CHANGE_LANGUAGE_URL = '/api/v1/portal-user/personalization';

export const BASE_CALENDAR_URL = '/api/v1/calendar';

export const CHANGE_JOB_DESCRIPTION_URL = '/api/v1/passport/me/job';

export const JOB_LIST_URL = '/api/v1/passport/job';

export const ORGANIZATION_URL = '/api/v1/organization/relationship/all';

export const LEGACY_APPROVAL_ME = '/api/v1/legacy-approval/approver/me';

export const LEGACY_APPROVAL_AUTHOR_URL = '/api/v1/legacy-approval/author/me';

export const LEGACY_APPROVAL_REFERRER_URL =
  '/api/v1/legacy-approval/referrer/me';

export const LEGACY_APPROVAL_TEAM_AUTHOR_URL =
  '/api/v1/legacy-approval/author/my-department';

export const LEGACY_APPROVAL_TEAM_APPROVAL_URL =
  '/api/v1/legacy-approval/approver/my-department';

export const APPROVAL_TYPE_ALL_URL = '/api/v1/approval/type/all';

export const LEGACY_APPROVAL_SEARCH_URL = '/api/v1/legacy-approval';

export const COMBINED_DEPARTMENT_URL = '/api/v1/legacy-approval-department';

export const ORGANIZATION_BASE_URL = '/api/v1/passport/organization';

export const BASE_JARDIN_URL = '/api/v0/jardin';

export const SEASONS_URL = '/api/v1/sales/season';

export const SALES_MAIN_URL = (season: string) =>
  `/api/v1/sales/season/${season}/brand`;

export const ALL_STORE_SALES = (season: string, brand: string) =>
  `/api/v1/sales/season/${season}/brand/${brand}/shop`;

export const SHOP_INFO_URL = (
  season: string,
  brand: string,
  shopCode: string,
) => `/api/v1/sales/season/${season}/brand/${brand}/shop/${shopCode}`;

export const SALES_BY_TYPE_URL = (
  season: string,
  brand: string,
  shopCode: string,
) => `${SHOP_INFO_URL(season, brand, shopCode)}/type`;

export const SALES_BY_BEST_URL = (
  season: string,
  brand: string,
  shopCode: string,
) => `${SHOP_INFO_URL(season, brand, shopCode)}/best`;

export const ITEM_SALES_DETAIL_URL = ({
  season,
  brand,
  productCode,
  shopCode,
}: ItemDetailParams) =>
  `/api/v1/sales/season/${season}/brand/${brand}/shop/${shopCode}/product/${productCode}/best`;

export const BEST_SHOP_URL = ({
  season,
  brand,
  productCode,
  colorCode,
}: SalesByStoreRanking) =>
  `/api/v1/sales/season/${season}/brand/${brand}/shop/product/${productCode}/color/${colorCode}`;

export const RIVAL_RANK_URL = '/api/v1/sales/daily-rival-rank';

export const RIVAL_RANK_STANDARD_COMPANY_URL = (
  brand: string,
  shopCode?: string,
) =>
  `/api/v1/sales/daily-rival-rank/brand/${brand}${
    shopCode ? `/shop/${shopCode}` : ''
  }`;

export const RIVAL_RANK_STORE_URL = (brand: string) =>
  `/api/v1/sales/daily-rival-rank/brand/${brand}/shop`;

export const WEATHER_URL = '/api/v1/labs/dataOpenApi/weather/forecast';

export const SALES_ANALYSIS_URL = '/api/v1/sales/analysis/brand';

export const SALES_BY_CHANNEL_URL = (brand: string) =>
  `${SALES_ANALYSIS_URL}/${brand}/channel`;

export const SALES_BY_SEASON_URL = (brand: string) =>
  `${SALES_ANALYSIS_URL}/${brand}/season`;

export const SALES_BY_Item_URL = (brand: string) =>
  `${SALES_ANALYSIS_URL}/${brand}/apparel-and-accessories`;

export const PRODUCT_DETAIL_URL = ({
  season,
  brand,
  productCode,
  date,
}: ItemParams) =>
  `/api/v1/sales/season/${season}/brand/${brand}/product/${productCode}?date=${date}`;

export const PRODUCT_SALES_BY_COLORS_URL = ({
  season,
  brand,
  productCode,
  date,
}: ItemParams) =>
  `/api/v1/sales/season/${season}/brand/${brand}/product/${productCode}/color?date=${date}`;

export const PASSPORT_SYNCED_TIME_URL =
  '/api/v1/passport/issuer/synced-at/latest';

export const BRAND_PERMISSION_MISSING_URL = '/api/v1/sales/permissions/missing';

export const PING_URL = '/api/ping';

export const HISTORY_BASE_URL = '/api/v1/history';

export const SEARCH_EMP_HISTORY_URL = `${HISTORY_BASE_URL}/passport/quick-search`;
export const PASSPORT_SEARCH_URL = `${HISTORY_BASE_URL}/passport/keyword`;

export const DELETE_PASSPORT_URL = `${SEARCH_EMP_HISTORY_URL}/data`;

export const LEGACY_APPROVAL_RECENT_READ = `${HISTORY_BASE_URL}/approval/viewed/recently`;
export const APPROVAL_RECOMMEND_FILTER_URL = `${HISTORY_BASE_URL}/approval/filter/recommend`;
export const APPROVAL_RECENT_SEARCH_KEYWORD = `${HISTORY_BASE_URL}/approval/keyword`;

export const SEARCH_GPT_EMPLOYEE_URL = '/api/v1/passport/nl-search';

export const APPROVAL_FAVORITE_FILTER = `${LEGACY_APPROVAL_SEARCH_URL}/favorite-filter`;
