import {
  StyledContent,
  StyledContentLayout,
  StyledReadButton,
  StyledRowLayout,
  StyledSubContent,
} from '@/components/Alarm/NotificationHistoryItem/styles';
import { NotiHistoryData } from '@/types/api';
import { useMessageRead } from '@/hooks/apiHooks';
import { getIntranetDocUrl } from '@/utils/urls';
import React from 'react';

type P = {
  mobileMode?: boolean;
  handleReadClick(id: number): void;
  handleOpen(id: string): void;
};

const NotificationHistoryItem = ({
  title,
  body,
  watched,
  createdAt,
  link,
  data,
  id,
  mobileMode = false,
  handleReadClick,
  handleOpen,
}: NotiHistoryData & P) => {
  const read = watched === 1;
  const popToi = link.replace('//pop.', '//i.');

  const handleClick = () => {
    const isApproval = popToi.includes('approval');
    const docId = isApproval
      ? new URL(popToi).searchParams.get('doc') ?? ''
      : null;
    const isWebView = window?.isRNWebview;
    const hasMobileAction = isWebView && data?.mobileActionType;

    if (isWebView && hasMobileAction) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const url = isApproval ? getIntranetDocUrl(docId!, false, false) : link;
      const sendData = JSON.stringify({
        actionType: data.mobileActionType,
        tabName: data.tabName,
        url,
      });
      window?.ReactNativeWebView?.postMessage(sendData);
    } else if (isApproval && docId) {
      handleOpen(docId);
    } else {
      window.open(popToi, '_blank', 'noopener,noreferrer');
    }
    readMessage();
  };

  const readMessage = () => {
    useMessageRead(id.toString()).then(() => {
      handleReadClick(id);
    });
  };

  const handleRead = (e: React.MouseEvent<HTMLButtonElement>) => {
    readMessage();
    e?.stopPropagation();
  };

  return (
    <StyledRowLayout {...{ read, mobileMode }} onClick={handleClick}>
      <StyledContentLayout>
        <div style={{ fontWeight: '700' }}>{title}</div>
        <StyledContent>{body}</StyledContent>
        <StyledSubContent>
          <div>{createdAt}</div>
          <StyledReadButton onClick={(e) => handleRead(e)}>
            Mark as read
          </StyledReadButton>
        </StyledSubContent>
      </StyledContentLayout>
    </StyledRowLayout>
  );
};

export default NotificationHistoryItem;
