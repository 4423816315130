import React from 'react';
import {
  Box,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import LinkMenuIcon from '@/public/icons/link_menu.svg';
import { LoginInfoBoxSx } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/PayStub/style';
import { DataListGrid, DataListGridItem } from '@/components/DataListGrid';
import { SubContentProps } from 'components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSetting';
import ContentDescription, {
  ContentDivider,
} from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';
import Button from '@mui/material/Button';
import process from 'process';
import Link from '@/components/@IntranetLibrary/Link';
import ProfileCardPopper from '@/components/@IntranetLibrary/ProfileCard/ProfileCardPopper';
import usePopper from '@/hooks/usePopper';

const CompanyCodeList = [
  { name: 'F&F', code: 'Q623' },
  { name: 'F&F 홀딩스', code: 'Q526' },
  { name: 'F&F 로지스틱스', code: 'Q739' },
  { name: 'F&F 파트너스', code: 'Q597' },
  { name: 'F&F 엔터테인먼트', code: 'Q734' },
];

const isDev = process.env.NEXT_PUBLIC_API_URL?.includes('portal-backend-dev');
// 인사총무팀 박미정 대리(6549ee8546c21937d00af645) 고정 (2025년 승진!!)
const managerName = isDev ? 'Dev 박민지 담당' : '인사총무팀 박미정 대리';
const managerPassportId = isDev
  ? '6549f436794d98e9b2029ba4'
  : '6549ee8546c21937d00af645';

export const PayStubContent = ({ myInfo }: SubContentProps) => {
  const { email, employeeNumber, phoneMobile } = myInfo;

  const { anchorEl, openPopper, closePopper } = usePopper<HTMLElement>();

  return (
    <ContentDivider>
      <ContentDescription hasVerticalMargin={true}>
        급여명세서와 연말정산을 조회할 수 있습니다.
        <br />
        법인마다 사용 서비스가 다르니 아래 내용을 확인해 주세요.
      </ContentDescription>
      <Stack component={'section'}>
        <Box sx={LoginInfoBoxSx}>
          <h1>iPayView 로그인 정보</h1>
          <Table className={'companyCode'}>
            <TableHead>
              <TableRow>
                <TableCell>대상 회사명</TableCell>
                <TableCell align="right">코드</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {CompanyCodeList.map((company, index) => (
                <TableRow key={`company-${index}`}>
                  <TableCell>{company.name}</TableCell>
                  <TableCell align="right">{company.code}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <DataListGrid
            rowGap={'8px'}
            columnGap={'12px'}
            gridTemplateColumns={'80px 1fr'}
            sx={{ marginTop: '24px' }}
          >
            <DataListGridItem label={'사번'}>
              {employeeNumber || '포탈 마이페이지에서 확인'}
            </DataListGridItem>
            <DataListGridItem label={'휴대폰'}>
              {phoneMobile || '-'}
            </DataListGridItem>
            <DataListGridItem label={'비밀번호'}>
              iPayView에서 설정한 비밀번호
            </DataListGridItem>
          </DataListGrid>
        </Box>
        <Link
          href={'https://www.ipayview.com/'}
          target={'_blank'}
          size={'large'}
          rel={'noreferrer'}
          variant={'primary'}
          sx={{ margin: '24px 0 32px' }}
        >
          <SvgIcon
            sx={{
              width: '20px',
              height: '20px',
              marginRight: '4px',
              path: { stroke: 'white' },
            }}
          >
            <LinkMenuIcon />
          </SvgIcon>
          iPayView 열기
        </Link>
      </Stack>
      <Stack component={'section'}>
        <Box sx={LoginInfoBoxSx}>
          <h1>F&CO · WORKUP 로그인 정보</h1>
          <DataListGrid
            rowGap={'8px'}
            columnGap={'12px'}
            gridTemplateColumns={'80px 1fr'}
            sx={{ marginTop: '16px' }}
          >
            <DataListGridItem label={'아이디'}>{email}</DataListGridItem>
            <DataListGridItem label={'비밀번호'}>
              Workup에서 설정한 비밀번호
            </DataListGridItem>
            <DataListGridItem label={'문의'}>
              <Button
                onClick={openPopper}
                sx={{
                  padding: 0,
                  textDecoration: 'underline !important',
                  backgroundColor: 'white !important',
                  lineHeight: '1.5',
                  fontSize: '14px',
                  color: '#06C',
                }}
              >
                {managerName}
              </Button>
            </DataListGridItem>
          </DataListGrid>
        </Box>
        <Link
          href={'https://hr.workup.plus/cloudhr/1.0/login'}
          target={'_blank'}
          rel={'noreferrer'}
          size={'large'}
          variant={'secondary'}
          sx={{ margin: '24px 0 32px' }}
        >
          <SvgIcon
            sx={{
              width: '20px',
              height: '20px',
              marginRight: '4px',
              path: { stroke: 'black' },
            }}
          >
            <LinkMenuIcon />
          </SvgIcon>
          WORKUP 열기
        </Link>
        <ProfileCardPopper
          passportId={managerPassportId}
          anchorEl={anchorEl}
          onClose={closePopper}
        />
      </Stack>
    </ContentDivider>
  );
};
