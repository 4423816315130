import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { Box, IconButton, InputAdornment } from '@mui/material';
import {
  ErrorHelperTextStyle,
  RHFTextFieldProps,
} from '@/components/HookForm/RHFTextField';
import { useState } from 'react';
import EyeShowIcon from '@/public/icons/eye/eye_show_16.svg';
import EyeHideIcon from '@/public/icons/eye/eye_hide_16.svg';

export default function RHFPasswordField({
  name,
  helperText,
  size,
  sx,
  onClear,
  ...other
}: Omit<RHFTextFieldProps, 'type' | 'maxLength'>) {
  const [showPassword, setShowPassword] = useState(false);

  const { control, clearErrors } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          onChange={(e) => {
            const value = e.target.value.replace(/\s/g, '');
            field.onChange(value);
          }}
          fullWidth
          type={showPassword ? 'text' : 'password'}
          value={field.value}
          error={!!error}
          helperText={error ? error?.message : helperText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {field.value && (
                  <IconButton
                    onClick={() => {
                      field.onChange('');
                      clearErrors(name);
                      onClear?.();
                    }}
                  >
                    <img
                      src={'/images/icon/close_gray.svg'}
                      width={16}
                      height={16}
                      alt={'close icon'}
                    />
                  </IconButton>
                )}
                <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                  {showPassword ? <EyeShowIcon /> : <EyeHideIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          FormHelperTextProps={{
            sx: {
              ...ErrorHelperTextStyle,
              color: error ? '#F55060' : '#888D96',
            },
          }}
          sx={{
            '& .MuiInputBase-input::placeholder': {
              fontSize: '14px',
            },
            '& .MuiInputBase-root': {
              height: '48px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#000',
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px',
              borderColor: '#000',
            },
            ...sx,
          }}
          {...other}
        />
      )}
    />
  );
}
