import React, { PropsWithChildren } from 'react';
import { SxProps, Typography } from '@mui/material';

type Props = PropsWithChildren<{ sx?: SxProps; className?: string }>;

export const Body1 = ({ children, sx, className }: Props) => {
  return (
    <Typography
      className={className}
      sx={{
        color: '#000',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const Body2 = ({ children, sx, className }: Props) => {
  return (
    <Typography
      className={className}
      sx={{
        color: '#888D96',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const Caption = ({ children, sx, className }: Props) => {
  return (
    <Typography
      className={className}
      sx={{
        color: '#888D96',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};
